import {
  GET_PHONE_NUMBER_STATUS_SUCCESS,
  GET_PHONE_NUMBER_STATUS_PENDING,
  GET_PHONE_NUMBER_STATUS_FAILED,
} from "../Constants/Constants";

export const PhoneReducer = (
  state = {
    data: false,
    loading: true,
  },
  action
) => {
  switch (action.type) {
    case GET_PHONE_NUMBER_STATUS_PENDING:
      return state;
    case GET_PHONE_NUMBER_STATUS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case GET_PHONE_NUMBER_STATUS_FAILED:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
