import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDJnNhs3U6z-JxZeK3ypDtOajwOlLnwNmg",
  authDomain: "banglabazar-139df.firebaseapp.com",
  projectId: "banglabazar-139df",
  storageBucket: "banglabazar-139df.firebasestorage.app",
  messagingSenderId: "310417855070",
  appId: "1:310417855070:web:bd5d92265985653f3e1acf",
  measurementId: "G-26S7F260RE"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore_db = firebase.firestore();
export default firebase;
