import { WebsiteHeader } from "./Layout/Header/Header";
import { NewsLetter } from "./Layout/NewsLetter";
import { Footer } from "./Layout/Footer";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BanglaTin from "../../../assets/images/bangla_tin.jpg";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { Breadcrumb } from "antd";
import axios from "axios";
import Endpoint from "../../../Utils/Endpoint";
import { HiChevronRight } from "react-icons/hi2";

function AboutUs() {
  const [ShowModal, setShowModal] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  const [content, setContent] = useState("");
  const [para, setPara] = useState([]);

  const getContect = async () => {
    try {
      const result = await axios.post(`${Endpoint}/api/admin/getStaticTable`, {
        ContentType: "About Us",
      });

      setContent(result.data.data);

      content.Content.split("/n").map((item) => {
        console.log(item);
      });

      // console.log("=================>", content,);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getContect();
  }, []);
  return (
    <>
      <WebsiteHeader />

      <div className="container">
        <div className="container">
          <>
            <div className="breadcrumb-wrapper d-flex align-items-center gap-1 mt-2 pb-0">
              <Breadcrumb
                separator={<HiChevronRight />}
                items={[
                  {
                    title: "Home",
                    href: "/",
                  },
                  {
                    title: "About us",
                  },
                ]}
              />
            </div>
          </>
          <div className="mt-4">
            <div className="text-center">
              <h2 className="text-default">{content && content.ContentType}</h2>
            </div>
            <div className="mt-3">
              {/* {JSON.stringify(content.jsonContent)} */}

              {content &&
                content.jsonContent &&
                content.jsonContent.map((item) => {
                  return (
                    <div>
                      <h5>{item.head}</h5>
                      <p style={{ fontSize: "13px", fontWeight: 400 }}>
                        {item.body}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="container mt-3">
            {localStorage.getItem("region") === "Bangladesh" && (
              <p className="mt-2" style={{ fontSize: "15px" }}>
                Bangladesh Trade License: TRAD/DSCC/003746/2020
              </p>
            )}
            {localStorage.getItem("region") === "Bangladesh" && (
              <Link to="#" onClick={() => setShowModal(!ShowModal)}>
                Click here to see Bangladesh TIN certificate.
              </Link>
            )}

            <p className="mt-2" style={{ fontSize: "15px" }}>
              Registered Address :{" "}
              {localStorage.getItem("region") === "Bangladesh"
                ? "269/4 Fakirapool 2nd Floor, Dhaka 1000, Bangladesh"
                : "P.O. Box 2765, Atlanta, GA 30097, USA"}
            </p>
          </div>
        </div>
      </div>

      {/* <NewsLetter /> */}
      <Footer />
      <Modal
        isOpen={ShowModal}
        toggle={() => setShowModal(!ShowModal)}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          toggle={() => setShowModal(!ShowModal)}
          className="landing-signin"
        ></ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 m-auto">
              <div className="text-center">
                <img className="img-fluid" src={BanglaTin} />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
export default AboutUs;
