import { WebsiteHeader } from "./Layout/Header/Header";
import { NewsLetter } from "./Layout/NewsLetter";
import { Footer } from "./Layout/Footer";
// import { BreadcrumbItem, Breadcrumb } from "reactstrap";
import { Breadcrumb } from "antd";

import CartStep1 from "../../../assets/images/cart-step1.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loading from "../../../Utils/Loading";
import firetoast from "./../../../Helpers/FireToast";
import CartTableItem from "./Layout/My Cart Items/CartTableItem";
import { useDispatch } from "react-redux";
import { GetCartItems, RemoveCartItems } from "./../../../Actions/CartAction";
import BanglaBazarApi from "./../../Api/BanglaBazarApi";
import Endpoint from "./../../../Utils/Endpoint";
import { HiChevronRight } from "react-icons/hi2";

function UserCart() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [CartItems, setCartItems] = useState([]);
  const [ProductCombinationItems, setProductCombinationItems] = useState([]);
  const [TotalPrice, setTotalPrice] = useState(0);
  const state = useSelector((state) => state);
  const [selectedItemIds, setSelectedItemsIds] = useState([]);
  const [selectedItemsPrice, setSelectedItemsPrice] = useState(0);
  const { getCartItem, removeCartItem } = state;
  const [showNoteSection, setNoteSecion] = useState(false);

  useEffect(() => {
    setCartItems([]);
    setTotalPrice(0);
    setProductCombinationItems([]);
    setSelectedItemsIds([]);
    if (!getCartItem.loading) {
      if (getCartItem.error) {
        firetoast(
          "Something went wrong while fetching cart items",
          "default-error"
        );
      } else {
        setCartItems(getCartItem.data.productCartList);
        setProductCombinationItems(
          getCartItem.data.productCombinationPriceDetail
        );
        {
          console.log(
            getCartItem.data.productCartList,
            "getCartItem.data.productCartList"
          );
        }
        let allAllowStorePickupValuesAreY =
          getCartItem.data.productCartList.every(
            (item) => item.AllowStorePickup === "Y"
          );
        setNoteSecion(allAllowStorePickupValuesAreY);

        var totalCount = 0;
        let tempCombination = getCartItem.data.productCombinationPriceDetail;
        console.log("temp Combination", tempCombination);
        let indexes = getCartItem.data.productCartList;
        let _selectedItems = [];
        for (let i = 0; i < indexes.length; i++) {
          _selectedItems.push(i);
          let totalForCurrentItem = 0;
          let currentProduct = getCartItem.data.productCartList[i];
          let currentCombination = tempCombination[i];
          totalForCurrentItem += parseFloat(currentProduct.Price);
          for (let j = 0; j < currentCombination.length; j++) {
            totalForCurrentItem += parseFloat(
              currentCombination[j].ProductCombinationPrice
            );
          }
          console.log("total count here: ", totalForCurrentItem);
          totalForCurrentItem =
            totalForCurrentItem * parseInt(currentProduct.Total_Quantity);
          console.log("total count here: ", totalForCurrentItem);
          totalCount += totalForCurrentItem;
        }
        console.log("totalAmountCartItems: ", totalCount);

        localStorage.setItem("totalAmountCartItems", totalCount);

        setTotalPrice(totalCount);
        setSelectedItemsPrice(totalCount);

        localStorage.setItem("totalAmountCartItems", totalCount);

        setSelectedItemsIds(_selectedItems);
      }
    }
  }, [getCartItem, getCartItem, dispatch]);

  useEffect(() => {
    dispatch(GetCartItems());
  }, [removeCartItem]);

  var deleteCartItem = async (ProductID, index) => {
    var productVariations = ProductCombinationItems[index];
    console.log(ProductCombinationItems);
    var ProductVariantCombinationDetail = [];
    for (let i = 0; i < productVariations.length; i++) {
      var obj = {};
      obj.ProductVariantCombinationID =
        productVariations[i].ProductVariantCombinationID;
      ProductVariantCombinationDetail.push(obj);
    }
    // console.log(ProductID, { ProductVariantCombinationDetail });
    await dispatch(
      RemoveCartItems(ProductID, { ProductVariantCombinationDetail })
    );
    await dispatch(GetCartItems());
  };

  //Verify PROCEED
  var VerifyToProceed = async () => {
    //Check for same countries
    var selectedIds = selectedItemIds;
    var SelectedProductsArray = [];
    var GlobalShippingStatus = [];
    var ShippingAvailableStatus = [];
    var ShippingCities = [];
    var SelectedCartItems = [];
    var SelectedCartCombinations = [];
    for (let i = 0; i < selectedIds.length; i++) {
      let currentId = selectedIds[i];
      for (let x in CartItems) {
        if (parseInt(currentId) === parseInt(x)) {
          SelectedProductsArray.push(CartItems[x].ProductCountry);
          GlobalShippingStatus.push(CartItems[x].ShippingGlobal);
          ShippingAvailableStatus.push(CartItems[x].ShippingAvailable);
          ShippingCities.push(CartItems[x].City);
          SelectedCartItems.push(CartItems[x]);
          SelectedCartCombinations.push(ProductCombinationItems[x]);
        }
      }
    }
    console.log(SelectedProductsArray);
    var resp = await checkIfAvailableInInventory(
      SelectedCartItems,
      SelectedCartCombinations
    );

    if (resp.productActiveStatus.length > 0) {
      return firetoast(
        `The Product ${resp.productActiveStatus[0]} is currently InActive, Kindly Remove This Product To Buy Other Products in Cart`,
        "default-error"
      );
    } else if (resp.storeActiveStatus.length > 0) {
      return firetoast(
        `The Product ${resp.storeActiveStatus[0]} Store  is currently InActive, Kindly Remove This Product To Buy Other Products in Cart`,
        "default-error"
      );
    }

    console.log(
      resp.productActiveStatus.length,
      resp.storeActiveStatus.length,
      "++++++++++++++++++++++"
    );

    if (!resp.value) {
      if (resp.data.length === 0) {
        return firetoast("Something went wrong", "default-error");
      } else {
        let array = resp.data[0]["ProductDetail"];

        for (let i = 0; i < array.length; i++) {
          firetoast(
            `Sorry can't proceed with checkout the variant ${array[i]["OptionValue"]} for the product ${array[i]["Title"]} is out of stock`,
            "error",
            5000,
            "top-center"
          );
        }
        return;
      }
    }
    // console.log(SelectedProductsArray);

    const allEqual = (arr) => arr.every((v) => v === arr[0]);
    var CheckEqualCountries = allEqual(SelectedProductsArray);
    var CheckAllGlobalShipping = allEqual(GlobalShippingStatus);
    var CheckAllShippingAvailable = allEqual(ShippingAvailableStatus);
    var CheckAllShippingCities = allEqual(ShippingCities);

    if (CheckEqualCountries) {
      if (CheckAllGlobalShipping) {
        history.push(
          `/my-cart/delivery-details?product=${selectedItemIds.join(
            ","
          )}&GlobalShipping=Y&ShippingAvailable=Y&City=All`
        );
      } else {
        if (CheckAllShippingAvailable) {
          history.push(
            `/my-cart/delivery-details?product=${selectedItemIds.join(
              ","
            )}&GlobalShipping=N&ShippingAvailable=Y&City=All`
          );

          console.log("CheckAllShippingAvailable", CheckAllShippingAvailable);
        } else {
          // 6-case
          if (CheckAllShippingCities) {
            history.push(
              `/my-cart/delivery-details?product=${selectedItemIds.join(
                ","
              )}&GlobalShipping=N&ShippingAvailable=N&City=${
                CartItems[0]["City"]
              }`
            );

            console.log("CheckAllShippingCities", CheckAllShippingCities);
          } else {
            //8-case
            return firetoast(
              "Can't proceed with cart as the products are not available for different cities delivery",
              "info",
              6000,
              "top-right"
            );
          }
        }
      }
    } else {
      return firetoast(
        "This product’s payment cannot be process at the same time with previously add product in your shopping cart. Please pay your previously added product from your shopping cart then make a separate purchase for this product or remove previously added product from your shopping cart then add this product to your cart.",
        "error",
        6000,
        "top-right"
      );
    }
  };
  //Verify PROCEED

  var CheckIfSelectedCitiesSame = () => {
    var selectedIds = selectedItemIds;
    var SelectedProductsArray = [];
    for (let i = 0; i < selectedIds.length; i++) {
      let currentId = selectedIds[i];
      for (let x in CartItems) {
        if (parseInt(currentId) === parseInt(CartItems[x].ProductID)) {
          SelectedProductsArray.push(CartItems[x].ProductCountry);
        }
      }
    }

    const allEqual = (arr) => arr.every((v) => v === arr[0]);
    var CheckEqualCountries = allEqual(SelectedProductsArray);

    if (CheckEqualCountries) {
      // history.push(
      //   `/my-cart/delivery-details?product=${selectedItemIds.join(",")}`
      // );
    } else {
      return firetoast(
        "Product countries for selected products are not same ,please proceed with individual item",
        "info",
        6000,
        "top-right"
      );
    }
  };
  var checkIfAvailableInInventory = async (Cart, Combination) => {
    ///api/wish-list/get-inventory
    var productCartList = [];
    for (let i = 0; i < Cart.length; i++) {
      var currentProduct = Cart[i];
      currentProduct["ProductCombinations"] = Combination[i];
      productCartList.push(currentProduct);
    }
    try {
      const response = await BanglaBazarApi.post(
        `${Endpoint}/api/wish-list/get-inventory`,
        { productCartList }
      );

      if (response.data.status) {
        var p_list = response.data.outOfStockProducts;

        if (p_list.length === 0) {
          return {
            value: true,
            data: [],
            storeActiveStatus: response.data.storeActiveStatus,
            productActiveStatus: response.data.productActiveStatus,
          };
        } else {
          return {
            value: false,
            data: p_list,
            storeActiveStatus: response.data.storeActiveStatus,
            productActiveStatus: response.data.productActiveStatus,
          };
        }
      }
      return {
        value: false,
        data: [],
      };
    } catch (e) {
      return {
        value: false,
        data: [],
      };
    }
  };

  var updateTotalPrice = (addedValue, operator, checked) => {
    console.log("Total price before update: ", addedValue, operator, checked);
    if (checked && operator == "+") {
      setTotalPrice(parseFloat(TotalPrice) + parseInt(addedValue));
      setSelectedItemsPrice(
        parseFloat(selectedItemsPrice) + parseInt(addedValue)
      );
      localStorage.setItem(
        "totalAmountCartItems",
        parseFloat(selectedItemsPrice) + parseInt(addedValue)
      );
    } else if (checked && operator == "-") {
      setTotalPrice(parseFloat(TotalPrice) - parseInt(addedValue));
      setSelectedItemsPrice(
        parseFloat(selectedItemsPrice) - parseInt(addedValue)
      );
      localStorage.setItem(
        "totalAmountCartItems",
        parseFloat(selectedItemsPrice) - parseInt(addedValue)
      );
    } else if (!checked && operator == "+") {
      setTotalPrice(parseFloat(TotalPrice) + parseInt(addedValue));
      // setSelectedItemsPrice(
      //   parseFloat(selectedItemsPrice) + parseInt(addedValue)
      // );
    } else if (!checked && operator == "-") {
      setTotalPrice(parseFloat(TotalPrice) - parseInt(addedValue));
      // setSelectedItemsPrice(
      //   parseFloat(selectedItemsPrice) - parseInt(addedValue)
      // );
    }
    console.log("Total price after update: ", TotalPrice);
  };

  var updateSelectedItemsArray = (price, action) => {
    if (action) {
      setSelectedItemsPrice(selectedItemsPrice - price);
    } else {
      setSelectedItemsPrice(selectedItemsPrice + price);
    }
  };

  return (
    <>
      <WebsiteHeader />

      <div className="container">
        <>
          <div className="breadcrumb-wrapper d-flex align-items-center gap-1 mt-2 pb-0">
            <Breadcrumb
              separator={<HiChevronRight />}
              items={[
                {
                  title: "Home",
                  href: "/",
                },
                {
                  title: "Shopping cart",
                },
              ]}
            />
          </div>
        </>
        <div className="mt-4">
          <div className="row content-inner">
            <div
              id="center_column"
              className="center_column col-xs-12 col-sm-12 col-sm-push-0"
            >
              <h1 id="cart_title" className="page-heading">
                Shopping-cart summary
                <span className="heading-counter">
                  Your shopping cart contains:{" "}
                  <span id="summary_products_quantity">
                    {CartItems.length} Product
                  </span>
                </span>
              </h1>
              <ul
                className="step clearfix"
                id="order_step"
                style={{ paddingLeft: 0 }}
              >
                {/* <li className="step_current first"> */}
                <li className="step_current first">
                  <span>
                    <em>01.</em> Summary
                  </span>
                </li>
                <li className="step_todo second">
                  <span>
                    <em>02.</em> Proceed to Checkout
                  </span>
                </li>
                <li className="step_todo third">
                  <span>
                    <em>03.</em> Order Complete
                  </span>
                </li>
              </ul>
              <div
                id="order-detail-content"
                className="table_block table-responsive"
              >
                <table
                  id=""
                  className="table table-bordered stock-management-on table-responsive"
                >
                  <thead>
                    <tr>
                      <th className="cart_product first_item text-center">
                        Product
                      </th>
                      <th className="cart_description item">Description</th>
                      <th className="cart_avail item text-center">
                        Availability
                      </th>
                      <th className="cart_unit item text-center">Unit price</th>
                      <th className="cart_quantity item text-center">Qty</th>
                      <th className="cart_delete last_item text-center">
                        Action
                      </th>
                      <th className="cart_total item text-right">Total</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr className="cart_total_price">
                      <td
                        rowSpan={4}
                        colSpan={2}
                        id="cart_voucher"
                        className="cart_voucher"
                      ></td>
                      <td colSpan={3} className="text-right">
                        Total products
                      </td>
                      <td colSpan={2} className="price" id="total_product">
                        {CartItems.length > 0 && CartItems[0].Currency}{" "}
                        {TotalPrice &&
                          TotalPrice !== "" &&
                          TotalPrice.toFixed(2)}
                      </td>
                    </tr>

                    {/* <tr className="cart_total_delivery">
                      <td colSpan={3} className="text-right">
                        Total shipping
                      </td>
                      <td colSpan={2} className="price" id="total_shipping">
                        0
                      </td>
                    </tr>
                    <tr className="cart_total_tax">
                      <td colSpan={3} className="text-right">
                        Tax
                      </td>
                      <td colSpan={2} className="price" id="total_tax">
                        0
                      </td>
                    </tr> */}
                    <tr className="cart_total_price">
                      <td
                        colSpan={3}
                        className="total_price_container text-right"
                      >
                        <span>Total</span>
                        <div className="hookDisplayProductPriceBlock-price" />
                      </td>
                      <td
                        colSpan={2}
                        className="price"
                        id="total_price_container"
                      >
                        <span id="total_price">
                          {CartItems.length > 0 && CartItems[0].Currency}{" "}
                          {TotalPrice &&
                            TotalPrice !== "" &&
                            TotalPrice.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                  <tbody>
                    {CartItems.length < 1 ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          Cart is empty!
                        </td>
                      </tr>
                    ) : (
                      CartItems.map((item, index) => (
                        <CartTableItem
                          key={index} // Add a key for each item
                          product={item}
                          index={index}
                          variants={ProductCombinationItems[index]}
                          removeCartItem={deleteCartItem}
                          allVariants={ProductCombinationItems[index]}
                          selectedItemIds={selectedItemIds}
                          setSelectedItemsIds={setSelectedItemsIds}
                          updateSelectedItemsArray={updateSelectedItemsArray}
                          updateTotalPrice={updateTotalPrice}
                        />
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              <p className="cart_navigation clearfix">
                <button
                  disabled={selectedItemIds.length < 1}
                  onClick={() => VerifyToProceed()}
                  className="button btn standard-checkout button-medium"
                  title="Proceed to checkout"
                >
                  <span>
                    Proceed to checkout
                    <i
                      className="fas fa-chevron-right"
                      style={{ paddingLeft: 10 }}
                    />
                  </span>
                </button>
                <Link
                  onClick={() => history.push("/")}
                  className="button-exclusive btn"
                  title="Continue shopping"
                >
                  <i
                    className="fas fa-chevron-left"
                    style={{ paddingRight: 10 }}
                  />
                  Continue shopping
                </Link>
              </p>

              <div className="clear" />
              <div className="cart_navigation_extra">
                <div id="HOOK_SHOPPING_CART_EXTRA" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <NewsLetter /> */}
      <Footer />
    </>
  );
}
export default UserCart;
