import {
  CATEGORY_REQUEST_SUCCESS,
  CATEGORY_REQUEST_PENDING,
  CATEGORY_REQUEST_FAILED,
  LANDING_REQUEST_SUCCESS,
  LANDING_REQUEST_PENDING,
  LANDING_REQUEST_FAILED,
  IP_REQUEST_SUCCESS,
  IP_REQUEST_FAILED,
} from "../Constants/Constants";
import BanglaBazarApi from "./../Components/Api/BanglaBazarApi";
import Endpoint from "./../Utils/Endpoint";
import axios from "axios";

export const getLandingPageData = (Country, UserID) => async (dispatch) => {
  try {
    dispatch({
      type: LANDING_REQUEST_PENDING,
      payload: [],
    });
    var form = new URLSearchParams();
    form.append("Country", Country);
    form.append("UserID", UserID);
    var response = await BanglaBazarApi.post(
      `${Endpoint}/api/landing-page/showProductsViews`,
      form
    );
    if (response.data.status) {
      dispatch({
        type: LANDING_REQUEST_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: LANDING_REQUEST_FAILED,
        payload: response.data.message || response.data.error,
      });
    }
  } catch (e) {
    if (e.response) {
      dispatch({
        type: LANDING_REQUEST_FAILED,
        payload: e.response.data.message || e.response.data.error,
      });
    } else {
      dispatch({
        type: LANDING_REQUEST_FAILED,
        payload: "Something went wrong!",
      });
    }
  }
};

export const getDepartmentCategoryAndSubCategory = () => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_REQUEST_PENDING,
      payload: [],
    });

    var response = await BanglaBazarApi.get(
      `${Endpoint}/api/category/get-viewDepartmentsAllCategoriesANDSubcategories`
    );

    // console.log(response.data, "================================================");
    // return

    if (response.data.status) {
      dispatch({
        type: CATEGORY_REQUEST_SUCCESS,
        payload: response.data.departmentsDetails,
      });
    } else {
      dispatch({
        type: CATEGORY_REQUEST_FAILED,
        payload: response.data.message || response.data.error,
      });
    }
  } catch (e) {
    if (e.response) {
      dispatch({
        type: CATEGORY_REQUEST_FAILED,
        payload: e.response.data.message || e.response.data.error,
      });
    } else {
      dispatch({
        type: CATEGORY_REQUEST_FAILED,
        payload: "Something went wrong",
      });
    }
  }
};

export const getCategoryAndSubCategory = () => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_REQUEST_PENDING,
      payload: [],
    });

    var response = await BanglaBazarApi.get(
      `${Endpoint}/api/category/get-allCategoriesAndSubCategories`
    );

    if (response.data.status) {
      dispatch({
        type: CATEGORY_REQUEST_SUCCESS,
        payload: response.data.categoriesAndSubCategories,
      });
    } else {
      dispatch({
        type: CATEGORY_REQUEST_FAILED,
        payload: response.data.message || response.data.error,
      });
    }
  } catch (e) {
    if (e.response) {
      dispatch({
        type: CATEGORY_REQUEST_FAILED,
        payload: e.response.data.message || e.response.data.error,
      });
    } else {
      dispatch({
        type: CATEGORY_REQUEST_FAILED,
        payload: "Something went wrong",
      });
    }
  }
};
export const getCurrentIpv4 = () => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_REQUEST_PENDING,
      payload: [],
    });

    const geoLocation = localStorage.getItem("geoLoc");
    if (geoLocation) {
      dispatch({
        type: IP_REQUEST_SUCCESS,
        payload: JSON.parse(geoLocation),
      });
    } else {
      const response = await axios.get("https://geolocation-db.com/json/");
      const data = response.data;
      localStorage.setItem("geoLoc", JSON.stringify(data));
      dispatch({
        type: IP_REQUEST_SUCCESS,
        payload: {
          IPv4: data.IPv4,
          city: data.city,
          country_code: data.country_code,
          country_name: data.country_name,
          latitude: data.latitude,
          longitude: data.longitude,
          postal: data.postal,
          state: data.state,
        },
      });
    }
  } catch (e) {
    dispatch({
      type: IP_REQUEST_FAILED,
      payload: {},
    });
  }
};
