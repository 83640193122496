import { useState } from "react";
import { useEffect } from "react";
import BanglaBazarApi from "./../../../Api/BanglaBazarApi";
import Endpoint from "./../../../../Utils/Endpoint";
import { RequiredField } from "./../../../../Utils/Required-field";
import Icons from "./../../../../Utils/Icons";
import { Spinner } from "reactstrap";
import { moment } from "moment";
import {
  CountryCodes,
  CountryCodes2,
  CountryNames,
} from "../../../../Helpers/CountryCodes";
import firetoast from "./../../../../Helpers/FireToast";
import CheckEmpty from "./../../../../Utils/CheckEmpty";
import { Modal, ModalBody } from "reactstrap";
import Loading from "./../../../../Utils/Loading";
import { Link, useHistory } from "react-router-dom";
import { CurrentUser } from "./../../../../Helpers/Auth";
import PhoneInput from "react-phone-input-2";

import Swal from "sweetalert2";

import { ToastContainer, toast } from "react-toastify";

function GlobalDeliveryDHL({
  setPaymentStates,
  setPaymentCities,
  setCountrySelect,
  ShowPrevPayment,
  setShowPrevPayment,
  PaymentType,
  setPaymentType,
  PickUpByUser,
  AllowStorePickup,
  setAllowStorePickup,
  OverallCity,
  CountrySelect,
  CartItems,
  setDeliveryBy,
  ContinueButton,
  setContinueButton,
  DeliveryBy,
  TotalPrice,
  setTotalPrice,
  ShippingPrice,
  setShippingPrice,
  ProductCombinationItems,
  setProductCombinationItems,
  product_ids,
  productGlobalShipping,
  productShippingAvailable,
  productCity,
  PaymentClient,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [UserCountry, setUserCountry] = useState();
  const [AllowAdminPickup, setAllowAdminPickup] = useState("Y");
  const [ShowModal, setShowModal] = useState(false);
  const [paymentLoading, setpaymentLoading] = useState(false);
  const [PaymentDetails, setPaymentDetails] = useState({});
  const [AgreeTerms, setAgreeTerms] = useState(false);
  const [DHLResponse, setDHLResponse] = useState(true);
  const [isPaymentSelected, setPaymentSelected] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [ZoneList, setZoneList] = useState([]);
  const [AreaList, setAreaList] = useState([]);
  const [ZoneLoading, setZoneLoading] = useState(false);
  const [AreaLoading, setAreaLoading] = useState(false);
  const [PathaoAccessToken, setPathaoAccessToken] = useState(null);
  const [Gateways, setGateways] = useState([]);
  const [rateCalculation, setRateCalculation] = useState(false);
  const [PaymentMethod, setPaymentMethod] = useState("");
  const [ShowCheckoutButton, setShowCheckoutButton] = useState(false);
  const [OrderNumber, setOrderNumber] = useState("");
  const [SameAsAbove, setSameAsAbove] = useState(false);
  const [LastPaymentDetail, setLastPaymentDetail] = useState([]);
  const [SaveAddress, setSaveAddress] = useState(true);
  const [HomeAddress, setHomeAddress] = useState(true);
  const [DeliveryDetails, setDeliveryDetails] = useState({});
  const [PayerHistory, setPayerHistory] = useState(null);
  const [DeliveryHistory, setDeliveryHistory] = useState(null);
  const [ShowPaymentFields, setShowPaymentFields] = useState(false);
  const [ShowDeliveryFields, setShowDeliveryFields] = useState(false);
  const [DeliveryStatus, SetDeliveryStatus] = useState(null);
  const [SelectedFromPrevious, setSelectedFromPrevious] = useState(false);
  const [stripeData, setStripeData] = useState(null);
  const [ChcekcOutStatus, setChceckOutStatus] = useState(false);
  const [StripeToken, setStripeToken] = useState("");
  const [CountryCode, setCountryCode] = useState([]);
  const [planeDate, setPlaneState] = useState("");
  const [CitiesList, setCitiesList] = useState([]);

  useEffect(async () => {
    const data = await CountryNames();
    console.log(data);
    setCountryList(data);
  }, [UserCountry]);

  useEffect(() => {
    let country = localStorage.getItem("DSCountry").toLowerCase();
    setUserCountry(country);
    if (country === "united states") {
      setUserCountry("us");
    }
  }, []);

  var getStates = async (id) => {
    const selectedCountry =
      localStorage.getItem("setSelectedCountry") ||
      localStorage.getItem("country");

    let CountryIdSelected = 226;

    // Match the country and set DeliveryCountry accordingly
    switch (selectedCountry) {
      case "bangladesh":
        CountryIdSelected = 16;
        localStorage.setItem("DSCountry", "BD");
        break;
      case "pakistan":
        CountryIdSelected = 159;
        localStorage.setItem("DSCountry", "PK");
        break;
      case "south africa":
        CountryIdSelected = 159;
        localStorage.setItem("DSCountry", "ZAR");
        break;
      case "canada":
        CountryIdSelected = 37;
        localStorage.setItem("DSCountry", "CA");
        break;
      case "australia":
        CountryIdSelected = 11;
        localStorage.setItem("DSCountry", "AU");
        break;
      case "united states":
        CountryIdSelected = 226;
        localStorage.setItem("DSCountry", "US");
        break;
      default:
        CountryIdSelected = 226;
        localStorage.setItem("DSCountry", "US");
        break;
    }

    try {
      var response = await BanglaBazarApi.post(
        Endpoint + "/api/location/get-vendorAllowedStates",
        {
          CountryID: CountryIdSelected, //
        }
      );
      setStateList(response?.data.States);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(async () => {
    getStates();

    getPaymentMethods();
    getPreviousPaymentDetails();
    var temp = await CountryCodes();
    // console.log("___________________________", temp);
    setCountryCodes(temp);
    setCountryCode(temp);
    // getCountries();
    // console.log(CountrySelect);
  }, []);

  useEffect(async () => {
    if (PaymentClient == "SSL Commerz" || PaymentType == "cod") {
      setSaveAddress(false);
    }
  }, []);

  // let SetDeliveryStatus = async (status) => {
  //   var pd = {...PaymentDetails};
  //   var dd = {...DeliveryDetails};
  //   dd["DeliveryStatus"] = status;
  //   pd["DeliveryStatus"] = status;
  //   setPaymentDetails(pd);
  //   setDeliveryDetails(dd);
  // };
  let getPreviousPaymentDetails = async () => {
    try {
      let CountryID = CountrySelect;
      const response = await BanglaBazarApi.get(
        `${Endpoint}/api/payment/user-history?CountryID=${CountryID}`
      );
      // setLastPaymentDetail(response.data.userPaymentHistory);
      setDeliveryHistory(response.data.userAddressHistory);
      setPayerHistory(response.data.userPaymentHistory);
      if (response.data.userPaymentHistory.length === 0) {
        setShowPaymentFields(true);
      }
      if (response.data.userAddressHistory.length === 0) {
        setShowDeliveryFields(true);
      }
    } catch (e) {
      console.log(e, "error while fetching previous details");
    }
  };

  let CalculateShippingv2 = async (item) => {
    let cartItems = [...CartItems];
    let currentItemsCities = [];
    var total_weight = 0.0;
    for (let i = 0; i < cartItems.length; i++) {
      total_weight += parseFloat(cartItems[i].Weight);
      currentItemsCities.push(cartItems[i].City);
    }
    console.log("inside this");
    const allEqual = (arr) => arr.every((v) => v === arr[0]);
    var sameProductCities = allEqual(currentItemsCities);
    if (sameProductCities) {
      console.log(currentItemsCities[0]);
      console.log({ ...item }, "PaymentDetails");
      if (currentItemsCities[0] === item["DeliveryCity"]) {
        console.log("driver case");
        //Check Driver Availability for that city
        //no driver
        //DeliveryClient
        console.log("else case 1");

        CalculateShippingPricev2(item, false);
      } else {
        console.log("else case 2.1");
        //DeliveryClient
        CalculateShippingPricev2(item, false);
      }
    } else {
      console.log("else case 3.1");
      //DeliveryClient
      CalculateShippingPricev2(item, false);
    }
  };
  var CalculateShippingPricev2 = async (item, deliveryStatus) => {
    let countryId = CountrySelect;
    console.log(item);
    let DriverAvailability = deliveryStatus ? "Y" : "N";
    if (countryId === "16" || countryId === 16) {
      //bangladesh go for pathao
      //patho price calculation
      var productIds = [];
      for (let j = 0; j < CartItems.length; j++) {
        productIds.push(CartItems[j].ProductID);
      }
      console.log(item, "item.PathaoCityID ");
      var response = await BanglaBazarApi.post(
        `${Endpoint}/api/pathao/price-plan`,
        {
          token: PathaoAccessToken,
          // item_weight: weight,
          recipient_city: item.DeliveryCityID,
          recipient_zone: item["DeliveryZoneID"],
          ProductIDs: productIds,
          DriverAvailability,
        }
      );

      var total = 0;
      var responses = response.data.saveResponse;
      // console.log(responses);
      for (let j = 0; j < responses.length; j++) {
        total +=
          parseFloat(responses[j]["data"]["price"]) +
          parseFloat(responses[j]["data"]["additional_charge"]);
      }
      localStorage.setItem("pp", JSON.stringify(responses));
      setShippingPrice(parseFloat(total.toFixed(2)));
      if (deliveryStatus) {
        setDeliveryBy("dd");
        SetDeliveryStatus("dd");
      } else {
        setDeliveryBy("pathao");
        SetDeliveryStatus("pathao");
      }
    } else {
      //usps price calculation

      var totalShippingRate = 0;
      setRateCalculation(true);
      for (let i = 0; i < CartItems.length; i++) {
        var response = await BanglaBazarApi.post(`${Endpoint}/api/usps/rate`, {
          originationZip: CartItems[i].VendorStoreZip,
          destinationZip: document.getElementById("DeliveryZipCode").value,
          pounds: parseFloat(CartItems[i].Weight),
          ounces: 0,
          height: parseFloat(CartItems[i].Height),
          width: parseFloat(CartItems[i].Width),
          length: parseFloat(CartItems[i].Length),
        });
        console.log(response.data);
        if (!response.data.status) {
          // return firetoast(response.data.message, "default-error");
          setDeliveryBy(null);
          return null;
        } else {
          totalShippingRate += parseFloat(
            response.data.data.Package.Postage.Rate._text
          );
        }
        console.log(totalShippingRate);
      }
      setDeliveryBy("usps");
      SetDeliveryStatus("usps");
      setShippingPrice(totalShippingRate);
      setRateCalculation(false);
    }
  };

  let getPaymentMethods = async () => {
    try {
      const response = await BanglaBazarApi.get(
        `${Endpoint}/api/payment/get-paymentGateway`
      );
      if (response.data.status) {
        setGateways(response.data.PaymentGateway);
      } else {
        var { message, error } = response.data;
        firetoast(message || error, "default-error");
      }
    } catch (e) {
      firetoast(
        "Something went wrong while getting payment methods",
        "default-error"
      );
    }
  };
  let handleChange = ({ currentTarget: input }) => {
    var data = { ...DeliveryDetails };
    if (input.name === "ExpirationDate") {
      data[input.name] = moment(input.value).format("MMYY");
    } else {
      data[input.name] = input.value;
    }
    setDeliveryDetails(data);
  };
  let DeliveryhandleChange = async ({ currentTarget: input }) => {
    console.log("DeliveryCity", input.value);

    if (input.name === "ShippingDate") {
      setPlaneState(input.value);
    }
    var data = { ...DeliveryDetails };
    if (input.name === "ExpirationDate") {
      data[input.name] = moment(input.value).format("MMYY");
    } else {
      data[input.name] = input.value;
    }
    // if (input.name === 'DeliveryCity') {
    //   // console.log(JSON.parse(input.value)['City']);
    //   data['DeliveryCity'] = JSON.parse(input.value)['City']
    //   if (!data['DeliveryCity']) {
    //     data['DeliveryCity'] = document.getElementById('dcity') ? document.getElementById('dcity').value : JSON.parse(input.value)['City']
    //     console.log(data['DeliveryCity']);
    //   }
    // }

    console.log("========================,", input);

    if (input.name === "DeliveryState") {
      {
        console.log(
          JSON.parse(input.value).State,
          "JSON.parse(input.value).State"
        );
      }
      if (JSON.parse(input.value).State === "New York") {
        console.log("+++++++");
        getCitiez(91);
      } else {
        getCitiez(0);
      }
      console.log(JSON.parse(input.value).State);
      data["DeliveryState"] = JSON.parse(input.value).State;
      data.DeliveryCity = null;
      data["DeliveryZipCode"] = "";
      // setDeliveryDetails(data);
      // return;
    }
    if (input.name === "DeliveryCity") {
      console.log("==========>", input.value);
      data[input.name] =
        CitiesList && CitiesList.length > 0
          ? JSON.parse(input.value).City
          : input.value;
    }
    // !state

    console.log(data);
    //setPaymentDetails(data);
    setDeliveryDetails(data);
  };

  // var handleCityChange = (e) => {
  //   var value = JSON.parse(e.target.value);
  //   var data = { ...DeliveryDetails };
  //   data["DeliveryCity"] = value.city_name;
  //   data["CityId"] = value.city_id;
  //   document.getElementById("p-area").selectedIndex = 0;
  //   document.getElementById("p-zone").selectedIndex = 0;
  //   setDeliveryDetails(data);
  // };
  // var getCountries = async () => {
  //   try {
  //     var response = await BanglaBazarApi.get(
  //       Endpoint + "/api/location/get-deliveryAllowedCountries"
  //     );
  //     setCountryList(response.data.Countries);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  useEffect(() => {
    if (CountrySelect) getCities(CountrySelect);
  }, [ShowPrevPayment, CountrySelect]);

  var getCities = async (id) => {
    try {
      var response = await BanglaBazarApi.get(
        Endpoint + `/api/location/get-citiesByCountry/${CountrySelect}`,
        {}
      );
      setCityList(response?.data.Cities);

      // console.log("=====================>", response?.data.Cities.sort((a, b) => a.City.localeCompare(b.City)))
    } catch (e) {
      console.log(e);
    }
  };

  var getCitiez = async (id) => {
    try {
      var response = await BanglaBazarApi.get(
        Endpoint + `/api/location/get-cities/${id}`,
        {}
      );
      console.log(response.data);
      setCitiesList(response?.data.Cities);
    } catch (e) {
      console.log(e);
    }
  };

  let handleSameAsAbove = async (status) => {
    console.log("=============================================>", {
      ...DeliveryDetails,
    });
    if (status) {
      let delivery_details = { ...DeliveryDetails };
      let obj = {
        DeliveryName: delivery_details["Name"] ? delivery_details["Name"] : "",
        DeliveryPhoneNumber: delivery_details["cus_phone"]
          ? delivery_details["cus_phone"]
          : "",
        DeliveryAddress1: delivery_details["Address1"]
          ? delivery_details["Address1"]
          : "",
        DeliveryAddress2: delivery_details["Address2"]
          ? delivery_details["Address2"]
          : "",
        DeliveryZipCode: delivery_details["ZipCode"]
          ? delivery_details["ZipCode"]
          : "",
      };
      setDeliveryDetails(obj);
      //setPaymentDetails(obj);
    } else {
      var obj = { ...PaymentDetails };
      delete obj["Name"];
      delete obj["DeliveryPhoneNumber"];
      delete obj["DeliveryAddress1"];
      delete obj["DeliveryAddress2"];
      delete obj["DeliveryZipCode"];
      setDeliveryDetails(obj);
      //  setPaymentDetails(obj);
    }
  };
  var ValidateDHLAddresses = async () => {
    console.log("=====================>");

    try {
      console.log(DeliveryDetails["DeliveryZipCode"], "----------------");
      const response = await BanglaBazarApi.post(
        `${Endpoint}/api/dhl/address-validate`,
        {
          deliveryCityName: DeliveryDetails["DeliveryCity"],
          countryID: CountrySelect,
          postalCode: DeliveryDetails["DeliveryZipCode"],
        }
      );

      console.log(response.data.status, "response.data");
      if (response.data.response.status === true) {
        return response.data;
      } else {
        return response.data.response;
      }
    } catch (e) {
      console.log(e);
      return e.message;
    }
  };
  var checkIfAvailableInInventory = async (Cart, Combination) => {
    ///api/wish-list/get-inventory
    var productCartList = [];
    for (let i = 0; i < Cart.length; i++) {
      var currentProduct = Cart[i];
      currentProduct["ProductCombinations"] = Combination[i];
      productCartList.push(currentProduct);
    }
    try {
      const response = await BanglaBazarApi.post(
        `${Endpoint}/api/wish-list/get-inventory`,
        { productCartList }
      );
      if (response.data.status) {
        var p_list = response.data.outOfStockProducts;

        if (p_list.length === 0) {
          return {
            value: true,
            data: [],
          };
        } else {
          return {
            value: false,
            data: p_list,
          };
        }
      }
      return {
        value: false,
        data: [],
      };
    } catch (e) {
      return {
        value: false,
        data: [],
      };
    }
  };

  var savePaymentDetails = async () => {
    var resp = await checkIfAvailableInInventory(
      CartItems,
      ProductCombinationItems
    );

    if (!resp.value) {
      if (resp.data.length === 0) {
        return firetoast("Something went wrong", "default-error");
      } else {
        let array = resp.data[0]["ProductDetail"];

        for (let i = 0; i < array.length; i++) {
          firetoast(
            `Sorry can't proceed with checkout the variant ${array[i]["OptionValue"]} for the product ${array[i]["Title"]} is out of stock`,
            "error",
            5000,
            "top-center"
          );
        }
        return;
      }
    }
    // productGlobalShipping,productShippingAvailable,productCity
    // if (productGlobalShipping === "Y") {
    //   //available for globalshipping
    //   if (productShippingAvailable === "Y") {
    //     //proceed
    //   } else {
    //     if (productCity === PaymentDetails["DeliveryCity"]) {
    //       //proceed
    //     } else {
    //       return firetoast(
    //         `This product is only available for delivery in ${productCity}`
    //       );
    //     }
    //   }
    // } else {
    //   if (CartItems.length === 1) {
    //     if (
    //       parseInt(CartItems[0]["ProductCountry"]) === parseInt(CountrySelect)
    //     ) {
    //       //proceed
    //       if (productShippingAvailable === "Y") {
    //         //proceed
    //       } else {
    //         if (productCity === PaymentDetails["DeliveryCity"]) {
    //           //proceed
    //         } else {
    //           return firetoast(
    //             `This product is only available for delivery in ${productCity}`
    //           );
    //         }
    //       }
    //     } else {
    //       return firetoast("Procduct can't be delivered in this country");
    //     }
    //   }
    // }

    proceedPayment();
  };
  var validateDHL = async () => {
    let validproductCity = true;
    let msg;

    CartItems.map((item) => {
      if (!["Dhaka", "Chittagong"].includes(item.City)) {
        validproductCity = false;
        msg = `Shipping is only allowed for the product that belongs Dhaka and Chittagong, but ${item.Title} blongs to  ${item.City} `;
      }
    });
    if (!validproductCity) {
      return firetoast(`${msg}`, "default-error");
    }

    var dd = { ...DeliveryDetails };

    console.log("elsecase");

    if (CheckEmpty(dd.DeliveryName)) {
      dd["DeliveryName"] = CurrentUser.UserName;
      DeliveryDetails["DeliveryName"] = CurrentUser.UserName;
    }
    console.log("____________________________", dd);

    if (CheckEmpty(dd.DeliveryName) && PaymentType !== "cod") {
      return firetoast("Please provide your delivery name", "default-error");
    }
    if (CheckEmpty(dd.DeliveryPhoneNumber) && PaymentType !== "cod") {
      return firetoast(
        "Please provide your valid delivery phone number",
        "default-error"
      );
    }

    if (CheckEmpty(dd.DeliveryAddress1) && PaymentType !== "cod") {
      return firetoast(
        "Delivery Address 1 is required to proceed ",
        "default-error"
      );
    }
    // if (
    //   (dd && dd.DeliveryAddress2 && dd.DeliveryAddress2.length && dd.DeliveryAddress2.length < 25 || dd.DeliveryAddress2.length > 45) &&
    //   PaymentType !== "cod"
    // ) {
    //   return firetoast(
    //     "Delivery Address 2 should be between 25 to 45 characters",
    //     "default-error"
    //   );
    // }

    if (CheckEmpty(dd["DeliveryState"])) {
      return firetoast("Please provide delivery state", "default-error");
    }
    console.log(dd["DeliveryCity"], "dd[DeliveryCity]");
    if (CheckEmpty(dd["DeliveryCity"])) {
      return firetoast("Please provide delivery City", "default-error");
    }
    // if (
    //   CheckEmpty(dd["DeliveryZipCode"]) ||
    //   dd["DeliveryZipCode"].length < 4 ||
    //   dd["DeliveryZipCode"].length > 7
    // ) {
    //   return firetoast(
    //     "Please provide valid zipcode between 4 to 7 digits",
    //     "default-error"
    //   );
    // }
    dd["Name"] = CurrentUser["UserName"];

    let validateAddress = await ValidateDHLAddresses();
    console.log(validateAddress, "validateAddress");
    if (validateAddress.address) {
      console.log(dd["DeliveryZipCode"] + "9090");
      //!here rates api
      if (dd["DeliveryZipCode"]) {
        let response;
        var productIds = [];
        console.log("------------------------------------------", CartItems);
        for (let j = 0; j < CartItems.length; j++) {
          productIds.push(CartItems[j].ProductID);
        }
        let orderTotalWeight = [];
        for (let i = 0; i < CartItems.length; i++) {
          orderTotalWeight.push(parseFloat(CartItems[i].Weight));
        }
        console.log(
          parseFloat(orderTotalWeight[0].toFixed(2)),
          "orderTotalWeight"
        );
        console.log(CountrySelect, "CountrySelect");
        if (parseFloat(orderTotalWeight[0].toFixed(2)) < 25) {
          console.log("one piece case");
          response = await BanglaBazarApi.post(`${Endpoint}/api/dhl/rates`, {
            destinationCityName: dd["DeliveryCity"],
            address1: dd["DeliveryAddress1"],
            address2: dd["DeliveryAddress2"],
            postalCode: dd["DeliveryZipCode"],
            ProductIDs: productIds,
            countryID: CountrySelect,
            // plannedShippingDate: planeDate
          });
        } else {
          console.log("multi piece case");
          response = await BanglaBazarApi.post(
            `${Endpoint}/api/dhl/rates-multiple`,
            {
              destinationCityName: dd["DeliveryCity"],
              address1: dd["DeliveryAddress1"],
              address2: dd["DeliveryAddress2"],
              postalCode: dd["DeliveryZipCode"],
              ProductIDs: productIds,
              countryID: CountrySelect,
            }
          );
        }
        console.log(response, "response");
        var total = 0;
        var responses = response.data.saveResponse;
        console.log(responses);
        // for (let j = 0; j < responses && responses.length; j++) {

        //   console.log(responses[j]["data"]);
        //   total +=
        //     parseFloat(responses[j]["data"]["price"]) +
        //     parseFloat(responses[j]["data"]["additional_charge"]);
        // }

        responses &&
          responses.map((item) => {
            total += item.data.price + item.data.additional_charge;
          });

        // return
        console.log("totalValue", total);
        setShippingPrice(parseFloat(total.toFixed(2)));
        setDeliveryBy("dhl");
        SetDeliveryStatus("dhl");
        localStorage.setItem("pp", JSON.stringify(responses));
        setDHLResponse(false);
      } else {
        console.log("else case of shipping prce");
        setShippingPrice(0);
      }
    } else {
      return firetoast(
        `${validateAddress.response}`,
        "error",
        6000,
        "top-center"
      );
    }
  };
  var proceedPayment = async () => {
    var dd = { ...DeliveryDetails };
    console.log();
    dd["GatewayID"] = PaymentMethod.GatewayID;
    dd["Currency"] = PaymentMethod.CurrencyCode;
    dd["AllowStorePickup"] = AllowStorePickup;
    dd["AllowAdminPickup"] = AllowAdminPickup;
    dd["PhoneNumber"] = DeliveryDetails.cus_phone;
    dd["DeliveryState"] = DeliveryDetails.DeliveryState;
    dd["PaymentType"] = PaymentType;
    dd["DeliveryStatus"] = DeliveryBy;
    dd["DeliveryStatus"] = DeliveryStatus;
    //dd["ShippingDate"]=DeliveryDetails.ShippingDate;

    setDeliveryDetails(dd);
    console.log(dd, "parseInt(dd[");
    //bangla
    var letters = /^[A-Za-z ]+$/;
    if (PaymentClient == "Stripe") {
      console.log(dd, "ifcase");
      if (CheckEmpty(dd.Name) && PaymentType !== "cod") {
        return firetoast("Please provide your card name", "default-error");
      }
      if (
        (CheckEmpty(dd.CardNumber) ||
          dd.CardNumber.length < 12 ||
          dd.CardNumber.length > 19) &&
        PaymentType !== "cod"
      ) {
        return firetoast("Valid Card Number is required", "default-error");
      }
      if ((CheckEmpty(dd.CVC) || dd.CVC.length != 3) && PaymentType !== "cod") {
        return firetoast("Valid 3 digits CVC/CVV is required", "default-error");
      }
      console.log(dd.ExpiryDate, "dd.ExpiryDate");
      if (CheckEmpty(dd.ExpiryDate) && PaymentType !== "cod") {
        return firetoast("Valid Expiry date required", "default-error");
      }
      if (CheckEmpty(dd.Address1) && PaymentType !== "cod") {
        return firetoast("Address 1 is required to proceed", "default-error");
      }

      if (CheckEmpty(dd.DeliveryName)) {
        dd["DeliveryName"] = CurrentUser.UserName;
        DeliveryDetails["DeliveryName"] = CurrentUser.UserName;
      }
      if (CheckEmpty(dd.DeliveryName) && PaymentType !== "cod") {
        return firetoast("Please provide your delivery name", "default-error");
      }
      if (
        CheckEmpty(dd.DeliveryPhoneNumber) &&
        PaymentType !== "cod"
        // (CheckEmpty(dd.DeliveryPhoneNumber) ||
        //   dd.DeliveryPhoneNumber.length != 10) &&
        // PaymentType !== "cod"
      ) {
        return firetoast(
          "Please provide your 10 digits phone number without country code",
          "default-error"
        );
      }
      if (CheckEmpty(dd.DeliveryAddress1) && PaymentType !== "cod") {
        return firetoast(
          "Delivery Address 1 is required to proceed",
          "default-error"
        );
      }
      if (CheckEmpty(dd["DeliveryState"])) {
        return firetoast("Please provide delivery state", "default-error");
      }
      if (!dd["DeliveryState"].match(letters)) {
        return firetoast(
          " Delivery state field cannot accept integer value",
          "default-error"
        );
      }
      if (CheckEmpty(dd["DeliveryCity"])) {
        return firetoast("Please provide delivery City", "default-error");
      }
      if (
        CheckEmpty(dd["DeliveryZipCode"]) ||
        dd["DeliveryZipCode"].length !== 4
      ) {
        return firetoast(
          "Please provide a 4 digits valid zipcode",
          "default-error"
        );
      }
    }
    initiateTransaction();
  };
  var getProductNames = () => {
    var array = [];
    for (let i = 0; i < CartItems.length; i++) {
      array.push(CartItems[i].Title);
    }
    return array.toString();
  };
  let initiateTransaction = async () => {
    var data = DeliveryDetails;
    console.log(DeliveryDetails);
    data.CountryID = CartItems[0]["ProductCountry"];
    data.SessionID = localStorage.getItem("accessToken");
    data.total_amount = (TotalPrice + ShippingPrice).toFixed(2);
    if (PaymentClient == "Stripe") {
      data["GatewayID"] = 4;
    } else {
      data["GatewayID"] = 5;
    }
    if (PaymentType === "cod") {
      if (parseInt(CountrySelect) === 16) {
        data["currency"] = "BDT";
      } else {
        //!Currency Change

        data["currency"] = "USD";
      }
    } else {
      data["currency"] = PaymentMethod.CurrencyCode;
    }

    console.log("DeliveryDetails", DeliveryDetails.DeliveryPhoneNumber);

    data["AllowStorePickup"] = AllowStorePickup;
    data["AllowAdminPickup"] = AllowAdminPickup;
    data["PhoneNumber"] = data.cus_phone;
    data["DeliveryStatus"] = DeliveryBy;
    data["DeliveryBy"] = DeliveryBy;

    // data["OrderTotal"] = (TotalPrice + ShippingPrice).toFixed(2);

    data["OrderTotal"] = localStorage.getItem("usaCaseFinalPrice");

    data["product_name"] = getProductNames();
    data["product_category"] = "General";
    data["product_profile"] = "general";
    data["GetawayConfirmation"] = "YES";
    data["cus_email"] = CurrentUser.EmailAddress;

    data["cus_phone"] =
      CurrentUser.PhoneNumber === "null" || !CurrentUser.PhoneNumber
        ? DeliveryDetails.DeliveryPhoneNumber
        : CurrentUser.PhoneNumber;

    data["cus_country"] = CountrySelect;
    data["ShippingHandling"] = ShippingPrice;
    data["saveAddress"] = SaveAddress;
    data["homeAddress"] = HomeAddress;
    data["ProcessStatus"] = "Processing";
    data["VendorPaymentStatus"] = "N";
    data["DeliveryConfirmationPic"] = null;
    data["ShippingLabel"] = null;
    data["TrackingNumber"] = null;

    setSaveAddress(SaveAddress);
    setHomeAddress(HomeAddress);

    data["shipping_method"] = "No";
    data["PaymentType"] = PaymentType;
    data["currency"] = CartItems[0]["Currency"];

    var ProductDetail = [];

    for (let i = 0; i < CartItems.length; i++) {
      var currentProduct = CartItems[i];
      currentProduct["Quantity"] = currentProduct["Total_Quantity"];
      let ProductVariantCombinationDetail = [];
      for (let j = 0; j < ProductCombinationItems[i].length; j++) {
        ProductVariantCombinationDetail.push(ProductCombinationItems[i][j]);
        currentProduct["VendorStoreID"] =
          ProductCombinationItems[i][j]["VendorStoreID"];
      }
      currentProduct["ProductVariantCombinationDetail"] =
        ProductVariantCombinationDetail;
      ProductDetail.push(currentProduct);
    }
    data["ProductDetail"] = ProductDetail;
    // console.log(data["ProductDetail"]);
    let temp_prod_combo = "";
    let temp_array = [];
    var pricePlan = JSON.parse(localStorage.getItem("pp"));
    //   console.log(pricePlan);
    for (let i = 0; i < data["ProductDetail"].length; i++) {
      temp_prod_combo = data["ProductDetail"][i]; // obj in array
      // console.log(temp_prod_combo["ItemsPrice"])
      let currentBase = parseFloat(temp_prod_combo["Price"]); //ItemsPrice in obj
      let currentCombinationPrice = 0;
      for (
        let j = 0;
        j < temp_prod_combo["ProductVariantCombinationDetail"].length;
        j++
      ) {
        // console.log(temp_prod_combo["ProductVariantCombinationDetail"][j])
        currentCombinationPrice += parseFloat(
          temp_prod_combo["ProductVariantCombinationDetail"][j][
            "ProductCombinationPrice"
          ]
        );
      }
      // console.log("priceplan", pricePlan);
      temp_prod_combo["itemsPrice"] = currentBase + currentCombinationPrice;
      temp_prod_combo["itemsShippingHandling"] =
        AllowStorePickup === "Y"
          ? 0
          : parseFloat(pricePlan[i]["data"]["price"]);
      var temp_price_plane_value =
        AllowStorePickup === "Y"
          ? 0
          : parseFloat(pricePlan[i]["data"]["price"]);
      let ItemsBeforeTax =
        parseFloat(currentBase) +
        parseFloat(currentCombinationPrice) +
        temp_price_plane_value;
      temp_prod_combo["itemsBeforeTax"] = ItemsBeforeTax.toFixed(2);
      let ItemsEstimatedTax = 0;
      temp_prod_combo["itemsEstimatedTax"] = ItemsEstimatedTax.toFixed(2);
      temp_prod_combo["itemsTotal"] = (
        ItemsBeforeTax + ItemsEstimatedTax
      ).toFixed(2);
      temp_array.push(temp_prod_combo);
    }

    var items_price = 0;

    for (let i = 0; i < data["ProductDetail"].length; i++) {
      var current = data["ProductDetail"][i];
      items_price += parseFloat(current["Price"]);
      for (
        let j = 0;
        j < current["ProductVariantCombinationDetail"].length;
        j++
      ) {
        var current_comb = current["ProductVariantCombinationDetail"][j];
        items_price += parseFloat(current_comb["ProductCombinationPrice"]);
      }
    }
    if (AllowStorePickup === "Y") {
      data["ShippingHandling"] = 0;
      data["TotalBeforeTax"] = items_price;
      data["DeliveryStatus"] = "SP";
      data["DeliveryBy"] = "SP";
    } else {
      data["ShippingHandling"] = ShippingPrice;
      data["TotalBeforeTax"] = items_price + ShippingPrice;
    }
    data["ItemsPrice"] = items_price;

    data = { ...data, ...PaymentDetails };
    // console.log(data);
    // return;

    if (CheckEmpty(data.DeliveryName)) {
      return firetoast(
        "Please provide name of person to deliver",
        "default-error"
      );
    }
    if (CheckEmpty(data.DeliveryPhoneNumber)) {
      return firetoast("Please provide delivery phone number", "default-error");
    }
    if (CheckEmpty(data.DeliveryAddress1)) {
      return firetoast("Please provide delivery address", "default-error");
    }
    if (CheckEmpty(data.DeliveryCity)) {
      return firetoast("Please select delivery city", "default-error");
    }
    if (CheckEmpty(data.DeliveryZipCode)) {
      return firetoast(
        "Please select provide delivery zipcode",
        "default-error"
      );
    }
    setStripeData(data);
    data.Name = CurrentUser["UserName"];

    try {
      if (PaymentType === "cod") {
        const response = await BanglaBazarApi.post(
          `${Endpoint}/api/payment/processPayment`,
          data
        );
        if (response.data.status) {
          if (response.data.URL_LINK !== "") {
            setOrderNumber(response.data.OrderNumber);
            localStorage.setItem("p_detail", JSON.stringify(CartItems));
            localStorage.setItem(
              "pC_detail",
              JSON.stringify(ProductCombinationItems)
            );
            localStorage.setItem("u_d", JSON.stringify(data));
            localStorage.setItem("o_n", response.data.OrderNumber);

            window.location.href =
              "/payment-checkout?status=success&paymentType=cod";
          } else {
            firetoast(
              "Processing failed, please make sure you provided all the required fields",
              "default-error"
            );
          }
        } else {
          firetoast(
            "Something went wrong while checking out.",
            "default-error"
          );
        }
      } else {
        if (PaymentClient === "SSL Commerz") {
          data["AddressLabel"] = "";
          data["DefaultAddress"] = "";
          data["State"] = "";
          const response = await BanglaBazarApi.post(
            `${Endpoint}/api/sslCommerz/init`,
            data
          );
          if (response.data.status) {
            if (response.data.URL_LINK !== "") {
              setOrderNumber(response.data.OrderNumber);
              localStorage.setItem("p_detail", JSON.stringify(CartItems));
              localStorage.setItem(
                "pC_detail",
                JSON.stringify(ProductCombinationItems)
              );
              localStorage.setItem("u_d", JSON.stringify(DeliveryDetails));
              localStorage.setItem("o_n", response.data.OrderNumber);

              window.location.href = response.data.URL_LINK;
            } else {
              firetoast(
                "Please make sure you provided all the required fields",
                "default-error"
              );
            }
          } else {
            firetoast(
              "Something went wrong while checking out.",
              "default-error"
            );
          }
        } else {
          ///stripe-checkout/:orderNumber/:currency/:price
          setpaymentLoading(true);
          setShowModal(true);
          const response = await BanglaBazarApi.post(
            `${Endpoint}/api/stripe/init`,
            data
          );

          localStorage.setItem("dataUSA", data);

          if (response.data.status) {
            localStorage.setItem("p_detail", JSON.stringify(CartItems));
            localStorage.setItem(
              "pC_detail",
              JSON.stringify(ProductCombinationItems)
            );
            localStorage.setItem("u_d", JSON.stringify(DeliveryDetails));
            localStorage.setItem("o_n", response.data.OrderNumber);

            if (response.data.status) {
              setpaymentLoading(false);
              setShowModal(false);
              setOrderNumber(response.data.OrderNumber);
              setStripeToken(response.data.StripeToken);
              try {
                var response2 = await BanglaBazarApi.post(
                  `${Endpoint}/api/stripe/validate`,
                  {
                    stripeToken: StripeToken,
                    OrderTotal: Math.round(
                      parseFloat(
                        parseFloat(response.data.OrderTotal).toFixed(2)
                      ) * 100
                    ),
                    Currency: response.data.Currency,
                    OrderNumber: response.data.OrderNumber,
                  }
                );

                if (response2.data.status) {
                  window.location.href = `/payment-checkout?status=success&paymentType=card`;
                } else {
                  return window.alert(
                    response2.data.message || response2.data.error
                  );
                }
              } catch (e) {
                console.log(e);
              }
            } else {
              window.location.href = `/payment-checkout?status=failed&failure_message=${response.data.message}&paymentType=card`;
            }
          } else {
            setpaymentLoading(false);
            setShowModal(false);

            firetoast(`${response.data.message}`, "default-error");
          }
        }
      }
    } catch (e) {
      firetoast("Something went wrong!", "default-error");
    }
  };

  var PopulatePaymentFields = (data) => {
    var dd = { ...DeliveryDetails };
    dd["Name"] = data.Name;
    dd["Address1"] = data.Address1;
    dd["Address2"] = data.Address2;
    dd["CountryID"] = CartItems[0]["ProductCountry"];
    dd["City"] = data.City;
    dd["State"] = data.State;
    dd["ZipCode"] = data.ZipCode;
    dd["CardNumber"] = data.CardNumber;
    setDeliveryDetails(dd);
    setShowPaymentFields(true);
    console.log(dd.CountryID, "(dd.CountryID");
    // setPayment(dd.CountryID);
  };
  var PopulateDeliveryFields = async (data) => {
    var pd = { ...DeliveryDetails };
    pd["DeliveryName"] = data.Name;
    pd["DeliveryPhoneNumber"] = data.PhoneNumber;
    pd["cus_phone"] = data.PhoneNumber;
    pd["cus_country"] = data.CountryID;
    pd["DeliveryAddress1"] = data.Address1;
    pd["DeliveryAddress2"] = data.Address2;
    pd["DeliveryCity"] = data.City;
    pd["DeliveryState"] = data.State;
    pd["DeliveryZipCode"] = data.ZipCode;
    pd["CountryID"] = data.CountryID;
    pd["UserNote"] = data.UserNote;
    pd["AddressLabel"] = data.AddressLabel;
    pd["DeliveryCityID"] = data.CityID;
    pd["DeliveryZoneID"] = data.ZoneID;
    pd["AreaID"] = data.AreaID;
    pd["DeliveryAreaID"] = data.AreaID;
    pd["DeliveryZoneID"] = data.ZoneID;
    pd["DeliveryZone"] = data.DeliveryZone;
    pd["DeliveryArea"] = data.DeliveryArea;
    pd["DeliveryUserNote"] = data.UserNote;
    pd["PathaoCityID"] = data.PathaoCityID;
    console.log(pd, "_+++++++++++++++++++++++++++++++++PathaoCityID");
    await setDeliveryDetails(pd);
    await setShowDeliveryFields(true);
    await CalculateShippingv2({
      DeliveryCityID: data.PathaoCityID,
      DeliveryZoneID: data.ZoneID,
      DeliveryCity: data.City,
    });

    setSelectedFromPrevious(false);
  };

  const handleDeleteClick = (item) => {
    console.log("item", item.UserAddressID);

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to proceed with the deletion?", // Changed text here
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6f9642", // Bootstrap "success" color
      cancelButtonColor: "#d33", // Bootstrap "danger" color
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true); // Show loader

        try {
          const response = await BanglaBazarApi.delete(
            `${Endpoint}/api/payment/user-history/remove-address/${item.UserAddressID}`
          );

          setIsLoading(false); // Hide loader

          // Check if the response is successful
          if (response) {
            toast.success("Address Deleted Successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            // Handle error here
            Swal.fire("Error", "Something went wrong!", "error");
          }
        } catch (error) {
          setIsLoading(false); // Hide loader in case of error
          // Handle error here
          Swal.fire("Error", "Network error!", "error");
        }
      }
    });

    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: "You won't be able to revert this!",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes, delete it!'
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    //     setIsLoading(true); // Show loader

    //     try {
    //       const response = await BanglaBazarApi.delete(
    //         `${Endpoint}/api/payment/user-history/remove-address/${item.UserAddressID}`,
    //       );

    //       setIsLoading(false); // Hide loader

    //       // Check if the response is successful
    //       if (response) {
    //         toast.success("Address Deleted Successfully!", {
    //           position: "top-right",
    //           autoClose: 5000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //         });

    //         setTimeout(() => {
    //           window.location.reload();
    //         }, 2000);

    //       } else {
    //         // Handle error here
    //         Swal.fire('Error','Something went wrong!', 'error');
    //       }
    //     } catch (error) {
    //       setIsLoading(false); // Hide loader in case of error
    //       // Handle error here
    //       Swal.fire('Error', 'Network error!', 'error');
    //     }
    //   }
    // });
  };

  const [phoneInputCountry, setPhoneInputCountry] = useState("us"); // Default to 'us'

  useEffect(() => {
    // Retrieve ISO code from localStorage
    const storedIso = localStorage.getItem("iso");
    if (storedIso) {
      setPhoneInputCountry(storedIso.toLowerCase());
    }
  }, []);

  return (
    <>
      {PaymentClient && PaymentClient == "Stripe" && PaymentType !== "cod" && (
        <>
          <h3 className="text-default mt-3 mb-3"> Payment Information</h3>
          {!ShowPaymentFields && (
            <div className="text-right" style={{ textAlign: "right" }}>
              <buton
                style={{ cursor: "pointer" }}
                className="text-default td-none"
                onClick={() => {
                  //setDeliveryDetails({});
                  setShowDeliveryFields(true);
                }}
              >
                <i className="fas fa-plus"></i> Add New
              </buton>
            </div>
          )}
          <>
            {!ShowPaymentFields &&
              PayerHistory &&
              PayerHistory.length > 0 &&
              PayerHistory.map((item, index) => (
                <div className="card mt-1" key={index}>
                  <div className="card-body">
                    <div className="d-flex align-tems-center w-100">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input default-check-color"
                          type="checkbox"
                          id="inlineCheckbox3"
                          onClick={() => PopulatePaymentFields(item)}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div style={{ marginLeft: "15px", fontSize: "15px" }}>
                          {item.CardNumber.length > 4
                            ? item.CardNumber.slice(0, -4) + "****"
                            : item.CardNumber}
                        </div>
                        <div>
                          {item.PaymentType === "card" && (
                            <img src="https://img.icons8.com/external-obvious-flat-kerismaker/24/000000/external-card-payment-ecommerce-flat-obvious-flat-kerismaker.png" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
          {PaymentClient && PaymentClient == "Stripe" && ShowPaymentFields && (
            <form>
              <div className="row mt-2">
                <div className="col-md-6 col-sm-12">
                  <label>
                    Card Holder Name <RequiredField />
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter your name"
                    name="Name"
                    defaultValue={
                      DeliveryDetails["Name"] ? DeliveryDetails["Name"] : ""
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>
                    Card Number <RequiredField />
                  </label>
                  <input
                    onChange={handleChange}
                    name="CardNumber"
                    type="number"
                    maxlength="19"
                    minlength="12"
                    defaultValue={
                      DeliveryDetails["CardNumber"]
                        ? DeliveryDetails["CardNumber"]
                        : ""
                    }
                    className="form-control"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6 col-sm-12">
                  <label>
                    CVC/CVV <RequiredField />
                  </label>
                  <input
                    onChange={handleChange}
                    name="CVC"
                    type="password"
                    maxlength="3"
                    defaultValue={
                      DeliveryDetails["CVC"] ? DeliveryDetails["CVC"] : ""
                    }
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>
                    Expiry Date <RequiredField />
                  </label>
                  <input
                    onChange={handleChange}
                    name="ExpiryDate"
                    placeholder="MM / YY"
                    maxlength="5"
                    autocomplete="cc-exp"
                    defaultValue={
                      DeliveryDetails["ExpiryDate"]
                        ? DeliveryDetails["ExpiryDate"]
                        : ""
                    }
                    className="form-control"
                  />
                  <span style={{ color: "red" }}>
                    Format: MM/YY (e.g. 02/33)
                  </span>
                </div>
                <div className="col-12">
                  <label>
                    Address 1 <RequiredField />{" "}
                    {/* {DeliveryDetails["Address1"] &&
                      DeliveryDetails["Address1"].length > 25 &&
                      Icons.GreenTick} */}
                  </label>
                  <input
                    className="form-control"
                    name="Address1"
                    defaultValue={
                      DeliveryDetails["Address1"]
                        ? DeliveryDetails["Address1"]
                        : ""
                    }
                    onChange={handleChange}
                  />

                  {/* <span
                    className="text-default"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {" "}
                    {DeliveryDetails["Address1"] &&
                      `${DeliveryDetails["Address1"].length < 26
                        ? `Address should be 25 character long  (${25 - DeliveryDetails["Address1"].length
                        })`
                        : ""
                      }`}{" "}
                  </span> */}
                </div>
                <div className="col-12 mt-2">
                  <label>
                    Address 2{" "}
                    {/* {DeliveryDetails["Address2"] &&
                      DeliveryDetails["Address2"].length > 25 &&
                      Icons.GreenTick} */}
                  </label>
                  <input
                    className="form-control"
                    name="Address2"
                    defaultValue={
                      DeliveryDetails["Address2"]
                        ? DeliveryDetails["Address2"]
                        : ""
                    }
                    onChange={handleChange}
                  />

                  {/* <span
                    className="text-default"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {" "}
                    {DeliveryDetails["Address2"] &&
                      `${DeliveryDetails["Address2"].length < 26
                        ? `Address should be 25 character long (${25 - DeliveryDetails["Address2"].length
                        })`
                        : ""
                      }`}{" "}
                  </span> */}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6 col-sm-12">
                  <label>State</label>
                  <input
                    className="form-control"
                    name="State"
                    defaultValue={
                      DeliveryDetails["State"] ? DeliveryDetails["State"] : ""
                    }
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>City</label>
                  <input
                    className="form-control"
                    name="City"
                    defaultValue={
                      DeliveryDetails["City"] ? DeliveryDetails["City"] : ""
                    }
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6 col-sm-12">
                  <label>ZipCode</label>
                  <input
                    className="form-control"
                    name="ZipCode"
                    type="number"
                    defaultValue={
                      DeliveryDetails["ZipCode"]
                        ? DeliveryDetails["ZipCode"].replace(/\D/g, "")
                        : ""
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6 col-sm-12">
                  <div>
                    <label className="text-white"> aa</label>
                  </div>
                  <label>
                    {" "}
                    <div className="d-flex align-items-center">
                      <div>
                        {" "}
                        <input
                          type="checkbox"
                          className="form-check-input default-check-color"
                          onChange={(e) => {
                            e.target.checked
                              ? setSaveAddress(true)
                              : setSaveAddress(false);
                          }}
                          defaultChecked={true}
                        />{" "}
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {" "}
                        Save address for later use
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </form>
          )}
        </>
      )}
      <>
        <h3 className="text-default mt-3 mb-3"> Delivery Information</h3>
        {!ShowDeliveryFields && (
          <div className="text-right" style={{ textAlign: "right" }}>
            <buton
              style={{ cursor: "pointer" }}
              className="text-default td-none"
              onClick={() => {
                //setDeliveryDetails({});
                setShowDeliveryFields(true);
              }}
            >
              <i className="fas fa-plus"></i> Add New
            </buton>
          </div>
        )}
        {!ShowDeliveryFields &&
          DeliveryHistory &&
          DeliveryHistory.length > 0 &&
          DeliveryHistory.map((item, index) => (
            <div className="card" key={index}>
              <div className="card-body">
                <div className="d-flex align-tems-center w-100">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input default-check-color"
                      type="checkbox"
                      id="inlineCheckbox3"
                      onClick={() => {
                        console.log(
                          "________________________________________-",
                          item
                        );
                        // return
                        PopulateDeliveryFields(item);
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div style={{ marginLeft: "15px", fontSize: "15px" }}>
                      {item.Address1}
                    </div>
                    <div
                      style={{
                        marginLeft: "15px",
                        fontSize: "15px",
                        display: "flex",
                      }}
                    >
                      {item.City}, {item.State}
                      <div>
                        <button
                          style={{
                            background: "transparent",
                            border: "none",
                            padding: 0,
                            margin: 0,
                            cursor: "pointer",
                            color: "grey",
                            marginLeft: "8px",
                            boxShadow: "10px",
                          }}
                          onClick={() => handleDeleteClick(item)}
                        >
                          {isLoading ? (
                            <i className="fas fa-spinner fa-spin"></i>
                          ) : (
                            <i className="fas fa-trash"></i>
                          )}
                          {/* <i className="fas fa-spinner fa-spin"></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {ShowDeliveryFields && (
          <>
            {SelectedFromPrevious && (
              <div className="row mt-2">
                <div className="col-md-6 col-sm-12">
                  <div>
                    <label className="text-white"> aa</label>
                  </div>
                  <label>
                    {" "}
                    <div className="d-flex align-items-center">
                      <div>
                        {" "}
                        <input
                          type="checkbox"
                          className="form-check-input default-check-color"
                          onChange={() => {
                            setSameAsAbove(!SameAsAbove);
                            handleSameAsAbove(!SameAsAbove);
                          }}
                        />{" "}
                      </div>
                      <div style={{ paddingLeft: "10px" }}> Same as above</div>
                    </div>
                  </label>
                </div>
              </div>
            )}
            <form>
              <div className="row mt-2">
                <div className="col-md-6 col-sm-12">
                  <label>
                    Full Name <RequiredField />
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter your name"
                    name="DeliveryName"
                    defaultValue={
                      DeliveryDetails["DeliveryName"]
                        ? DeliveryDetails["DeliveryName"]
                        : CurrentUser.UserName
                    }
                    onChange={DeliveryhandleChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>
                    Phone Number <RequiredField />
                  </label>
                  {/* <input
                    className="form-control"
                    onChange={DeliveryhandleChange}
                    defaultValue={
                      DeliveryDetails["DeliveryPhoneNumber"]
                        ? DeliveryDetails["DeliveryPhoneNumber"]
                        : ""
                    }
                    name="DeliveryPhoneNumber"
                  /> */}

                  {/* <PhoneInput
                    // countryCodeEditable={false}
                    value={
                      DeliveryDetails["DeliveryPhoneNumber"]
                        ? DeliveryDetails["DeliveryPhoneNumber"]
                        : ""}
                    defaultValue={
                      DeliveryDetails["DeliveryPhoneNumber"]
                        ? DeliveryDetails["DeliveryPhoneNumber"]
                        : CurrentUser.PhoneNumber}
                    inputClass="adduser-phone"
                    country={'us'}
                    onlyCountries={CountryCode}
                    onChange={(phone) => {
                      DeliveryDetails['DeliveryPhoneNumber'] = phone;
                      // console.log({ ...DeliveryDetails });
                    }}
                    inputProps={{
                      minLength: 8,
                      maxLength: 19,
                    }}
                  /> */}

                  <PhoneInput
                    // countryCodeEditable={false}
                    value={
                      DeliveryDetails["DeliveryPhoneNumber"]
                        ? DeliveryDetails["DeliveryPhoneNumber"]
                        : ""
                    }
                    defaultValue={
                      DeliveryDetails["DeliveryPhoneNumber"]
                        ? DeliveryDetails["DeliveryPhoneNumber"]
                        : CurrentUser.PhoneNumber
                    }
                    inputClass="adduser-phone"
                    country={phoneInputCountry} // Set the retrieved ISO code
                    onlyCountries={CountryCode}
                    onChange={(phone) => {
                      DeliveryDetails["DeliveryPhoneNumber"] = phone;
                      // console.log({ ...DeliveryDetails });
                    }}
                    inputProps={{
                      minLength: 8,
                      maxLength: 19,
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <label>
                    Address 1 <RequiredField />
                    {/* {DeliveryDetails &&
                      DeliveryDetails["DeliveryAddress1"] &&
                      (DeliveryDetails["DeliveryAddress1"].length < 25 ||
                        DeliveryDetails["DeliveryAddress1"].length > 45) &&
                      Icons.GreenTick} */}
                  </label>
                  <input
                    className="form-control"
                    name="DeliveryAddress1"
                    defaultValue={
                      DeliveryDetails["DeliveryAddress1"]
                        ? DeliveryDetails["DeliveryAddress1"]
                        : ""
                    }
                    onChange={DeliveryhandleChange}
                  />
                  <small>
                    {/* <span className="text-default">
                      {" "}
                      {DeliveryDetails &&
                        DeliveryDetails["DeliveryAddress1"] &&
                        `${DeliveryDetails["DeliveryAddress1"].length < 25 ||
                          DeliveryDetails["DeliveryAddress1"].length > 45
                          ? `Address should be 25 to45 characters long (${25 - DeliveryDetails["DeliveryAddress1"].length
                          })`
                          : ""
                        }`}{" "}
                    </span> */}
                  </small>
                </div>
                <div className="col-12">
                  <label>Address 2</label>
                  <input
                    className="form-control"
                    name="DeliveryAddress2"
                    defaultValue={
                      DeliveryDetails["DeliveryAddress2"]
                        ? DeliveryDetails["DeliveryAddress2"]
                        : ""
                    }
                    onChange={DeliveryhandleChange}
                  />

                  {/* <span
                    className="text-default"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {" "}
                    {DeliveryDetails &&
                      DeliveryDetails["DeliveryAddress2"] &&
                      `${DeliveryDetails["DeliveryAddress2"].length < 26
                        ? `Address should be 25 character long (${25 - DeliveryDetails["DeliveryAddress2"].length
                        })`
                        : ""
                      }`}{" "}
                  </span> */}
                </div>
                <div className="col-6">
                  <label>Address Label</label>
                  <input
                    className="form-control"
                    name="AddressLabel"
                    defaultValue={
                      DeliveryDetails && DeliveryDetails["AddressLabel"]
                        ? DeliveryDetails["AddressLabel"]
                        : ""
                    }
                    onChange={DeliveryhandleChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div>
                    <label className="text-white"> aa</label>
                  </div>
                  <label>
                    {" "}
                    <div className="d-flex align-items-center">
                      <div>
                        {" "}
                        <input
                          type="checkbox"
                          className="form-check-input default-check-color"
                          onChange={(e) => {
                            e.target.checked
                              ? setHomeAddress(true)
                              : setHomeAddress(false);
                          }}
                          defaultChecked={true}
                        />{" "}
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {" "}
                        Mark as home address
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div className="row mt-2">
                {/* //!implement states */}
                <div className="col-md-6 col-sm-12">
                  <label>
                    State / District / Province <RequiredField />
                    {/* {JSON.stringify(DeliveryDetails["DeliveryState"])} */}
                  </label>
                  <select
                    className="form-control"
                    // selected={DeliveryDetails["DeliveryState"]}
                    onChange={(e) => {
                      if (e.target.value === "select") {
                        e.preventDefault();
                      } else {
                        DeliveryhandleChange(e);
                      }
                    }}
                    // defaultValue={
                    //   DeliveryDetails["DeliveryState"]
                    //     ? DeliveryDetails["DeliveryState"]
                    //     : ""
                    // }
                    name="DeliveryState"
                    id="d-state"
                    placeholder="Enter state"
                  >
                    <option value="select">Select State</option>
                    {StateList &&
                      StateList.map((item, index) => (
                        <option
                          value={JSON.stringify(item)}
                          key={index}
                          selected={
                            DeliveryDetails &&
                            DeliveryDetails["DeliveryState"] &&
                            DeliveryDetails["DeliveryState"] === item.State
                          }
                        >
                          {item.State}
                        </option>
                      ))}
                  </select>
                  {/* <input
                    className="form-control"
                    onChange={DeliveryhandleChange}
                    defaultValue={
                      DeliveryDetails["DeliveryState"]
                        ? DeliveryDetails["DeliveryState"]
                        : ""
                    }
                    name="DeliveryState"
                  /> */}
                </div>

                <div className="col-md-6 col-sm-12">
                  <label>
                    City <RequiredField />
                  </label>
                  {CitiesList && CitiesList.length > 0 ? (
                    <select
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value === "select") {
                          e.preventDefault();
                        }
                        //setStoreState(JSON.parse(e.target.value).State);
                        else {
                          DeliveryhandleChange(e);
                        }
                      }}
                      defaultValue={
                        DeliveryDetails["DeliveryCity"]
                          ? DeliveryDetails["DeliveryCity"]
                          : ""
                      }
                      name="DeliveryCity"
                    >
                      <option value="select">Select City </option>
                      {CitiesList &&
                        CitiesList.map((item, index) => (
                          <option
                            value={JSON.stringify(item)}
                            key={index}
                            selected={
                              DeliveryDetails["DeliveryCity"] === item.City
                            }
                          >
                            {item.City}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <input
                      className="form-control"
                      onChange={DeliveryhandleChange}
                      value={
                        DeliveryDetails["DeliveryCity"]
                          ? DeliveryDetails["DeliveryCity"]
                          : ""
                      }
                      name="DeliveryCity"
                    />
                  )}
                </div>

                {/* <div className="col-md-6 col-sm-12">
                  <label>
                    Citys <RequiredField />
                  </label>
                  {CityList && CityList.length > 0 ? (
                    <select
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value === "select") {
                          e.preventDefault();
                        }
                        //setStoreState(JSON.parse(e.target.value).State);
                        else {
                          DeliveryhandleChange(e);
                        }
                      }}
                      defaultValue={
                        DeliveryDetails["DeliveryCity"]
                          ? DeliveryDetails["DeliveryCity"]
                          : ""
                      }
                      name="DeliveryCity"
                    >
                      <option value="select">Select City </option>
                      {CityList &&
                        CityList.sort((a, b) => a.City.localeCompare(b.City)).map((item, index) => (
                          // CityList.map((item, index) => (
                          <option
                            value={JSON.stringify(item)}
                            key={index}
                            selected={
                              DeliveryDetails["DeliveryCity"] === item.City
                            }
                          >
                            {item.City}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <input
                      id="dcity"
                      className="form-control"
                      onChange={DeliveryhandleChange}

                      value={
                        DeliveryDetails["DeliveryCity"]
                          ? DeliveryDetails["DeliveryCity"]
                          : ""
                      }
                      name="DeliveryCity"
                    />
                  )}
                </div> */}
                <div className="col-md-6 col-sm-12 mt-3">
                  <label>
                    ZipCode <RequiredField />
                  </label>
                  <input
                    className="form-control"
                    name="DeliveryZipCode"
                    id="DeliveryZipCode"
                    type="text"
                    value={
                      DeliveryDetails["DeliveryZipCode"]
                        ? DeliveryDetails["DeliveryZipCode"]
                        : ""
                    }
                    onChange={async (e) => {
                      DeliveryhandleChange(e);
                    }}
                  />
                  <large>
                    <span className="text-default">
                      {" "}
                      Valid ZipCode is required for shipping price calculation
                    </span>
                  </large>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6 col-sm-12">
                  <label>Desired Delivery Date</label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    name="ShippingDate"
                    min={new Date().toISOString().slice(0, 16)}
                    max={new Date(Date.now() + 10 * 24 * 60 * 60 * 1000)
                      .toISOString()
                      .slice(0, 16)}
                    onChange={DeliveryhandleChange}
                  />

                  <large className="text-default">
                    We will try to honor that, no guarantee
                  </large>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Delivery Note</label>
                  <textarea
                    className="form-control"
                    name="DeliveryUserNote"
                    defaultValue={
                      DeliveryDetails && DeliveryDetails["DeliveryUserNote"]
                        ? DeliveryDetails["DeliveryUserNote"]
                        : ""
                    }
                    onChange={DeliveryhandleChange}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12 col-sm-12">
                  <div>
                    <label className="text-white"> aa</label>
                  </div>
                  <label>
                    {" "}
                    <div className="d-flex align-items-center">
                      <div>
                        {" "}
                        <input
                          type="checkbox"
                          className="form-check-input default-check-color"
                          onClick={() => setAgreeTerms(!AgreeTerms)}
                        />{" "}
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {" "}
                        I agree to the{" "}
                        <a
                          style={{
                            color: "#6f9642",
                            textDecoration: "underline",
                          }}
                          href="/terms-conditions"
                          target="_blank"
                        >
                          Terms & Conditions ,
                        </a>{" "}
                        <a
                          style={{
                            color: "#6f9642",
                            textDecoration: "underline",
                          }}
                          href="/refund-and-return"
                          target="_blank"
                        >
                          Refund policy{" "}
                        </a>
                        and value{" "}
                        <a
                          style={{
                            color: "#6f9642",
                            textDecoration: "underline",
                          }}
                          href="/privacy-policy"
                          target="_blank"
                        >
                          Privacy policy
                        </a>
                        .
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </form>
          </>
        )}

        <div className="row">
          <div style={{ textAlign: "right" }}>
            {ShowCheckoutButton ? (
              <button
                className="btn btn-success"
                disabled={paymentLoading}
                onClick={() => initiateTransaction()}
              >
                Proceed Checkout
              </button>
            ) : (
              <div className="d-flex justify-content-between mt-5">
                <div>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      setContinueButton(!ContinueButton);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setShowPrevPayment(true);
                      setPaymentMethod(null);
                    }}
                  >
                    Previous
                  </button>
                </div>
                {DHLResponse ? (
                  <div>
                    <button
                      className="btn btn-success"
                      onClick={() => validateDHL()}
                    >
                      Confirm Address
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className="btn btn-success"
                      disabled={!AgreeTerms}
                      onClick={() => savePaymentDetails()}
                    >
                      Checkout
                      {/* Checkout2 */}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
      <Modal
        toggle={() => setShowModal(!ShowModal)}
        isOpen={ShowModal}
        backdrop="static"
        size="md"
      >
        <ModalBody>
          <Loading text="Please wait while we process!" />
        </ModalBody>
      </Modal>
    </>
  );
}
export default GlobalDeliveryDHL;
