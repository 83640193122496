import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import NoStore from "../../../../../assets/images/no-store.svg";
import CsvDownload from "react-json-to-csv";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";

import Endpoint from "../../../../../Utils/Endpoint";
import Loading from "../../../../../Utils/Loading";
import CapitalizeFirstWord from "../../../../../Utils/CapitalizeFirstWord";
import moment from "moment";
import CheckEmpty from "../../../../../Utils/CheckEmpty";
import firetoast from "../../../../../Helpers/FireToast";
import BanglaBazarApi from "../../../../Api/BanglaBazarApi";
import { RequiredField } from "../../../../../Utils/Required-field";

export function CurrencyAudits() {
  const [currencyAudits, setCurrencyAudits] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const {
        data: { getCurrencyAuditList },
      } = await BanglaBazarApi.get(
        `${Endpoint}/api/currency/get-currency-audit-list?date=${selectedDate}`
      );

      // Assuming the response data is the array of currency rates
      setIsLoading(false);
      setCurrencyAudits(getCurrencyAuditList);
    } catch (err) {
      setIsLoading(false);
      setCurrencyAudits([]);
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <>
      <div
        className="card mt-5"
        style={{ maxHeight: "450px", overflowY: "auto" }}
      >
        <style>
          {`
          .table-responsive::-webkit-scrollbar {
            width: 6px;
          }

          .table-responsive::-webkit-scrollbar-thumb {
            background-color: green;
            border-radius: 5px;
          }
        `}
        </style>

        <div
          // className="card-header bg-white d-flex justify-content-between mt-4"
          className="card-header bg-white "
          style={{ borderBottom: "1px solid white" }}
        >
          <h5 className="ftw-400 text-default mt-1 mb-2 ">Currency Audits</h5>
        </div>
        <div className="col-sm-3">
                    <div className="input-group mb-3">
                      <input
                        type="date"
                        className="form-control form-control-sm"
                        placeholder="By Date"
                        onChange={(e) => setSelectedDate(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-success"
                          style={{ padding: "9px 12px" }}
                          onClick={async()=>{
                            await fetchData()
                          }}
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="table-responsive">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>From Country</th>
                      <th>To Country</th>
                      <th>Currency rate</th>
                      <th>Last Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currencyAudits.map((rate, index) => (
                      <tr key={index}>
                        <td>{rate.FromCurrency}</td>
                        <td>{rate.ToCurrency}</td>
                        <td>{rate.Rate}</td>
                        <td>{rate.LastUpdate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
