import Void from "../assets/images/void.svg";
export function NoData() {
  return (
    <>
      <img
        src={Void}
        className="mt-2"
        style={{ height: "80px", opacity: "0.9" }}
      />
      <h4 className="text-default mt-2">No Data to display</h4>
    </>
  );
}
