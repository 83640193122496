import moment from "moment";
import Endpoint from "../../../../../Utils/Endpoint";
import BanglaLogo from "./../../../../../assets/images/favicon.png";
import Icons from "../../../../../Utils/Icons";
import firetoast from "../../../../../Helpers/FireToast";
import BanglaBazarApi from "../../../../Api/BanglaBazarApi";
import jsPDF from "jspdf";

import html2canvas from "html2canvas";

import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { CurrentUser } from "../../../../../Helpers/Auth";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
function setPageSize(cssPageSize) {
  const style = document.createElement("style");
  style.innerHTML = `@page {size: ${cssPageSize}}`;
  style.id = "page-orientation";
  document.head.appendChild(style);
}

function AdminInvoiceForm({
  OrderDeliveryDetails,
  OrderDetails,
  getOrderDetails,
  orientation = "portrait",
  type,
}) {
  const [ByDeliveryDriver, setByDeliveryDriver] = useState([]);
  const [ByDeliveryClient, setByDeliveryClient] = useState([]);
  const [profile, setProfile] = useState(null);
  const history = useHistory();
  const { orderStatus } = useParams();

  // var getUserInfo = async (UserID) => {
  //     try {
  //         var response = await BanglaBazarApi.get(
  //             `${Endpoint}/api/admin/get/${UserID}`
  //         );
  //         let data = response.data.profile;
  //         setProfile(data);
  //         console.log("================================>", profile);
  //     } catch (e) {
  //         console.log(e);
  //     }
  // };

  useEffect(() => {
    if (OrderDetails) {
      let currentOrders = OrderDetails.ProductDetail;
      let array1 = [];
      let array2 = [];
      let array3 = [];

      for (let i = 0; i < currentOrders.length; i++) {
        console.log(currentOrders[i]);
        console.log(currentOrders[i].UserID);
        // getUserInfo(currentOrders[i].UserID)
        if (currentOrders[i].DeliveryDriverID) {
          array1.push(currentOrders[i]);
        } else if (currentOrders[i].ConsignmentId) {
          array2.push(currentOrders[i]);
        }
      }
      setByDeliveryDriver(array1);
      setByDeliveryClient(array2);
    }
  }, [OrderDetails]);
  let getSingleTotal = (status, item) => {
    let product = item;
    let array = item["ProductCombinations"];
    let basePrice = parseFloat(product.BasePrice);
    let variantValues = [];
    var variationsSum = 0;
    for (let i = 0; i < array.length; i++) {
      variationsSum += parseFloat(array[i].ProductCombinationPrice);
      variantValues.push(array[i].OptionValue);
    }
    const totalSingle = basePrice + variationsSum;

    if (status === 0) {
      return totalSingle;
    } else if (status === 1) {
      return parseFloat(item.Quantity) * totalSingle;
    } else {
      return variantValues.join(",");
    }
  };
  let getOverAllTotal = () => {
    var totalCount = 0;
    let indexes = OrderDetails["ProductDetail"];
    for (let i = 0; i < indexes.length; i++) {
      let currentProduct = indexes[i];
      let currentCombination = currentProduct["ProductCombinations"];
      totalCount += parseFloat(currentProduct.BasePrice);
      for (let j = 0; j < currentCombination.length; j++) {
        totalCount += parseFloat(currentCombination[j].ProductCombinationPrice);
      }
      totalCount = totalCount * parseInt(currentProduct.Quantity);
    }
    return totalCount;
  };

  const downloadPDF = () => {
    const input = document.getElementById("invoice");

    // Set the options for html2canvas
    const canvasOptions = {
      scale: 2, // Adjust this for resolution
      useCORS: true, // To handle cross-origin images
      logging: true, // For debugging purposes
      width: input.offsetWidth, // Capture the actual width
      height: input.offsetHeight, // Capture the actual height
    };

    html2canvas(input, canvasOptions)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Standard A4 dimensions: 210mm x 297mm
        const pdfWidth = 210;
        const pdfHeight = 297;

        // Calculate the aspect ratio for the PDF
        const contentAspectRatio = canvas.width / canvas.height;
        const pdfAspectRatio = pdfWidth / pdfHeight;

        let finalPdfWidth, finalPdfHeight;

        if (contentAspectRatio > pdfAspectRatio) {
          // Width is the limiting factor
          finalPdfWidth = pdfWidth;
          finalPdfHeight = pdfWidth / contentAspectRatio;
        } else {
          // Height is the limiting factor
          finalPdfHeight = pdfHeight;
          finalPdfWidth = pdfHeight * contentAspectRatio;
        }

        const pdf = new jsPDF({
          orientation: contentAspectRatio > 1 ? "landscape" : "portrait",
          unit: "mm",
          format: [finalPdfWidth, finalPdfHeight],
        });

        // Add the image to the PDF
        pdf.addImage(imgData, "PNG", 0, 0, finalPdfWidth, finalPdfHeight);
        pdf.save("download.pdf");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container">
      {OrderDeliveryDetails && (
        <div className="col-md-12">
          <div className="invoice" id="invoice">
            <div className="invoice-company text-inverse d-flex justify-content-between">
              <div>
                <img src={BanglaLogo} style={{ height: "25px" }} /> BanglaBazar{" "}
              </div>
            </div>
            <div className="invoice-header">
              <div className="invoice-from">
                {/* {JSON.stringify(OrderDetails)} */}
                <address className="m-t-5 m-b-5">
                  <strong className="text-inverse">From</strong>
                  <br />
                  {OrderDeliveryDetails && OrderDeliveryDetails.CompanyName}
                  <br />
                  {OrderDeliveryDetails && OrderDeliveryDetails.Address1}
                  <br />
                  {OrderDeliveryDetails.City}, {OrderDeliveryDetails.ZipCode}
                  <br />
                  Phone:{" "}
                  {OrderDeliveryDetails && OrderDeliveryDetails.BusinessPhone}
                </address>
              </div>
              <div className="invoice-to">
                <address className="m-t-5 m-b-5">
                  <strong className="text-inverse">To</strong>
                  <br />
                  {OrderDeliveryDetails &&
                    OrderDeliveryDetails.DeliveryName}{" "}
                  <br />
                  {OrderDeliveryDetails.DeliveryAddress1}
                  {console.log(
                    OrderDeliveryDetails.DeliveryAddress1,
                    "OrderDeliveryDetails.DeliveryAddress1}"
                  )}
                  <br />
                  {OrderDeliveryDetails.DeliveryCity},{" "}
                  {OrderDeliveryDetails.DeliveryZipCode}
                  <br />
                  Phone: {OrderDeliveryDetails.DeliveryPhoneNumber}
                </address>
              </div>
              <div className="invoice-date">
                <small>Invoice</small>
                <div className="date text-inverse m-t-5">
                  {OrderDeliveryDetails.DesireDate &&
                    moment(OrderDeliveryDetails.DesireDate).format(
                      "DD-MM-YYYY"
                    )}
                </div>
                <div className="invoice-detail">
                  Order#: {OrderDeliveryDetails.OrderNumber}
                  <br />
                  TranId#: {OrderDetails.TransactionID}
                </div>
                <div>
                  <div className="invoice-price-right">
                    <small>TOTAL</small>{" "}
                    {console.log(
                      OrderDetails.ProductDetail[0],
                      "OrderDetails.ProductDetail[0]"
                    )}
                    <span className="f-w-600">
                      {" "}
                      {OrderDetails.ProductDetail[0].Currency}{" "}
                      {OrderDetails?.OrderTotal}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* {JSON.stringify(orderStatus)} */}

            {orderStatus !== "usps" && (
              <>
                <div className="invoice-content">
                  <div className="table-responsive">
                    <table className="table table-invoice">
                      <thead>
                        <tr>
                          <th className="text-center">PRODUCT</th>
                          <th className="text-center">VARIATIONS</th>
                          <th className="text-center">PRICE</th>
                          <th className="text-center">QUANTITY</th>
                          <th className="text-center">SUBTOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {OrderDetails["ProductDetail"].map((item, index) => (
                          <tr key={index} className="text-center">
                            <td>
                              <div>{item.Title}</div>
                            </td>
                            <td>{getSingleTotal(2, item)}</td>
                            <td>{`${item.Currency} ${getSingleTotal(
                              0,
                              item
                            )}`}</td>
                            <td>
                              {" "}
                              <div className="product-quantity">
                                {item.Quantity}
                              </div>
                            </td>
                            <td>{`${item.Currency} ${getSingleTotal(
                              1,
                              item
                            )}`}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="invoice-price">
                    <div className="invoice-price-left">
                      <div className="invoice-price-row">
                        <div className="sub-price">
                          <small>TAX</small>
                          <span className="text-inverse">
                            {OrderDetails.ProductDetail[0]?.Currency ?? ""}{" "}
                            {OrderDetails?.EstimatedTax ?? 0.0}
                          </span>
                        </div>
                        <div className="sub-price">
                          <small>SHIPPING</small>
                          <span className="text-inverse">
                            {OrderDetails.ProductDetail[0].Currency}{" "}
                            {OrderDetails.ShippingHandling}
                          </span>
                        </div>
                        <div className="sub-price">
                          <small>SUBTOTAL</small>
                          <span className="text-inverse">
                            {OrderDetails.ProductDetail[0].Currency}{" "}
                            {getOverAllTotal().toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-price-right">
                      <small>TOTAL</small>{" "}
                      {console.log(
                        OrderDetails.ProductDetail[0],
                        "OrderDetails.ProductDetail[0]"
                      )}
                      <span className="f-w-600">
                        {" "}
                        {OrderDetails.ProductDetail[0].Currency}{" "}
                        {OrderDetails?.OrderTotal}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* <div className="invoice-content">
                            <div className="table-responsive">
                                <table className="table table-invoice">
                                    <thead>
                                        <tr>
                                            <th className="text-center">PRODUCT</th>
                                            <th className="text-center">VARIATIONS</th>
                                            <th className="text-center">PRICE</th>
                                            <th className="text-center">QUANTITY</th>
                                            <th className="text-center">SUBTOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {OrderDetails["ProductDetail"].map((item, index) => (
                                            <tr key={index} className="text-center">
                                                <td>
                                                    <div>{item.Title}</div>
                                                </td>
                                                <td>{getSingleTotal(2, item)}</td>
                                                <td>{`${item.Currency} ${getSingleTotal(0, item)}`}</td>
                                                <td>
                                                    {" "}
                                                    <div className="product-quantity">
                                                        {item.Quantity}
                                                    </div>
                                                </td>
                                                <td>{`${item.Currency} ${getSingleTotal(1, item)}`}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="invoice-price">
                                <div className="invoice-price-left">
                                    <div className="invoice-price-row">
                                        <div className="sub-price">
                                            <small>TAX</small>
                                            <span className="text-inverse">
                                                {OrderDetails.ProductDetail[0].Currency}{" "}
                                                {OrderDetails?.EstimatedTax}
                                            </span>
                                        </div>
                                        <div className="sub-price">
                                            <small>SHIPPING</small>
                                            <span className="text-inverse">
                                                {OrderDetails.ProductDetail[0].Currency}{" "}
                                                {OrderDetails.ShippingHandling}
                                            </span>
                                        </div>
                                        <div className="sub-price">
                                            <small>SUBTOTAL</small>
                                            <span className="text-inverse">
                                                {OrderDetails.ProductDetail[0].Currency}{" "}
                                                {getOverAllTotal().toFixed(2)}
                                            </span>
                                        </div>
                                 
                                    </div>
                                </div>
                                <div className="invoice-price-right">
                                    <small>TOTAL</small>{" "}
                                    {console.log(
                                        OrderDetails.ProductDetail[0],
                                        "OrderDetails.ProductDetail[0]"
                                    )}
                                    <span className="f-w-600">
                                        {" "}
                                        {OrderDetails.ProductDetail[0].Currency}{" "}
                                        {OrderDetails?.OrderTotal}
                                    </span>
                                </div>
                            </div>
                        </div> */}
            {/* {type === "user" && (
              <div className="invoice-note">
                <h5>Your Signature</h5>
                <div
                  className="mt-5 w-25"
                  style={{ borderBottom: "1px solid black" }}
                ></div>
              </div>
            )} */}

            {/* {JSON.stringify(OrderDeliveryDetails)} */}
          </div>
          {type === "user" && (
            <button
              onClick={() => window.print()}
              className="btn btn-success btn-sm"
              style={{ marginRight: "10px" }}
            >
              Download
            </button>
            // <button
            //   onClick={downloadPDF}
            //   className="btn btn-success btn-sm"
            //   style={{ marginRight: "10px" }}
            // >
            //   Download as PDF
            // </button>
          )}

          {type === "user" && (
            <button
              // onClick={() => history.push(`/order-details/${OrderDeliveryDetails.OrderNumber}`)}
              onClick={() =>
                history.push(
                  `/order-details/${OrderDeliveryDetails.OrderNumber}`
                )
              }
              className="btn btn-success btn-sm"
            >
              <FaArrowLeft /> Back
            </button>
          )}
        </div>
      )}
    </div>
  );
}
export default AdminInvoiceForm;
