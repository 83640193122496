function ServiceInfo() {
  return (
    <div className="service-info mt-5 mb-5">
      <section id="textbannersmodule" className="row clearfix ">
        <ul>
          <li className="col-xs-12 col-sm-3">
            <div className="txtbanner txtbanner2 clearfix">
              <div className="circle">
                <i className="fal fa-truck-pickup"></i>
              </div>
              <a href="#" style={{ color: "#777777" }}>
                <span className="txttitle">Free Shipping & Returns</span>
                <span className="txtlegend">For all orders over $99</span>
              </a>
            </div>
          </li>

          <li className="col-xs-12 col-sm-3">
            <div className="txtbanner txtbanner3 clearfix">
              <div className="circle">
                <i className="fas fa-money-check-alt" />
              </div>
              <a href="#" style={{ color: "#777777" }}>
                <span className="txttitle">Secure Payment</span>
                <span className="txtlegend">We ensure secure payment</span>
              </a>
            </div>
          </li>
          <li className="col-xs-12 col-sm-3">
            <div className="txtbanner txtbanner1 clearfix">
              <div className="circle">
                <i className="fas fa-hand-holding-usd" />
              </div>
              <a href="#" style={{ color: "#777777" }}>
                <span className="txttitle">Money Back Guarantee</span>
                <span className="txtlegend">Any back within 30 days</span>
              </a>
            </div>
          </li>
          <li className="col-xs-12 col-sm-3">
            <div className="txtbanner txtbanner3 clearfix">
              <div className="circle">
                <i className="fas fa-headset" />
              </div>
              <a href="#" style={{ color: "#777777" }}>
                <span className="txttitle">Customer Support</span>
                <span className="txtlegend">Call or email us 24/7</span>
              </a>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
}
export default ServiceInfo;
