import { useEffect, useState, useCallback, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

import Endpoint from "../../../../../Utils/Endpoint";

import BanglaBazarApi from "../../../../Api/BanglaBazarApi";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { debounce } from "lodash";

export function QuikLinks() {
  const [modal, setModal] = useState(false);
  const [contentTitle, setContentTitle] = useState("");
  const [editorData, setEditorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const toggle = () => setModal(!modal);

  const scrollRef = useRef(null);

  const openModalWithContent = async (title) => {
    setContentTitle(title);
    setIsLoading(true);
    try {
      const result = await axios.post(`${Endpoint}/api/admin/getStaticTable`, {
        ContentType: title,
      });
      // Check if jsonContent is present and not null
      if (result.data.data.jsonContent) {
        // Set editor data as an array of objects
        setEditorData(result.data.data.jsonContent);
      } else {
        // If jsonContent is null or not an array, handle appropriately
        setEditorData([{ head: "", body: result.data.data.Content || "" }]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setEditorData([]); // Handle error state
    } finally {
      setIsLoading(false);
      toggle();
    }
  };

  const debouncedUpdateContentItem = useCallback(
    debounce((index, field, data) => {
      const updatedContent = [...editorData];
      updatedContent[index][field] = data;
      setEditorData(updatedContent);
    }, 300),
    [editorData]
  );

  const handleEditorChange = (index, field, data) => {
    if (!isDragging) {
      debouncedUpdateContentItem(index, field, data);
    }
  };

  const updateContentItem = (index, field, data) => {
    const updatedContent = [...editorData];
    updatedContent[index][field] = data;
    setEditorData(updatedContent);
  };

  const updateContentOrder = async (updatedContent) => {
    const processedContent = updatedContent.map((item) => ({
      head: item.head.replace(/<\/?[^>]+(>|$)/g, "").trim(),
      body: item.body.replace(/<\/?[^>]+(>|$)/g, "").trim(),
    }));

    // Filter out entries where both head and body are empty
    const contentToSave = processedContent.filter(
      (item) => !(item.head === "" && item.body === "")
    );

    try {
      const result = await BanglaBazarApi.put(
        `${Endpoint}/api/admin/update-quick-links`,
        {
          ContentType: contentTitle,
          content: contentToSave,
        }
      );

      console.log("Updated Content:", updatedContent);
      // Additional logic if needed after successful API call
    } catch (error) {
      console.error("Error updating content order:", error);
      // Handle error state
    }
  };

  const handleSave = async () => {
    await updateContentOrder(editorData);
    toggle(); // This will close the modal
  };
  const addNewQuicksLink = () => {
    setEditorData([...editorData, { head: "", body: "" }]);
    setTimeout(() => scrollToBottom(), 0);
  };

  // Function to handle the deletion of heading or description of a Quick Link
  const handleDeleteQuickLinkContent = (indexToDelete, contentType) => {
    const updatedEditorData = editorData.map((item, index) => {
      if (index === indexToDelete) {
        return {
          ...item,
          [contentType]: "", // Set either 'head' or 'body' to an empty string
        };
      }
      return item;
    });
    setEditorData(updatedEditorData);
  };

  const buttons = [
    { text: "Privacy Policy", content: "Privacy Policy" },
    { text: "Terms & Conditions", content: "Terms & Conditions" },
    { text: "About Us", content: "About Us" },
    { text: "Refund & Return Policy", content: "Refund & Return Policy" },
    // Add other buttons as needed
  ];

  const handleOnDragStart = () => {
    setIsDragging(true);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(editorData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setEditorData(items);
    setIsDragging(false);

    updateContentOrder(items);
  };

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // useEffect(() => {
  //   if (editorData.length > 0) {
  //     const timer = setTimeout(() => {
  //       scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  //     }, 100); // Delay to ensure DOM has updated
  //     return () => clearTimeout(timer);
  //   }
  // }, [editorData.length]);

  return (
    <>
      <div
        className="card mt-5"
        style={{ maxHeight: "450px", overflowY: "auto" }}
      >
        {/* Styles and other JSX elements */}
        <div className="card-header bg-white">
          <h5 className="ftw-400 text-default mt-1 mb-2 ">Quick Links</h5>
        </div>

        <div className="table-responsive">
          <div className="card-body">
            <div
              className="table-responsive"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {buttons.map((button, index) => (
                <div key={index} style={{ width: "auto", margin: "0 auto" }}>
                  <Button
                    className="btn-default mb-2"
                    onClick={() => openModalWithContent(button.content)}
                  >
                    {isLoading && contentTitle === button.content
                      ? "Getting Data...."
                      : button.text}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>{contentTitle}</ModalHeader>

          <Button className="btn-default mb-2" onClick={addNewQuicksLink}>
            Add New Quicks Link
          </Button>

          <ModalBody>
            <DragDropContext
              onDragStart={handleOnDragStart}
              onDragEnd={handleOnDragEnd}
            >
              <Droppable droppableId="editorData" direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {editorData.map((item, index) => (
                      <Draggable
                        key={`item-${index}`}
                        draggableId={`item-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="mb-4"
                          >
                            <h5>Heading</h5>
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.head}
                              disabled={isDragging}
                              onChange={(event, editor) =>
                                handleEditorChange(
                                  index,
                                  "head",
                                  editor.getData()
                                )
                              }
                            />
                            <button
                              style={{
                                float: "right",
                                border: "none",
                                cursor: "pointer",
                                background: "none",
                              }}
                              onClick={() =>
                                handleDeleteQuickLinkContent(index, "head")
                              }
                            >
                              <i className="fas fa-trash text-danger"></i>
                            </button>
                            <h5 className="mt-1">Description</h5>
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.body}
                              disabled={isDragging}
                              onChange={(event, editor) =>
                                handleEditorChange(
                                  index,
                                  "body",
                                  editor.getData()
                                )
                              }
                            />

                            <button
                              style={{
                                float: "right",
                                border: "none",
                                cursor: "pointer",
                                background: "none",
                              }}
                              onClick={() =>
                                handleDeleteQuickLinkContent(index, "body")
                              }
                            >
                              <i className="fas fa-trash text-danger"></i>
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <div ref={scrollRef} /> {/* Element to scroll into view */}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}
