import {
  Col,
  Container,
  Row,
  List,
  ListInlineItem,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import logo from "../../../../assets/images/logo.png";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CheckEmpty from "./../../../../Utils/CheckEmpty";
import firetoast from "../../../../Helpers/FireToast";
import { CurrentUser } from "../../../../Helpers/Auth";
import MyCart from "./MyCart";

function LandingNavbar() {
  const history = useHistory();
  const [Region, setRegion] = useState(
    localStorage.getItem("region") ? localStorage.getItem("region") : null
  );
  const [SelectedCategory, setSelectedCategory] = useState({
    Category: "All",
    CategoryID: "All",
  });
  const [SearchInput, setSearchInput] = useState("");
  const state = useSelector((state) => state);
  const { categoriesAndSubcategories } = state;

  const [CategoryAndSubCategory, setCategoryAndSubCategory] = useState([]);
  useEffect(() => {
    if (categoriesAndSubcategories.error) {
      firetoast(categoriesAndSubcategories.error, "default-error");
      setCategoryAndSubCategory([]);
    } else {
      setCategoryAndSubCategory(
        categoriesAndSubcategories.categoriesAndSubCategories
      );
    }
  }, [categoriesAndSubcategories]);
  return (
    <div className="l-nav container pt-2">
      <Row>
        <Col
          xl={4}
          lg={4}
          md={12}
          style={{
            backgroundColor: "transparent !important",
          }}
          className="d-none d-sm-none d-xs-none d-lg-flex d-xl-flex"
        >
          <img
            src={logo}
            className="img-fluid logo"
            onClick={() => history.push("/")}
          />
        </Col>

        <div
          className="col-12 d-xs-block  
          d-sm-block d-lg-none d-md-none mt-2"
          style={{
            border: "1px solid #b1b1b1 ",
            borderRadius: "5px",
          }}
        >
          <UncontrolledDropdown>
            <DropdownToggle
              className=" text-secondary btn-block btn-ln-search-cat"
            // style={{ width: "100%" }}
            >
              {SelectedCategory.Category === "All"
                ? "Categories"
                : SelectedCategory.Category}
              <i
                className="fas fa-chevron-down price-chevron"
                style={{ marginLeft: "10px" }}
              ></i>
            </DropdownToggle>
            <DropdownMenu style={{ width: "auto" }}>
              <DropdownItem
                onClick={() =>
                  setSelectedCategory({
                    Category: "All",
                    CategoryID: "All",
                  })
                }
              >
                All
              </DropdownItem>

              {/* //!departtments */}
              {/* {CategoryAndSubCategory.map((Category, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => {
                    setSelectedCategory(Category.SubCategories)
                    console.log(Category.SubCategories);
                  }}
                >
                  {Category.Category}
                </DropdownItem>
              ))} */}


              {/* {CategoryAndSubCategory.map((Category, index) => ( */}
              <>
                {CategoryAndSubCategory.map((cat, subIndex) => (
                  <DropdownItem
                    key={subIndex}
                    onClick={() => {
                      let obj = {
                        Active: cat.Active,
                        Category: cat.Category,
                        CategoryID: cat.CategoryID,
                        CategoryPic: cat.CategoryPic,
                        DepartmentID: cat.DepartmentID,
                        Description: cat.Description,
                        LastUpdate: cat.LastUpdate,
                        ShippingGlobal: cat.ShippingGlobal,
                      }
                      setSelectedCategory(obj)
                      console.log("===========>", obj);
                      // setSelectedCategory(cat.Category_Details)
                      // console.log("===========>", Category);
                    }}
                  >
                    {cat.Category}
                  </DropdownItem>
                ))}
              </>
              {/*  ))} */}




            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <Col
          xl={8}
          lg={7}
          md={8}
          style={{
            paddingLeft: "-9px !important",
            marginLeft: "10px !important",
            marginTop: "11px !importnat",
            width: "80% !important",
            marginBottom: "10px !importnat",
          }}
        >
          <div className="row search-nav">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{ paddingRight: "0px" }}>
              <InputGroup>
                <UncontrolledDropdown className="d-none d-lg-block d-md-block search-addon">
                  <DropdownToggle className="bg-transparent text-secondary btn-block btn-ln-search-cat" style={{ width: "100%" }}>
                    {SelectedCategory === "All" ? "Categories" : SelectedCategory.Category}
                    <i className="fas fa-chevron-down price-chevron" style={{ marginLeft: "10px" }}></i>
                  </DropdownToggle>
                  <DropdownMenu style={{ width: "auto" }}>
                    <DropdownItem onClick={() => setSelectedCategory({ Category: "All", CategoryID: "All" })}>
                      All
                    </DropdownItem>
                    {/* //!departtments */}
                    {CategoryAndSubCategory.map((cat, index) => (
                      <DropdownItem key={index} onClick={() => {

                        let obj = {
                          Active: cat.Active,
                          Category: cat.Category,
                          CategoryID: cat.CategoryID,
                          CategoryPic: cat.CategoryPic,
                          DepartmentID: cat.DepartmentID,
                          Description: cat.Description,
                          LastUpdate: cat.LastUpdate,
                          ShippingGlobal: cat.ShippingGlobal,
                        }
                        setSelectedCategory(obj)
                      }}>
                        {cat.Category}
                      </DropdownItem>
                    ))}

                    {/* {CategoryAndSubCategory.map((Category, index) => (
                      <>
                        {Category.Categories.map((cat, subIndex) => (
                          <DropdownItem
                            key={subIndex}
                            onClick={() => {
                              let obj = {
                                Active: cat.Category_Details['Active'],
                                Category: cat.Category_Details['Category'],
                                CategoryID: cat.Category_Details['CategoryID'],
                                CategoryPic: cat.Category_Details['CategoryPic'],
                                DepartmentID: cat.Category_Details['DepartmentID'],
                                Description: cat.Category_Details['Description'],
                                LastUpdate: cat.Category_Details['LastUpdate'],
                                ShippingGlobal: cat.Category_Details['ShippingGlobal'],
                              }
                              setSelectedCategory(obj)
                              console.log("===========>", obj);

                            }}
                          >
                            {cat.Category}
                          </DropdownItem>
                        ))}
                      </>
                    ))} */}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <span className="lt-divider d-none d-lg-block d-md-block" style={{ marginTop: "5px", borderLeft: "3px solid" }}></span>
                <Input
                  className="nav-search bg-transparent"
                  placeholder="Search ..."
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  className="bt-default-nav-search search-addon"
                  onClick={() => {
                    if (CheckEmpty(SearchInput)) {
                      history.push(`/search/global/products/${SelectedCategory.CategoryID}?keyword=`);
                    } else {
                      history.push(`/search/global/products/${SelectedCategory.CategoryID}?keyword=${SearchInput}`);
                    }
                  }}
                >
                  <i className="fas fa-search"></i>
                </button>
              </InputGroup>
            </div>
          </div>

        </Col>
        {/* <Col xl={2} lg={3} md={4}>
          <List type="inline" className="lt-un">
            <ListInlineItem
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (CurrentUser) {
                  history.push("/my-wishlist");
                } else {
                  firetoast(
                    "Login first to view your wishlist",
                    "info",
                    5000,
                    "top-center"
                  );
                }
              }}
            >
              <div className="ln-text text-center float-right">
                <div>
                  {" "}
                  <i className="far fa-heart text-secondary"></i>
                </div>
                <div style={{ fontSize: "12px" }}>Wishlist</div>
              </div>
            </ListInlineItem>
            <ListInlineItem></ListInlineItem>
          </List>
        </Col> */}
      </Row>
    </div>
  );
}
export default LandingNavbar;
