import React from 'react'
import { useState } from 'react'
import { RequiredField } from '../../../../../Utils/Required-field';
import { Link } from "react-router-dom";

const OptionValueField = ({ option, setModalButton, handleDropdownChange, setSelectedOptions, selectedOptions }) => {
    console.log(option, "option")
    var [isAddCustom, setIsAddCustom] = useState(false);


    const [inputValues, setInputValues] = useState({});


    const handleInputChange = (e, optionID) => {
        
        console.log("-------------------------------------", e.target.value, optionID);

        let d = document.getElementById(`valueName_${option.OptionID}`).value

        // Update the state with the input value
        // setInputValues(prevValues => ({
        //     ...prevValues,
        //     [optionID]: e.target.value
        // }));

        setSelectedOptions(prevData => ({
            ...prevData,
            [optionID]: {
                ...prevData[optionID], // Keep the other properties unchanged
                text: e.target.value // Update the text property
            }
        }));

        // Example: If you want to disable a modal button
        // setModalButton(false);
         console.log(selectedOptions, "inputVal-----------------------");

    }


    return (


        <div className="col-6">

            {/* //actual value is drop down */}
            {/* <label>{option.OptionName}  <RequiredField /></label> */}
            {isAddCustom ? (
                // <input
                //     className="form-control"
                //     type="text"
                //     name="VariantValue"
                //     id="valueName"
                //     onChange={(e) => {
                //         console.log("-------------------------------------", e.target.value, option.OptionID);
                //         setModalButton(false)
                //     }}
                // />

                <input
                    className="form-control"
                    type="text"
                    name={`VariantValue_${option.OptionID}`} // Unique name for each input
                    id={`valueName_${option.OptionID}`}      // Unique ID for each input
                    // value={inputValues[option.OptionID] || ''}  // Control the input value
                    onChange={(e) => handleInputChange(e, option.OptionID)}
                />
            ) : (
                <select

                    className="form-control"
                    type="text"
                    name="VariantValue"
                    // !value={option.OptionID}
                    // value={736}
                    id="valueName"
                    onChange={(e) => {
                        handleDropdownChange(option.OptionID, e);
                        setModalButton(false)
                    }}
                >
                    <option value={""}>Select Available Option</option>
                    {option.Values.map((item, index) => (
                        <option value={item.OptionValueID}>
                            {/* {item.OptionValueID} */}
                            {item.OptionValue}
                        </option>
                    ))}
                </select>
            )}

            {isAddCustom ? (
                <Link
                    to="#"
                    className="td-none text-danger"
                    onClick={(e) => {
                        setIsAddCustom(false);
                        // document.getElementById("valueName").value = "";
                        setSelectedOptions(prevState => ({
                            ...prevState,
                            [option.OptionID]: e.target.value
                        }));

                        // console.log(e.target.value + d, "e.target.valuee.target.valuee.target.value")
                    }}
                >
                    Cancel
                </Link>
            ) : (
                <Link
                    to="#"
                    className="td-none text-default"
                    onClick={(e) => {
                        setIsAddCustom(true);
                        // document.getElementById("valueName").value = "";
                        setSelectedOptions(prevState => ({
                            ...prevState,
                            [option.OptionID]: e.target.value
                        }));
                        setModalButton(false);
                    }}
                >
                    {/* //!custom value */}
                    Add Custom



                </Link>
            )}

        </div>


    )
}

export default OptionValueField