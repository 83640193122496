import { WebsiteHeader } from "./Layout/Header/Header";
import { Breadcrumb } from "antd";
import { Footer } from "./Layout/Footer";
import { useEffect, useState } from "react";
import Endpoint from "../../../Utils/Endpoint";
import axios from "axios";
import { HiChevronRight } from "react-icons/hi2";
function RefundAndReturnPolicy() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    getContect();
  }, []);

  const [content, setContent] = useState("");

  const getContect = async () => {
    try {
      const result = await axios.post(`${Endpoint}/api/admin/getStaticTable`, {
        ContentType: "Refund & Return Policy",
      });

      setContent(result.data.data);

      console.log("==============>", content);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getContect();
  }, []);

  return (
    <>
      <WebsiteHeader />

      <div className="container">
        <>
          <div className="breadcrumb-wrapper d-flex align-items-center gap-1 mt-2 pb-0">
            <Breadcrumb
              separator={<HiChevronRight />}
              items={[
                {
                  title: "Home",
                  href: "/",
                },
                {
                  title: "Refund and return",
                },
              ]}
            />
          </div>
        </>
        <div className="mt-4">
          <div className="text-center">
            <h2 className="text-default">{content && content.ContentType}</h2>
          </div>
          <div className="container mt-3">
            <div className="container mt-3">
              {content &&
                content.jsonContent &&
                content.jsonContent.map((item) => {
                  return (
                    <div>
                      <h5>{item.head}</h5>
                      <p style={{ fontSize: "13px", fontWeight: 400 }}>
                        {item.body}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {/* <NewsLetter /> */}
      <Footer />
    </>
  );
}
export default RefundAndReturnPolicy;
