import { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import logo from "../../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Endpoint from "./../../../../Utils/Endpoint";
import CheckEmpty from "./../../../../Utils/CheckEmpty";
import BanglaBazarApi from "../../../Api/BanglaBazarApi";
function Signin(props) {
  var history = useHistory();
  const toggle = () => props.setSignIn(!props.signin);
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [Ip, setIp] = useState("");

  const format =
    /(^(?!.*__.*)[a-z0-9]{2,253}(_?)[a-z0-9]+(?:\.[a-z0-9!#$%&*+\/=?^`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9]*[a-z0-9])?$)/gs;

  useEffect(() => {
    props.setSignIn(props.signin);
    setIsLoading(false);
    getIp();
  }, [props.signin]);
  var getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    // console.log(res.data);
    setIp(res.data.IPv4);
  };
  const Login = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (CheckEmpty(email)) {
      setIsLoading(false);
      return toast.error("Please provide your email!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!email.match(format)) {
      setIsLoading(false);
      return toast.error(`Invalid Email Format`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (CheckEmpty(password)) {
      setIsLoading(false);
      return toast.error("Please set a password!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      var data = {
        EmailAddress: email,
        Password: password,
        deviceID: null,
      };
      try {
        var form = new URLSearchParams();
        for (var key in data) {
          form.append(key, data[key]);
        }
        var response = await BanglaBazarApi.post(
          `${Endpoint}/api/user/login`,
          form
        );
        if (response.data.status) {
          toast.success("Login Successfully...", {
            position: "top-right",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.setItem("user", JSON.stringify(response.data.user));
          if (CheckEmpty(response.data.user.IPAddress)) {
            var form = new URLSearchParams();
            form.append("IPAddress", Ip);
            form.append("UserID", response.data.user.UserID);
            await BanglaBazarApi.put(Endpoint + "/api/admin/update-ip", form);
          }
          setIsLoading(false);
          localStorage.setItem("accessToken", response.data.token);
          setTimeout(() => {
            props.setSignIn(false);
            window.location.href = "/";
          }, 500);
        } else {
          setIsLoading(false);
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (e) {
        setIsLoading(false);
        if (e.response) {
          return toast.error(e.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };
  return (
    <div>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Modal
        isOpen={props.signin}
        toggle={props.signin}
        size="lg"
        backdrop="static"
        zIndex="99999"
      >
        <ModalHeader toggle={toggle} className="landing-signin"></ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-12 m-auto">
              <div>
                <div className="text-center">
                  <img src={logo} className="logo" />
                  <p className="default-p mt-3">
                    Sign in to your bangla bazar account
                  </p>
                </div>
                <Row>
                  <Col xl={10} lg={10} md={12} className="m-auto">
                    <form onSubmit={Login} id="login_form" className="box">
                      {/* <h3 className="page-subheading">Already registered?</h3> */}
                      <div className="form_content clearfix">
                        <div className="form-group">
                          <label htmlFor="email">Email address</label>
                          <input
                            className="is_required validate account_input form-control"
                            data-validate="isEmail"
                            type="email"
                            id="email"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="passwd">Password</label>
                          <input
                            onChange={(e) => setPassword(e.target.value)}
                            className="is_required validate account_input form-control"
                            type="password"
                            data-validate="isPasswd"
                            id="passwd"
                            name="passwd"
                            defaultValue=""
                          />
                        </div>
                        <div className="d-flex justify-content-between mt-2 ">
                          <FormGroup check>
                            <Label
                              check
                              className="forget-content"
                              style={{ marginLeft: 3 }}
                            >
                              <Input type="checkbox" /> Remember me
                            </Label>
                          </FormGroup>
                          <div
                            className="forget-content text-default"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              window.location.href = "/forgot-password";
                            }}
                          >
                            Forgot password?
                          </div>
                        </div>
                        <p className="submit">
                          <button
                            type="submit"
                            id="SubmitLogin"
                            name="SubmitLogin"
                            className="button btn btn-default btn-bg-color"
                          >
                            <span>
                              {isLoading ? (
                                <Spinner color="light" size="sm" />
                              ) : (
                                <>
                                  <i className="fa fa-lock me-2" /> Sign in
                                </>
                              )}
                            </span>
                          </button>
                        </p>
                      </div>
                    </form>
                    <div style={{ fontSize: "13px" }} className="mb-3">
                      Don't have an account?{" "}
                      <Link
                        className="text-default"
                        to="#"
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                          props.setSignIn(!props.signin);
                          props.setSignUp(!props.signup);
                        }}
                      >
                        Sign up
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* <ForgotPassword isOpen={fpass} setISOpen={setfpass} /> */}
    </div>
  );
}
export default Signin;
