import { WebsiteHeader } from "./Layout/Header/Header";
import { Breadcrumb } from "antd";
import { Footer } from "./Layout/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import Endpoint from "../../../Utils/Endpoint";
import { HiChevronRight } from "react-icons/hi2";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    getContect();
  }, []);

  const [content, setContent] = useState("");

  const getContect = async () => {
    try {
      const result = await axios.post(`${Endpoint}/api/admin/getStaticTable`, {
        ContentType: "Privacy Policy",
      });

      setContent(result.data.data);

      console.log("=================>", content, typeof content.jsonContent);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getContect();
  }, []);

  return (
    <>
      <WebsiteHeader />

      <div className="container">
        <>
          <div className="breadcrumb-wrapper d-flex align-items-center gap-1 mt-2 pb-0">
            <Breadcrumb
              separator={<HiChevronRight />}
              items={[
                {
                  title: "Home",
                  href: "/",
                },
                {
                  title: "Privacy policy",
                },
              ]}
            />
          </div>
        </>
        <div className="mt-4">
          <div className="text-center">
            <h2 className="text-default">{content && content.ContentType}</h2>
          </div>
          <div className="container mt-3">
            {/* {JSON.stringify(content.jsonContent)} */}

            {content &&
              content.jsonContent &&
              content.jsonContent.map((item) => {
                return (
                  <div>
                    <h5>{item.head}</h5>
                    <p style={{ fontSize: "13px", fontWeight: 400 }}>
                      {item.body}
                    </p>
                    <br />
                  </div>
                );
              })}

            {/* 
            <div>
              <h5>Your Security</h5>
              <p style={{ fontSize: "15px" }}>
                Our Web site encrypts your credit card number prior to
                transmission over the Internet using secure socket layer (SSL)
                encryption technology. This technology works best when the site
                is viewed using Google Chrome. However, no transmission of data
                over the Internet or any other public network can be guaranteed
                to be 100% secure. Although we make reasonable efforts to
                safeguard your personal information once we receive it.
              </p>
            </div>
            <div>
              <h5>IP Address</h5>
              <p style={{ fontSize: "15px" }}>
                BanglaBazar stores the IP (Internet protocol) addresses of its
                all users. This information, which is reported by your browser,
                is stored in our database and can be used for various reasons.
                We can use your IP address to improve technology problems
                reported by our users, compile a statistical database of user
                types, keep track of the type of requests is coming from an IP
                address and etc.
              </p>
            </div>
            <div>
              <h5>Personal Information </h5>
              <p style={{ fontSize: "15px" }}>
                BanglaBazar will not sell or rent your personal information to
                anyone. We, or our business partners, may send you e-mails time
                to time with service update information or to extend special
                offers from other partners your way.
              </p>
            </div> */}
          </div>
        </div>
      </div>
      {/* <NewsLetter /> */}
      <Footer />
    </>
  );
}
export default PrivacyPolicy;
