import { Spinner } from "reactstrap";

function Loading(props) {
  return (
    <div className="text-center" style={{ marginTop: "25px", height: "100px" }}>
      <Spinner
        className="text-default"
        style={{
          height: "5rem",
          width: "5rem",
        }}
      />{" "}
    </div>
  );
}
export default Loading;
