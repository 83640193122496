// Actions.js
import {
  GET_PHONE_NUMBER_STATUS_PENDING,
  GET_PHONE_NUMBER_STATUS_SUCCESS,
  GET_PHONE_NUMBER_STATUS_FAILED,
} from "../Constants/Constants.js";

// Loading State
export const getPhoneNumberStatusPending = () => ({
  type: GET_PHONE_NUMBER_STATUS_PENDING,
});

// Success State
export const getPhoneNumberStatusSuccess = (data) => ({
  type: GET_PHONE_NUMBER_STATUS_SUCCESS,
  payload: true,
});

// Error State
export const getPhoneNumberStatusFailed = (error) => ({
  type: GET_PHONE_NUMBER_STATUS_FAILED,
  payload: error,
});
