import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Endpoint from "./Endpoint";
import AddProductClick from "./../Helpers/AddProductClick";
import RatingStars from "./../Helpers/RatingStars";

function ProductImage({ item, type }) {
  const history = useHistory();
  const state = useSelector((state) => state);
  const { currentIPv4 } = state;

  return (
    <div className="card deal-carousel-item">
      <div className="deal-image">
        <img
          className="card-img-top m-auto"
          src={`${Endpoint}/${item.Medium}`}
          style={{ height: "175px", objectFit: "cover" }}
          alt="Product img"
          onClick={() => {
            AddProductClick(item, currentIPv4);
            history.push(`/product-details/${item.ProductID}`);
          }}
        />
        {/* <div className="image-text">
          <div className="mt-2">
            <button className="rounded-circle image-text-button">
              {" "}
              <i className="fas fa-shopping-cart"></i>
            </button>
          </div>
          <div className="mt-2">
            <button
              className="rounded-circle image-text-button"
              onClick={() => addToWishList()}
            >
              {" "}
              <i className="far fa-heart"></i>
            </button>
          </div>
          <div className="mt-2">
            <button className="rounded-circle image-text-button">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div> */}
      </div>
      <div className="card-body deal-carousel-item-body">
        <h5 className="card-title ftw-400">
          {" "}
          <Link
            to="#"
            onClick={() => {
              AddProductClick(item, currentIPv4);
              history.push(`/product-details/${item.ProductID}`);
            }}
            className="text-default td-none"
            style={{ fontSize: "14px" }}
          >
            {item.Title}
          </Link>
        </h5>
        {type !== "recent" && (
          <>
            <p className="card-text mb-0">
              <span className="dotd-rate">
                {RatingStars(
                  item.AVG_RATING
                    ? parseInt(item.AVG_RATING)
                    : item.AVG_Rating
                    ? parseInt(item.AVG_Rating)
                    : 0
                )}
              </span>
              <div>
                {" "}
                <span>
                  ( {item.REVIEW_COUNT ? item.REVIEW_COUNT : 0} Reviews)
                </span>
              </div>
            </p>
            <div>
              <span className="dotd-rate">
                <span className="text-dark">
                  {item.Currency}{" "}
                  <span style={{ marginLeft: "5px" }}>
                    {parseFloat(item.Price).toFixed(2)}
                  </span>
                </span>{" "}
              </span>
            </div>
            <div>
              {/* <button
                className="btn btn-sm btn-secondary mt-2 add-to-wishlist-btn"
                style={{}}
                onClick={() => {
                  if (CurrentUser) {
                    addToWishList();
                  } else {
                    firetoast(
                      "Login first to add this item in your wish list",
                      "info",
                      5000,
                      "top-center"
                    );
                  }
                }}
              >
                Add to wishlist
              </button> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default ProductImage;
