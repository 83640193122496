import React from 'react'

const ProductInstrunctions = ({ SpecialInstruction }) => {




    return (
        <div dangerouslySetInnerHTML={{ __html: SpecialInstruction }} />
    )
}

export default ProductInstrunctions