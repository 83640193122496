import { Footer } from "./Layout/Footer";
import { WebsiteHeader } from "./Layout/Header/Header";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import firetoast from "../../../Helpers/FireToast";
import Loading from "../../../Utils/Loading";
import Clean from "../../../assets/images/clean.svg";
import RatingStars from "../../../Helpers/RatingStars";
import RemoveItemFromWishList from "./../../Api/RemoveItemFromWishList";
import Endpoint from "../../../Utils/Endpoint";
import { CurrentUser } from "../../../Helpers/Auth";
import BanglaBazarApi from "../../Api/BanglaBazarApi";
import { Breadcrumb } from "antd";
import { HiChevronRight } from "react-icons/hi2";
function Wishlist() {
  const [Products, setProducts] = useState([]);
  const [Loader, setLoader] = useState("true");
  const [removeProduct, setRemoveProduct] = useState(false);
  const history = useHistory();

  async function getData() {
    let response = await BanglaBazarApi.get(
      `${Endpoint}/api/wish-list/viewUserWishList`
    );

    if (response.status) {
      if (response.data.status) {
        setProducts(response.data.userWishList);
        setLoader("false");
      }
    } else {
      firetoast("Something went wrong", "default-error");
    }
  }
  useEffect(() => {
    if (!CurrentUser) {
      history.push("/");
    }
    getData();
  }, [removeProduct]);

  var removeFromWishlist = async (id, index) => {
    if (await RemoveItemFromWishList(id)) {
      setRemoveProduct(true);
    } else {
      firetoast("Something went wrong", "default-error");
    }
  };
  return (
    <>
      <WebsiteHeader />

      <div className="container">
        <>
          <div className="breadcrumb-wrapper d-flex align-items-center gap-1 mt-2 pb-0">
            <Breadcrumb
              separator={<HiChevronRight />}
              items={[
                {
                  title: "Home",
                  href: "/",
                },
                {
                  title: "Wishlist",
                },
              ]}
            />
          </div>
        </>
        <div className="mt-4">
          <div className="row content-inner">
            <div
              id="center_column"
              className="center_column col-xs-12 col-sm-12 col-sm-push-0"
            >
              <h1 id="cart_title">My wishlist</h1>

              <div
                id="order-detail-content"
                className="table_block table-responsive"
              >
                <table
                  id=""
                  className="table table-bordered stock-management-on table-responsive"
                >
                  <thead>
                    <tr>
                      <th className="cart_product first_item text-center">
                        Product
                      </th>
                      <th className="cart_description item">Description</th>
                      <th className="cart_avail item text-center">
                        Availability
                      </th>
                      <th className="cart_unit item text-center">Unit price</th>
                      <th className="cart_delete last_item">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Products.length < 1 ? (
                      <tr>
                        <td colSpan={5} className="text-center">
                          Wishlist is empty!
                        </td>
                      </tr>
                    ) : (
                      Products.map((product, index) => (
                        <tr
                          id="product_3_13_0_681"
                          className="cart_item last_item first_item address_681 odd"
                        >
                          <td className="cart_product_img">
                            <Link
                              to={`/product-details/${product.ProductID}`}
                              // onClick={() =>
                              //   history.push(`/product-details/${product.ProductID}`)
                              // }
                            >
                              <div className="custom-rounded-image">
                                <img
                                  alt=""
                                  src={`${Endpoint}/${product.Small}`}
                                  className="img-fluid"
                                  style={{}}
                                />
                              </div>
                            </Link>
                          </td>
                          <td className="cart_description">
                            <div className="mt-2">
                              <b style={{ fontSize: 18 }}>{product.Title}</b>
                              {/* <div>
                                <b>Vendor</b> : {product.CompanyName}
                              </div> */}
                              <div>
                                <span className="d-inline-flex align-items-center gap-1">
                                  {RatingStars(
                                    product.AVG_Rating
                                      ? parseInt(product.AVG_Rating)
                                      : 0
                                  )}
                                  ({product.REVIEW_COUNT})
                                </span>
                              </div>
                              {product.AllowStorePickup === "Y" && (
                                <div>
                                  <small>
                                    <span className="text-orange">
                                      Store pick up available for {product.City}
                                    </span>
                                  </small>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="cart_avail text-center">
                            <span className="label label-success">
                              In stock
                            </span>
                          </td>
                          <td
                            className="cart_unit text-center"
                            data-title="Unit price"
                          >
                            {product.Price}
                          </td>

                          <td
                            className="cart_delete text-center"
                            data-title="Delete"
                          >
                            <div>
                              <Link
                                to="#"
                                onClick={() =>
                                  removeFromWishlist(product.ProductID, index)
                                }
                                rel="nofollow"
                                title="Delete"
                                className="cart_quantity_delete"
                                id="3_13_0_681"
                              >
                                <i
                                  className="icon-trash"
                                  style={{ color: "red" }}
                                />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Wishlist;
