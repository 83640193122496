import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import MODAL_CONTEXT from "Components/Contexts/ModalContext";
import { CurrentUser } from "Helpers/Auth";
import { Link, useLocation } from "react-router-dom";
import firetoast from "Helpers/FireToast";
import BanglaBazarApi from "Components/Api/BanglaBazarApi";
import Endpoint from "Utils/Endpoint";
import Signup from "Components/Pages/Web App/Modals/Signup";
import Signin from "Components/Pages/Web App/Modals/Signin";
import OtpVerify from "Components/Pages/Web App/Modals/OtpVerify";
import EmailVerify from "Components/Pages/Web App/Modals/EmailVerify";
import { useDispatch } from "react-redux";
import Menu, { SubMenu, MenuItem } from "rc-menu";
import "rc-menu/assets/index.css";

function NavBar({ isMobileNavShow = false, callback = null }) {
  const [
    DepartmentCategoryAndSubCategory,
    setDepartmentCategoryAndSubCategory,
  ] = useState([]);
  var { signin, setSignin, signup, setSignup } = useContext(MODAL_CONTEXT);
  var reload = () => {
    window.location.reload();
  };

  const [otpmodal, setOtpModal] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (newOpenKeys) => {
    setOpenKeys(newOpenKeys);
  };

  var getVendorBusiness = async () => {
    try {
      var response = await BanglaBazarApi.get(
        Endpoint +
          `/api/store-management/buisness-details/${CurrentUser.UserID}`
      );
      console.log(response.data.business && CurrentUser.PhoneVerified === "Y");

      // return
      if (response.data.business && CurrentUser.PhoneVerified === "Y") {
        history.push(`/panel/viewBusiness/${CurrentUser.UserID}`);
        return;
      }

      if (response.data.business && CurrentUser.PhoneVerified === "N") {
        firetoast(
          "You have to verify your phone number from your profile",
          "info",
          5000,
          "top-center"
        );
        setTimeout(() => {
          history.push("/sell");
        }, 3000);
        return;
      }

      if (!response.data.business && CurrentUser.PhoneVerified === "Y") {
        history.push("/sell");
      }

      if (!response.data.business && CurrentUser.PhoneVerified === "N") {
        firetoast(
          "You have to verify your phone number from your profile",
          "info",
          5000,
          "top-center"
        );
        setTimeout(() => {
          history.push(`/user-profile/${CurrentUser.UserID}`);
        }, 3000);
        return;
      }
    } catch (e) {
      console.log(e);
      // firetoast("Something went wrong!", "error", 4000, "top-right");
    }
  };
  const history = useHistory();

  useEffect(() => {
    let finalValue = "BDT";
    const currencyValue = localStorage.getItem("currency");
    if (currencyValue !== "BDT") {
      finalValue = "USD";
    }

    const fetchCategoryByDepartment = async () => {
      try {
        var response = await BanglaBazarApi.post(
          `${Endpoint}/api/category/get-all-productExistCategoryNew`,
          {
            currency: finalValue,
          }
        );
        setDepartmentCategoryAndSubCategory(
          response.data.categoriesAndSubCategories
        );
      } catch (error) {}
    };
    fetchCategoryByDepartment();
  }, []);

  const [isCategoryHovered, setCategoryIsHovered] = useState(false);

  return (
    <>
      <div
        class="iqitmegamenu-wrapper cbp-hor-width-1 clearfix"
        style={{ marginTop: "-20px" }}
      >
        <div
          id="iqitmegamenu-horizontal"
          class="iqitmegamenu cbp-sticky-transparent"
          role="navigation"
        >
          <div class="container">
            <nav
              id="cbp-hrmenu"
              class="cbp-hrmenu cbp-horizontal cbp-hrsub-narrow cbp-fade-slide-bottom cbp-arrowed "
            >
              <ul>
                <li class="cbp-hrmenu-tab cbp-hrmenu-tab-1 cbp-onlyicon">
                  <Link to="/">
                    <span class="cbp-tab-title">
                      <i class="fa fa-home"></i>
                    </span>
                  </Link>
                </li>
                <li
                  className={`cbp-hrmenu-tab cbp-hrmenu-tab-2 ${
                    isCategoryHovered ? "cbp-hropen" : ""
                  }`}
                  onMouseEnter={() => setCategoryIsHovered(true)}
                  onMouseLeave={() => setCategoryIsHovered(false)}
                >
                  <a href="#" role="button" class="cbp-empty-mlink">
                    <span class="cbp-tab-title">
                      Browse Categories
                      <i class="fas fa-chevron-down"></i>
                    </span>
                    <span class="cbp-triangle-container">
                      <span class="cbp-triangle-top"></span>
                      <span class="cbp-triangle-top-back"></span>
                    </span>
                  </a>
                  <div
                    className={`cbp-hrsub col-xs-6 ${
                      isCategoryHovered ? "cbp-show" : ""
                    }`}
                  >
                    <div class="cbp-hrsub-inner">
                      <div class="row menu_row menu-element first_rows menu-element-id-1">
                        <div class="col-xs-12 cbp-menu-column cbp-menu-element menu-element-id-2 ">
                          <div class="cbp-menu-column-inner">
                            <div class="row cbp-categories-row">
                              {DepartmentCategoryAndSubCategory &&
                                DepartmentCategoryAndSubCategory.map(
                                  (Department, index) => (
                                    <>
                                      <div class="col-xs-6" key={index}>
                                        <Link
                                          to=""
                                          class="cbp-column-title cbp-category-title"
                                        >
                                          {Department.Department}
                                        </Link>
                                        <ul class="cbp-links cbp-category-tree">
                                          {Department.Categories.map(
                                            (Category, key) =>
                                              Category.SubCategories.length <
                                              1 ? (
                                                <li key={key}>
                                                  <div class="cbp-category-link-w">
                                                    <a href="5-tshirts.html">
                                                      {Category.Category}
                                                    </a>
                                                  </div>
                                                </li>
                                              ) : (
                                                <li
                                                  className="cbp-hrsub-haslevel2"
                                                  key={key}
                                                >
                                                  <div className="cbp-category-link-w">
                                                    <Link
                                                      to={`/search-products/category/${Category.CategoryID}`}
                                                    >
                                                      {Category.Category}
                                                    </Link>
                                                    <ul className="cbp-hrsub-level2">
                                                      {Category.SubCategories.map(
                                                        (SubCategory) => (
                                                          <li
                                                            key={
                                                              SubCategory.SubCategoryID
                                                            }
                                                          >
                                                            <div className="cbp-category-link-w test">
                                                              <Link
                                                                to={`/search-products/subcategory/${SubCategory.SubCategoryID}`}
                                                              >
                                                                {
                                                                  SubCategory.SubCategory
                                                                }
                                                              </Link>
                                                            </div>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  </div>
                                                </li>
                                              )
                                          )}
                                        </ul>
                                      </div>
                                    </>
                                  )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="cbp-hrmenu-tab cbp-hrmenu-tab-5 ">
                  {CurrentUser ? (
                    <Link to="/order-details">
                      <span class="cbp-tab-title">My Orders</span>
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      onClick={() => {
                        firetoast(
                          "Login first to view your orders",
                          "info",
                          5000,
                          "top-center"
                        );
                      }}
                    >
                      <span class="cbp-tab-title">My Orders</span>
                    </Link>
                  )}
                </li>

                <li class="cbp-hrmenu-tab cbp-hrmenu-tab-10 ">
                  <Link
                    to="#"
                    onClick={() => {
                      if (CurrentUser) {
                        getVendorBusiness();
                      } else {
                        setSignin(!signin);
                      }
                    }}
                  >
                    <span class="cbp-tab-title">Become a Vendor</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div
            id="mobile-menu"
            className={`${isMobileNavShow ? "nav-show" : "nav-hide"}`}
          >
            <div className="nav-closer p-3 fw-bold text-end bg-secondary bg-opacity-10">
              <span
                role="button"
                className="d-inline-flex align-items-center"
                onClick={() => callback(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  fill="currentColor"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>{" "}
                Hide
              </span>
            </div>
            <div id="rc-menu-wrapper">
              <Menu
                mode="inline"
                style={{ height: "100%", borderRight: 0 }}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                onClick={(clickedItemInfo) => {
                  console.log(clickedItemInfo, "clickedItemInfo");
                }}
              >
                {/* {CurrentUser ? (
                  <MenuItem key="key-order">
                    <Link to="/order-details">My Orders</Link>
                  </MenuItem>
                ) : (
                  <MenuItem key="key-order">
                    <Link
                      to="#"
                      onClick={() => {
                        firetoast(
                          "Login first to view your orders",
                          "info",
                          5000,
                          "top-center"
                        );
                      }}
                    >
                      My Orders
                    </Link>
                  </MenuItem>
                )} */}

                {DepartmentCategoryAndSubCategory &&
                  DepartmentCategoryAndSubCategory.map((Department) => (
                    <SubMenu
                      key={Department.DepartmentID}
                      title={Department.Department}
                    >
                      {Department.Categories.map((Category) =>
                        Category.SubCategories.length < 1 ? (
                          <MenuItem key={`cat-${Category.CategoryID}`}>
                            <a
                              href={`/search-products/subcategory/${Category.CategoryID}`}
                            >
                              {Category.Category}
                            </a>
                          </MenuItem>
                        ) : (
                          <SubMenu
                            key={`cat-${Category.CategoryID}`}
                            title={Category.Category}
                          >
                            {Category.SubCategories.map((SubCategory) => (
                              <MenuItem
                                key={`subcat-${SubCategory.SubCategoryID}`}
                              >
                                <a
                                  style={{
                                    color: "unset",
                                    backgroundColor: "unset",
                                    paddingLeft: 0,
                                  }}
                                  href={`/search-products/subcategory/${SubCategory.SubCategoryID}`}
                                >
                                  {SubCategory.SubCategory}
                                </a>
                              </MenuItem>
                            ))}
                          </SubMenu>
                        )
                      )}
                    </SubMenu>
                  ))}
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <Signin
        signin={signin}
        setSignIn={setSignin}
        signup={signup}
        setSignUp={setSignup}
        reload={reload}
      />
      <Signup
        signin={signin}
        setSignIn={setSignin}
        signup={signup}
        setSignUp={setSignup}
        otpmodal={otpmodal}
        setOtpModal={setOtpModal}
      />
      <OtpVerify
        otpmodal={otpmodal}
        setOtpModal={setOtpModal}
        signin={signin}
        setSignIn={setSignin}
      />

      <EmailVerify />
    </>
  );
}
export default NavBar;
