import { useState } from "react";
import { useHistory } from "react-router-dom";
function OtherSettings() {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState("1");
  return (
    <div className="card mt-5">
      <div className="card-body">
        <h4 className="ftw-400 text-default">Settings</h4>

        <div className="row">
          <div className="col-3">
            <ul style={{ listStyle: "none" }} className="setting-ul">
              <li
                className={activeItem === "1" ? "active" : ""}
                onClick={() => setActiveItem("1")}
              >
                Manage Countries
              </li>
              <li
                className={activeItem === "2" ? "active" : ""}
                onClick={() => setActiveItem("2")}
              >
                Payment Gateway
              </li>
              <li
                className={activeItem === "3" ? "active" : ""}
                onClick={() => setActiveItem("3")}
              >
                Courier Services
              </li>
              <li
                className={activeItem === "4" ? "active" : ""}
                onClick={() => setActiveItem("4")}
              >
                Currency rate table
              </li>

              <li
                className={activeItem === "5" ? "active" : ""}
                onClick={() => setActiveItem("5")}
              >
                Quick Links
              </li>
              <li
                className={activeItem === "6" ? "active" : ""}
                onClick={() => setActiveItem("6")}
              >
                Currency Audit table
              </li>
            </ul>
          </div>
          <div className="col-8 sidebox-right">
            {activeItem === "1" && (
              <section>
                <div className="sidebox-header">Countries, States & Cities</div>
                <div className="pt-2">
                  <p>Manage countries, states and cities for the website.</p>
                  <div className="row m-0">
                    <div className="col-4">
                      <div style={{ float: "right" }}>
                        <button
                          className="btn btn-success btn-md"
                          onClick={() => history.push("/panel/country-list")}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {activeItem === "2" && (
              <section>
                <div className="sidebox-header">Payment Gateway</div>
                <div className="pt-2">
                  <p>Add new payment gateway, update previous or remove.</p>
                  <div className="row m-0">
                    <div className="col-4">
                      <div style={{ float: "right" }}>
                        <button
                          className="btn btn-success btn-md"
                          onClick={() => history.push("/panel/payment-gateway")}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {activeItem === "3" && (
              <section>
                <div className="sidebox-header">Courier Services</div>
                <div className="pt-2">
                  <p>Add new courier services, update previous or remove.</p>
                  <div className="row m-0">
                    <div className="col-4">
                      <div style={{ float: "right" }}>
                        <button
                          className="btn btn-success btn-md"
                          onClick={() =>
                            history.push("/panel/courier-services")
                          }
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {activeItem === "4" && (
              <section>
                <div className="sidebox-header">Currency Rate Table</div>
                <div className="pt-2">
                  <p>Check Currency.</p>
                  <div className="row m-0">
                    <div className="col-4">
                      <div style={{ float: "right" }}>
                        <button
                          className="btn btn-success btn-md"
                          onClick={() =>
                            history.push("/panel/currency-rate-table")
                          }
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {activeItem === "5" && (
              <section>
                <div className="sidebox-header">Quick Links</div>
                <div className="pt-2">
                  <p>Add new Quick Link, update previous or remove.</p>
                  <div className="row m-0">
                    <div className="col-4">
                      <div style={{ float: "right" }}>
                        <button
                          className="btn btn-success btn-md"
                          onClick={() => history.push("/panel/quik-links")}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {activeItem === "6" && (
              <section>
                <div className="sidebox-header">Currency Rate Table</div>
                <div className="pt-2">
                  <p>Check Currency.</p>
                  <div className="row m-0">
                    <div className="col-4">
                      <div style={{ float: "right" }}>
                        <button
                          className="btn btn-success btn-md"
                          onClick={() =>
                            history.push("/panel/currency-audit-table")
                          }
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default OtherSettings;
