import { WebsiteHeader } from "./../Layout/Header/Header";
import { Breadcrumb } from "antd";

import { NewsLetter } from "./../Layout/NewsLetter";
import { Footer } from "./../Layout/Footer";
import "firebase/firestore";
import "firebase/analytics";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useEffect, useState, useRef } from "react";
import { firestore_db } from "./../../../../Helpers/Firebase";
import { CurrentUser } from "./../../../../Helpers/Auth";
import BanglaBazarApi from "./../../../Api/BanglaBazarApi";
import Endpoint from "./../../../../Utils/Endpoint";
import firetoast from "./../../../../Helpers/FireToast";
import { serverTimestamp } from "firebase/firestore";
import { HiChevronRight } from "react-icons/hi2";

// import { AiOutlineSearch } from 'react-icons'

function SupportChat() {
  const [RoomId, setRoomId] = useState(null);
  const [Users, setUser] = useState([]);
  const [ActiveDoc, setActiveDoc] = useState(null);
  const [Messages, setMessages] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [name, setName] = useState("");
  const [search, setSearch] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    // await getUserDetail();
    if (CurrentUser) Fetchdata();
    console.log("HELlo");
  }, [CurrentUser]);

  // const Fetchdata = async () => {
  // const ref = await firestore_db
  //   .collection("Users")
  //   .where(
  //     "receiver_id",
  //     "==",
  //     parseInt(CurrentUser.UserID)
  //       "||",
  //     "sender_id",
  //     "==",
  //     parseInt(CurrentUser.UserID)
  //   )
  //   .orderBy("time_stamp")
  //   .get();
  //   // console.log("===============>", ref);

  //   var tempArray = [];
  //   ref.docs.forEach((doc) => {
  //     var obj = {
  //       doc_id: doc.id,
  //       doc_data: doc.data(),
  //     };
  //     tempArray.push(obj);
  //   });
  //   if (tempArray.length > 0) {
  //     console.log(tempArray);
  //     setRoomId(tempArray[0].doc_id);
  //     setActiveDoc(tempArray[0]);
  //     setUser(tempArray);
  //   }
  // };

  const Fetchdata = async () => {
    const receiverQuery = firestore_db
      .collection("Users")
      .where("receiver_id", "==", parseInt(CurrentUser.UserID));
    const senderQuery = firestore_db
      .collection("Users")
      .where("sender_id", "==", parseInt(CurrentUser.UserID));

    const ref = await Promise.all([receiverQuery.get(), senderQuery.get()]);
    const tempArray = [];

    ref.forEach((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var obj = {
          doc_id: doc.id,
          doc_data: doc.data(),
        };
        tempArray.push(obj);
      });
    });

    if (tempArray.length > 0) {
      console.log(tempArray);
      setRoomId(tempArray[0].doc_id);
      setActiveDoc(tempArray[0]);
      setUser(tempArray);
      console.log("tempArray==========>", tempArray);
    }
  };

  const handelChange = () => {
    if (searchText.length !== 0 || searchText !== "") {
      const users = Users.filter((_) => {
        return _.doc_data.sender_name
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setUser(users);
    } else {
      Fetchdata();
    }
  };

  useEffect(() => {
    handelChange();
  }, [searchText]);

  useEffect(() => {
    const u = localStorage.getItem("userID");

    console.log(Users);
    const user = Users.filter((item) => {
      console.log(item);
      return item.doc_data["sender_id"] == Number(u);
    });
    setSelectedUser(user[0]);
    OpenSelectedChat(user[0]);
  }, [Users, localStorage.getItem("userID")]);

  var getMessages = async (id) => {
    const messagesRef = firestore_db.collection("messages");
    const query = await messagesRef
      .where("room_id", "==", id)
      .orderBy("time_stamp")
      .get();
    var tempArray = [];
    query.docs.forEach((doc) => {
      tempArray.push(doc.data());
    });
    setMessages(tempArray);
  };
  function ChatRoom() {
    const dummy = useRef();

    const messagesRef = firestore_db.collection("messages");
    const query = messagesRef.where("room_id", "==", RoomId);
    // const messages = useCollectionData(query);
    // console.log(messages[0]);
    const [formValue, setFormValue] = useState("");

    const sendMessage = async (e) => {
      e.preventDefault();

      await messagesRef.add({
        text: formValue,
        time_stamp: serverTimestamp(),
        receiver_id: ActiveDoc["doc_data"]["receiver_id"],
        sender_id: parseInt(CurrentUser.UserID),
        room_id: RoomId,
      });

      setFormValue("");
      dummy.current.scrollIntoView({ behavior: "smooth" });
    };

    return <></>;
  }

  function ChatMessage(props) {
    const { text, sender_id } = props.message;

    return (
      <div>
        {parseInt(sender_id) === parseInt(CurrentUser.UserID) ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            {text}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            {text}
          </div>
        )}
      </div>
    );
  }

  let result = (
    <>
      {ActiveDoc &&
      ActiveDoc.doc_data &&
      ActiveDoc.doc_data.sender_id === CurrentUser.UserID ? (
        <>{ActiveDoc.doc_data.receiver_name}</>
      ) : ActiveDoc && ActiveDoc.doc_data ? (
        <>{ActiveDoc.doc_data.sender_name}</>
      ) : (
        <>Unknown</> // Replace with a default value or appropriate JSX if needed
      )}
    </>
  );

  // Use 'result' inside your JSX code to render the desired value

  var OpenSelectedChat = (doc) => {
    if (doc) {
      console.log("____________", doc);
      setRoomId(doc.doc_id);
      setActiveDoc(doc);

      // if (ActiveDoc.doc_data.sender_id === CurrentUser.UserID) {
      //   setName(ActiveDoc.doc_data.receiver_name)
      // } else {
      //   setName(ActiveDoc.doc_data.sender_name)
      // }
      // getMessages(doc.doc_id);
    }
  };
  var NotifyUser = async () => {
    try {
      await BanglaBazarApi.post(`${Endpoint}/api/admin/notify-user`, {
        senderID: parseInt(CurrentUser.UserID),
        receiverID: ActiveDoc["doc_data"]["receiver_id"],
      });
    } catch (e) {
      console.log(e);
    }
  };
  var sendText = async () => {
    var value = document.getElementById("chat-input").value;
    if (!value) {
      return firetoast("Chat can't be empty", "default-error");
    } else {
      const messagesRef = firestore_db.collection("messages");
      // const query = messagesRef.where("room_id", "==", RoomId);
      console.log(ActiveDoc["doc_data"]);
      await messagesRef.add({
        text: value,
        time_stamp: serverTimestamp(),
        receiver_id: ActiveDoc["doc_data"]["sender_id"],
        sender_id: parseInt(CurrentUser.UserID),
        room_id: RoomId,
      });

      getMessages(RoomId);
      document.getElementById("chat-input").value = "";
      NotifyUser();
    }
  };

  function SupportChatMessages({ RoomId }) {
    const [Messages, setMessages] = useState([]);
    const messagesRef = firestore_db.collection("messages");
    const query = messagesRef.where("room_id", "==", RoomId);
    const haha = useCollectionData(query);
    var scrollToBottom = () => {
      var div = document.getElementById("messages-chat");
      div.scrollTop = div.scrollHeight - div.clientHeight;
    };
    useEffect(async () => {
      if (RoomId) {
        const messagesRef = firestore_db.collection("messages");
        const temp_messages = await messagesRef
          .where("room_id", "==", RoomId)
          .orderBy("time_stamp")
          .onSnapshot((messages) => {
            var tempArray = [];
            messages.docs.forEach((doc) => {
              tempArray.push(doc.data());
            });
            setMessages(tempArray);
          });
        scrollToBottom();
      }
    }, [RoomId]);

    return (
      <>
        {Messages ? (
          <div
            className="messages-chat"
            id="messages-chat"
            style={{ height: "535px" }}
          >
            {Messages.map((message, index) => (
              <>
                {parseInt(message.sender_id) ===
                parseInt(CurrentUser.UserID) ? (
                  <div
                    className="response"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      marginTop: "10px",
                    }}
                  >
                    <p className="text-chat"> {message.text}</p>
                  </div>
                ) : (
                  <>
                    <div
                      className="message"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        marginTop: "10px",
                      }}
                    >
                      <p className="text-chat"> {message.text}</p>
                    </div>
                    <p className="time"> 14h58</p>
                  </>
                )}
              </>
            ))}
          </div>
        ) : null}
      </>
    );
  }

  return (
    <>
      <WebsiteHeader />

      <div className="container">
        <>
          <div className="breadcrumb-wrapper d-flex align-items-center gap-1 mt-2 pb-0">
            <Breadcrumb
              separator={<HiChevronRight />}
              items={[
                {
                  title: "Home",
                  href: "/",
                },
                {
                  title: "Support Chat",
                },
              ]}
            />
          </div>
        </>

        <section className="chatting">
          <div className="container">
            <div className="row">
              <section className="discussions">
                <div
                  className="discussion search "
                  style={{ backgroundColor: "#f6f6f6" }}
                >
                  {/* <h4 className="ftw-400">Chats</h4> */}
                  <div
                    className="d-flex align-items-center"
                    style={{ backgroundColor: "#f6f6f6" }}
                  >
                    <div className="d-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: "200px",
                        height: "75px",
                        padding: "20px",
                        border: "none",
                        outline: "none",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="Search User"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>

                {Users.map((user, index) => (
                  <div
                    className="discussion message-active"
                    key={index}
                    onClick={() => OpenSelectedChat(user)}
                  >
                    <div className="photo">
                      <img
                        className="reciever-image "
                        src={`${Endpoint}/${user.doc_data.sender_url}`}
                      />
                    </div>
                    <div className="desc-contact">
                      <p className="name mt-2">
                        {user.doc_data.sender_id === CurrentUser.UserID
                          ? user.doc_data.receiver_name
                          : user.doc_data.sender_name}
                      </p>
                    </div>
                  </div>
                ))}
              </section>
              <section className="chat">
                {ActiveDoc && (
                  <div className="header-chat">
                    <i className="icon fa fa-user-o" aria-hidden="true"></i>
                    <p className="name">
                      {/* {JSON.stringify(ActiveDoc.doc_data.sender_id)} */}
                      {/* {ActiveDoc && ActiveDoc?.doc_data !== null && ActiveDoc.doc_data?.sender_id === CurrentUser.UserID ? ActiveDoc.doc_data.receiver_name : ActiveDoc.doc_data.sender_name} */}
                      {/* {JSON.stringify(ActiveDoc.doc_data.sender_id === CurrentUser.UserID)} */}
                      {/* {ActiveDoc && ActiveDoc.doc_data.sender_name} */}

                      {result}
                    </p>
                  </div>
                )}

                {RoomId && (
                  <>
                    <SupportChatMessages RoomId={RoomId} />
                    <div
                      className="footer-chat"
                      style={{ position: "relative" }}
                    >
                      <i
                        className="icon fa fa-smile-o clickable"
                        style={{ fontSize: "25pt" }}
                        aria-hidden="true"
                      ></i>
                      <input
                        type="text"
                        className="write-message"
                        placeholder="Type your message here"
                        id="chat-input"
                      ></input>
                      <i
                        className="icon send fas fa-paper-plane clickable"
                        aria-hidden="true"
                        onClick={() => sendText()}
                      ></i>
                    </div>
                  </>
                )}
              </section>
            </div>
          </div>
        </section>
      </div>
      {/* <NewsLetter /> */}
      <Footer />
    </>
  );
}
export default SupportChat;
