import { useContext, useState, useEffect } from "react";
import { PRODUCT_FORM_CONTEXT } from "./../../../../Contexts/ProductFormContext";
import { Link, useParams } from "react-router-dom";
import firetoast from "./../../../../../Helpers/FireToast";
import Resizer from "react-image-file-resizer";
import BanglaBazarApi from "./../../../../Api/BanglaBazarApi";
import Endpoint from "./../../../../../Utils/Endpoint";
import CheckEmpty from "./../../../../../Utils/CheckEmpty";
import { Avatar, Badge, Space } from "antd";
import Loading from "../../../../../Utils/Loading";

import { CurrentUser } from "./../../../../../Helpers/Auth";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Alert,
} from "reactstrap";
import { RequiredField } from "../../../../../Utils/Required-field";
import OptionValueField from "./OptionValueField";

function EditProductVariants({ Active, ReviewedByAdmin }) {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [previewImages, setPreviewImages] = useState([]);
  const [previewImagesIndex, setPreviewImagesIndex] = useState(null);
  const [selectedProductImageIDs, setSelectedProductImageIDs] = useState({});
  console.log(
    selectedProductImageIDs,
    "selectedProductImageIDsselectedProductImageIDsselectedProductImageIDs"
  );
  const [selectedProductImageID, setSelectedProductImageID] = useState("");
  var [VariationValues, setVariationValues] = useState([]);
  var [vaiantValues, setVariantValues] = useState([]);
  console.log("vaiantValues", vaiantValues);
  var [optionid, setOptionid] = useState(null);
  var [showModal, setshowModal] = useState(false);
  var [AvailabaleValues, setAvailabaleValues] = useState([]);
  var [isAddCustom, setIsAddCustom] = useState(false);
  var [ModalSmall, setModalSmall] = useState({});
  var [ModalMedium, setModalMedium] = useState({});
  var [ModalLarge, setModalLarge] = useState({});
  var [ModalButton, setModalButton] = useState(false);
  var [ModalPrice, setModalPrice] = useState("0");
  var [disableBtn, setDisableBtn] = useState(false);
  const [editeAble, setEditeABle] = useState(false);
  const [optionWithValues, setOptionWithValues] = useState([]);

  const [videoShow, setVideoShow] = useState(false);
  const [videoURL, setVideoURL] = useState("");

  var [smallImage, setSmallImage] = useState(null);
  var [meduimImage, setMeduimImage] = useState(null);
  var [LargeImage, setLargeImage] = useState(null);
  const [updatedValues, setUpdatedValues] = useState({});

  const [loadingStates, setLoadingStates] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [videoLoading, setVideoLoading] = useState(false);

  const [deletingItemId, setDeletingItemId] = useState(null);

  const [videoItems, setVideoItems] = useState();

  const handleInputChange = (event, optionValueID) => {
    const { value } = event.target;
    console.log("optionValueID", value);

    // Create a new object with the updated value
    const updated = { ...updatedValues, [optionValueID]: value };

    console.log("updated", updated);
    // Set the updated values to state
    setUpdatedValues(updated);
  };

  // const handleDropdownChange = (optionId, e) => {
  //   setSelectedOptions(prevState => ({
  //     ...prevState,
  //     [optionId]: e.target.value
  //   }));
  // };
  const handleDropdownChange = (optionId, e) => {
    const selectedText = e.target.options[e.target.selectedIndex].text;
    setSelectedOptions((prevState) => ({
      ...prevState,
      [optionId]: { id: e.target.value, text: selectedText },
    }));
  };

  useEffect(() => {
    if (localStorage.getItem("videoDel") === "true") {
      // Perform necessary actions here, like updating state or UI

      // Remove the item from localStorage
      localStorage.removeItem("videoDel");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("imageDel") === "true") {
      // Perform necessary actions here, like updating state or UI

      // Remove the item from localStorage
      localStorage.removeItem("imageDel");
    }
  }, []);

  const handleVideoDelete = async (item) => {
    const id = item.ProductVariantCombinationID;

    setVideoLoading(true);
    setDeletingItemId(id);

    try {
      var response = await BanglaBazarApi.delete(
        `${Endpoint}/api/product/video/${id}`
      );
      console.log("RESPONSE DATA", response.data);

      setVideoLoading(true);
      setDeletingItemId(null);

      localStorage.setItem("videoDel", true);

      firetoast("video removed successfully", "success", 3000, "top-right");

      setTimeout(() => {
        getAllVariantAndValues();
      }, 500);
    } catch (err) {
      setVideoLoading(false);
      setDeletingItemId(id);

      firetoast("Error", "error", 3000, "top-right");
    } finally {
    }
  };

  const handleImageDelete = async (id, imageIndex) => {
    // Set loading state to true for the specific image
    setLoadingStates((prev) => ({ ...prev, [imageIndex]: true }));

    try {
      var response = await BanglaBazarApi.delete(
        `${Endpoint}/api/product/image/${id}`
      );
      console.log("RESPONSE DATA", response.data);

      localStorage.setItem("imageDel", true);

      firetoast("Image removed successfully", "success", 3000, "top-right");

      setTimeout(() => {
        getAllVariantAndValues();
      }, 500);
    } catch (err) {
      console.log(err);
    } finally {
      // Set loading state to false once the API call is completed
      setLoadingStates((prev) => ({ ...prev, [imageIndex]: false }));
    }
  };

  var { productID } = useParams();

  const {
    Options,
    setOptions,
    SubCategoryVariants,
    SKU,
    setSKU,
    Price,
    Status,
    ProductID,
    StoreName,
    setPrice,
    AvailableInventory,
    OptionId,
    setOptionId,
    setAvailableInventory,
    setSmall,
    setMedium,
    setLarge,
    OptionValue,
    setOptionValue,
    MainImage,
    Inventory,
    setInventory,
    UnitPrice,
    setUnitPrice,
    TotalPrice,
    setTotalPrice,
    Small,
    Medium,
    Large,
    Variations,
    getOptions,
    Currency,
    AvailableVariations,
  } = useContext(PRODUCT_FORM_CONTEXT);

  useEffect(async () => {
    await getAllVariantAndValues();
  }, []);

  useEffect(() => {
    console.log("selectedOptions", selectedOptions);
  }, [selectedOptions]);

  var getAllVariantAndValues = async () => {
    try {
      var response = await BanglaBazarApi.get(
        `${Endpoint}/api/product/get-productAllDetails/${productID}/${CurrentUser.UserID}`
      );
      console.log("jksaHLS");
      const res = response.data.Product;
      const result = response.data.VariantDetails;
      setVariationValues(res);
      setVariantValues(result);
      console.log(
        "response.data.result----------------------------------------------------------->",
        result
      );
    } catch (e) {
      console.log(e);
      firetoast(
        "Something went wrong while getting varaint and values",
        "default-error"
      );
    }
  };

  var UpdateVarantValueImage = async (file) => {
    if (file) {
      // console.log(await VariantImageResizer(file,1,1))

      await Resizer.imageFileResizer(
        file,
        50,
        50,
        "PNG",
        100,
        0,
        (uri) => {
          setSmall(uri);
        },
        "file"
      );
      await Resizer.imageFileResizer(
        file,
        200,
        200,
        "PNG",
        100,
        0,
        (uri) => {
          setMedium(uri);
        },
        "file"
      );
      await Resizer.imageFileResizer(
        file,
        400,
        550,
        "PNG",
        100,
        0,
        (uri) => {
          setLarge(uri);
        },
        "file"
      );
    }
  };

  useEffect(() => {
    if (Active === "N" && ReviewedByAdmin === "N") {
      setEditeABle(true);
    }
  }, [Active, ReviewedByAdmin]);

  //! there is the value

  var submitOptionValueDetail = async (id, item, variantIndex) => {
    var {
      VariantValue,
      SKU,
      VariantPrice,
      AvailableInventory,
      Inventory,
      UnitPrice,
      TotalPrice,
      Small,
      Medium,
      Large,
      MainImage,
      variantionValues,
      images,
      video,
    } = item;

    console.log("item----------------------->", item);
    item.variantionValues.map((item, index) => {
      if (item.VariantValue == "" || item.VariantValue == null) {
        return firetoast(
          `Option Value Name for ${item.VariantName} is required`,
          "default-error"
        );
      }
    });

    // Access the selected ProductImageID from selectedImageIndexes

    if (Object.keys(selectedProductImageIDs).length > 0) {
      const selectedImageIndex = selectedProductImageIDs[variantIndex];

      if (selectedImageIndex === undefined) {
        firetoast(
          "Please select a Main Image before saving.",
          "error",
          3000,
          "top-right"
        );
        return;
      }
    }

    // Price = item.variantionValues[0].VariantPrice

    // return
    if (CheckEmpty(VariantValue)) {
      return firetoast("Option Value Name is required", "default-error");
    }
    if (CheckEmpty(SKU)) {
      return firetoast("SKU is required", "default-error");
    }
    // if (CheckEmpty(Price)) {
    //   return firetoast("Price is required", "default-error");
    // }
    if (CheckEmpty(AvailableInventory)) {
      return firetoast("Available Inventory is required", "default-error");
    }
    if (CheckEmpty(Inventory)) {
      return firetoast("Inventory is required", "default-error");
    }
    if (parseInt(AvailableInventory) > parseInt(Inventory)) {
      return firetoast(
        "Available Inventory should be less than Inventory",
        "default-error"
      );
    }
    if (CheckEmpty(UnitPrice)) {
      return firetoast("Unit Price is required", "default-error");
    }
    if (CheckEmpty(TotalPrice)) {
      return firetoast("Total Price is required", "default-error");
    }
    // if (MainImage === "Y") {
    //   var found = false;

    //   for (var i = 0; i < VariationValues?.length; i++) {
    //     if (
    //       VariationValues[i].VariantValue !== VariantValue &&
    //       VariationValues[i].MainImage === "Y"
    //     ) {
    //       found = true;
    //     }
    //   }
    //   if (found) {
    //     return firetoast(
    //       "Only one Main Image can be set from the available variant values",
    //       "error",
    //       3000,
    //       "top-right"
    //     );
    //   }
    // }
    try {
      setIsLoading(true);
      console.log(video, images, "0000000000000000000000000000");

      // const largeImages = images.map(image => image.large);
      // const smallImages = images.map(image => image.small);
      // const mediumImages = images.map(image => image.medium);

      var form = new FormData();
      if (images && images.length > 0) {
        for (const file of images) {
          form.append("largeImage", file.large);
          form.append("smalImage", file.small);
          form.append("meduimImage", file.medium);
        }
      }
      form.append("ProductID", productID);
      form.append("ProductImageID", selectedProductImageID);
      form.append("variantionValues", JSON.stringify(variantionValues));
      form.append("video", video);
      form.append("StoreName", StoreName);
      form.append("OptionID", optionid);
      form.append("OptionValue", VariantValue);
      form.append("MainImage", MainImage);
      form.append("SKU", SKU);
      form.append("Price", VariantPrice);
      form.append("AvailableInventory", AvailableInventory);
      form.append("Inventory", Inventory);
      form.append("UnitPrice", UnitPrice);
      form.append("TotalPrice", TotalPrice);
      form.append("Status", Status);
      form.append("Small", Small);
      form.append("Medium", Medium);
      form.append("Large", Large);

      var response = await BanglaBazarApi.put(
        `${Endpoint}/api/product/updateProduct-Form2/${id}`,
        form
      );

      setPreviewImages([]);
      setIsLoading(false);
      // console.log("data==============>", data);

      console.log(response);

      if (response.data.status) {
        firetoast(
          "Option Value updated Added Successfully",
          "success",
          3000,
          "top-right"
        );

        setTimeout(() => {
          getAllVariantAndValues();
        }, 100);
      } else {
        var { message, error } = response.data;
        return firetoast(message || error, "default-error");
      }
    } catch (e) {
      console.log(e);
      return firetoast(
        "Something went wrong while updating option value deatils",
        "default-error"
      );
    }
  };

  var AddNewOptionValue = async (item) => {
    var {
      VariantValue,
      SKU,
      Price,
      AvailableInventory,
      Inventory,
      UnitPrice,
      TotalPrice,
      Small,
      Medium,
      Large,
    } = item;

    console.log("------------------------------------", item);

    if (CheckEmpty(VariantValue)) {
      return firetoast("Option Value Name is required", "default-error");
    }
    if (CheckEmpty(SKU)) {
      return firetoast("SKU is required", "default-error");
    }
    if (CheckEmpty(Price)) {
      return firetoast("Price is required", "default-error");
    }
    if (CheckEmpty(AvailableInventory)) {
      return firetoast("Available Inventory is required", "default-error");
    }

    if (CheckEmpty(Inventory)) {
      return firetoast("Inventory is required", "default-error");
    }
    if (parseInt(AvailableInventory) > parseInt(Inventory)) {
      return firetoast(
        "Available Inventory should be less than Inventory",
        "default-error"
      );
    }
    if (CheckEmpty(UnitPrice)) {
      return firetoast("Unit Price is required", "default-error");
    }
    if (CheckEmpty(TotalPrice)) {
      return firetoast("Total Price is required", "default-error");
    }
    if (MainImage === "Y") {
      var found = 0;

      for (var i = 0; i < VariationValues.length; i++) {
        if (
          VariationValues[i].VariantValue !== VariantValue &&
          VariationValues[i].MainImage === "Y"
        ) {
          found += 1;
        }
      }
      if (VariationValues[0].MainImage === "Y") {
        return firetoast(
          "Only one Main Image can be set from the available variant values",
          "error",
          3000,
          "top-right"
        );
      }
    }
    try {
      var form = new FormData();
      form.append("ProductID", productID);
      form.append("StoreName", StoreName);
      form.append("OptionID", optionid);
      form.append("OptionValue", VariantValue);
      form.append("MainImage", MainImage);
      form.append("SKU", SKU);
      form.append("Price", Price);
      form.append("AvailableInventory", AvailableInventory);
      form.append("Inventory", Inventory);
      form.append("UnitPrice", UnitPrice);
      form.append("TotalPrice", TotalPrice);
      form.append("Status", Status);
      form.append("Small", Small);
      form.append("Medium", Medium);
      form.append("Large", Large);

      var response = await BanglaBazarApi.post(
        `${Endpoint}/api/product/addProduct-Form2`,
        form
      );
      if (response.data.status) {
        firetoast(
          "Option Value Details Added Successfully",
          "success",
          3000,
          "top-right"
        );
        getAllVariantAndValues();
        clearDetails();
      } else {
        var { message, error } = response.data;
        return firetoast(message || error, "default-error");
      }
    } catch (e) {
      console.log(e);
      return firetoast(
        "Something went wrong while adding option value deatils",
        "default-error"
      );
    }
  };
  var clearDetails = () => {
    setOptionId("null");
    setOptionValue("");
    setSKU("");
    setPrice("");
    setInventory("");
    setTotalPrice("");
    setAvailableInventory("");
    setSmall("");
    setMedium("");
    setLarge("");
  };
  let getOptionValues = async (id) => {
    try {
      const response = await BanglaBazarApi.get(
        `${Endpoint}/api/product/get-productVariantDetail/${id}`
      );
      var data = response.data.ProductVariants;
      for (let i = 0; i < data.length; i++) {
        data[i].isNew = false;
        data[i].UnitPrice = 0;
        data[i].TotalPrice = 0;
      }
      setVariationValues(data);
    } catch (e) {
      firetoast(
        "Something went wrong while fething option values",
        "default-error"
      );
    }
  };
  let getOPtionsWithValue = async (id) => {
    try {
      const response = await BanglaBazarApi.get(
        `${Endpoint}/api/product/get-productVariantOptionWithValues/${id}`
      );
      var data = response.data.ProductVariantOptions;
      console.log("data---------------------------------------------", data);
      setOptionWithValues(data);
    } catch (e) {
      firetoast(
        "Something went wrong while fething option values",
        "default-error"
      );
    }
  };

  useEffect(() => {
    getOPtionsWithValue(productID);
  }, []);

  let handleVariationValueChange = (e, index, i) => {
    // console.log(e.target.value, '-----------------------------');
    var array = [...VariationValues];

    array[index][e.target.name] = e.target.value;
    if (e.target.name === "VariantPrice") {
      array[index]["Price"] = e.target.value;
    }
    setVariationValues(array);

    let variant_array = [...vaiantValues];
    // variant_array[index][e.target.name] = e.target.value;

    console.log({ id: e.target.id, i, index });

    if (i !== undefined) {
      variant_array[index].variantionValues[i][e.target.name] = e.target.value;
    } else {
      variant_array[index][e.target.name] = e.target.value;
      if (e.target.name === "VariantPrice") {
        array[index]["Price"] = e.target.value;
      }
    }

    console.log(variant_array, "-------------------");
    setVariantValues(variant_array);
  };

  let handleVariationValueMainImageChange = (e, index) => {
    var array = [...VariationValues];
    array[index].MainImage = e;
    setVariationValues(array);
  };

  let setVariationList = async () => {
    let foundVariationLabel = "";
    for (let i = 0; i < Variations.length; i++) {
      if (parseInt(Variations[i].value) === parseInt(optionid)) {
        console.log("inside");
        foundVariationLabel = Variations[i].label;
      }
    }
    let foundVariationId = "";
    for (let i = 0; i < AvailableVariations.length; i++) {
      if (AvailableVariations[i].label === foundVariationLabel) {
        foundVariationId = AvailableVariations[i].id;
      }
    }
    console.log(foundVariationLabel, "foundVariationLabel");
    console.log(AvailableVariations, "AvailableVariations");
    console.log(optionid, "optionid");
    console.log(foundVariationId, "foundVariationId");
    // try {
    //   var response = await BanglaBazarApi.get(
    //     `${Endpoint}/api/category/get-subcategoryvariantvalue/${foundVariationId}`
    //   );
    //   setAvailabaleValues(response.data.SubCategoryVariantValue);
    //   console.log("response.data.SubCategoryVariantValue", response.data.SubCategoryVariantValue);

    // } catch (e) {
    //   firetoast("Something went wrong!", "default-error");
    // }
  };

  useEffect(() => {
    console.log("=========================", Options);
  }, []);

  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null); // State to track the selected video index
  const [selectedVideo, setSelectedVideo] = useState("");

  const handleVideoUpload = async (file, index) => {
    let array = [...vaiantValues];

    const selectedFile = file;

    if (selectedFile) {
      const fileGet = file;
      const newVideoUrl = URL.createObjectURL(fileGet);
      setSelectedVideo(newVideoUrl);

      setSelectedVideoIndex(index);

      const validVideoTypes = ["video/mp4", "video/mpeg", "video/quicktime"];

      if (validVideoTypes.includes(selectedFile.type)) {
        // It's a valid video file
        setVideoShow(true);

        array[index]["video"] = file;
        console.log(`Selected file is a video: ${selectedFile.name}`);
      } else {
        // Not a video file
        console.log("Selected file is not a valid video file.");
        return firetoast("Selected File is not a video type.", "default-error");
      }
    }

    console.log("array=================>", array);
  };

  var handleUpdateVarantValueImage = async (files, index) => {
    if (!files && !files[0]) return;
    const newImages = Array.from(files).map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setPreviewImages((prevImages) => [...prevImages, ...newImages]);
    setPreviewImagesIndex(index);

    let array = [...vaiantValues];

    console.log("------------------------------------>", files, index);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // console.log("-------------------------->", file);

      if (file) {
        let imageDetails = { small: {}, medium: {}, large: {} };

        await Resizer.imageFileResizer(
          file,
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
            imageDetails.small = uri; // replace 'details' with the actual data if needed
          },
          "file",
          1,
          1
        );

        await Resizer.imageFileResizer(
          file,
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
            imageDetails.medium = uri; // replace 'details' with the actual data if needed
          },
          "file",
          10,
          10
        );

        await Resizer.imageFileResizer(
          file,
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
            imageDetails.large = uri; // replace 'details' with the actual data if needed
          },
          "file",
          100,
          100
        );

        if (!array[index]["images"]) {
          array[index]["images"] = [];
        }
        array[index]["images"].push(imageDetails);
      }
    }

    console.log("array=================>", array);
    return;
  };
  var AddNewOption = async () => {
    var array = [...VariationValues];
    if (optionid != null) {
      array.push({
        ProductID: "",
        StoreName: "",
        OptionID: "",
        OptionValue: "",
        MainImage: "N",
        SKU: "",
        Price: "",
        AvailableInventory: "",
        Inventory: "",
        UnitPrice: "",
        TotalPrice: "",
        Status: "",
        Small: "",
        Medium: "",
        Large: "",
        isNew: true,
      });
      setVariationValues(array);
    }
  };
  var removeVariantValue = async (item) => {
    try {
      console.log("--------------------->", item);
      const response = await BanglaBazarApi.post(
        `${Endpoint}/api/product/delete-productVariantValue`,
        { item }
      );

      if (response.data.status) {
        firetoast(
          "Variant Value removed successfully",
          "success",
          3000,
          "top-right"
        );
        getOptionValues(optionid);
        setTimeout(() => {
          getAllVariantAndValues();
        }, 100);
      }
    } catch (e) {
      firetoast(
        "Something went wrong while removing variant value",
        "default-error"
      );
    }
  };

  // var CreateNewValue = async () => {
  //   let VariantValue = document.getElementById("valueName").value;
  //   let SKU = document.getElementById("variantSKU").value;
  //   let Price = document.getElementById("variantPrice").value;
  //   let Inventory = document.getElementById("variantInventory").value;
  //   let AvailableInventory = document.getElementById(
  //     "variantAvailableInventory"
  //   ).value;
  //   let TotalPrice = document.getElementById("variantTotalPrice").value;
  //   let UnitPrice = document.getElementById("variantUnitPrice").value;
  //   let file = document.getElementById("variantImage").files[0];

  //   if (CheckEmpty(VariantValue)) {
  //     return firetoast("Option Value Name is required", "default-error");
  //   }

  //   if (CheckEmpty(SKU)) {
  //     return firetoast("SKU is required", "default-error");
  //   }
  //   if (CheckEmpty(Price)) {
  //     return firetoast("Price is required", "default-error");
  //   }
  //   if (CheckEmpty(AvailableInventory)) {
  //     return firetoast("Available Inventory is required", "default-error");
  //   }

  //   if (CheckEmpty(Inventory)) {
  //     return firetoast("Inventory is required", "default-error");
  //   }
  //   if (parseInt(Inventory) < parseInt(AvailableInventory)) {
  //     return firetoast(
  //       "Available Inventory should be less than Inventory",
  //       "default-error"
  //     );
  //   }
  //   if (CheckEmpty(UnitPrice)) {
  //     return firetoast("Unit Price is required", "default-error");
  //   }
  //   if (CheckEmpty(TotalPrice)) {
  //     return firetoast("Total Price is required", "default-error");
  //   }
  //   if (!file) {
  //     return firetoast("Image is required", "defaulr-error");
  //   }
  //   if (file) {
  //     // console.log(await VariantImageResizer(file,1,1))

  //     Resizer.imageFileResizer(
  //       file,
  //       300,
  //       300,
  //       "JPEG",
  //       100,
  //       0,
  //       (uri) => {
  //         console.log(uri);
  //         setModalSmall(uri);
  //       },
  //       "file",
  //       1,
  //       1
  //     );
  //     Resizer.imageFileResizer(
  //       file,
  //       300,
  //       300,
  //       "JPEG",
  //       100,
  //       0,
  //       (uri) => {
  //         console.log(uri);
  //         setModalMedium(uri);
  //       },
  //       "file",
  //       10,
  //       10
  //     );
  //     Resizer.imageFileResizer(
  //       file,
  //       300,
  //       300,
  //       "JPEG",
  //       100,
  //       0,
  //       (uri) => {
  //         setModalLarge(uri);
  //         console.log(uri);
  //       },
  //       "file",
  //       100,
  //       100
  //     );
  //   }
  //   // if (MainImage === "Y") {
  //   //   var found = 0;

  //   //   for (var i = 0; i < VariationValues.length; i++) {
  //   //     if (
  //   //       VariationValues[i].VariantValue !== VariantValue &&
  //   //       VariationValues[i].MainImage === "Y"
  //   //     ) {
  //   //       found += 1;
  //   //     }
  //   //   }
  //   //   if (VariationValues[0].MainImage === "Y") {
  //   //     return firetoast(
  //   //       "Only one Main Image can be set from the available variant values",
  //   //       "error",
  //   //       3000,
  //   //       "top-right"
  //   //     );
  //   //   }
  //   // }
  //   for (let i = 0; i < VariationValues.length; i++) {
  //     if (VariationValues[i].VariantValue === VariantValue) {
  //       setModalButton(true);
  //       return firetoast("Value already exist with this name", "default-error");
  //     }
  //   }
  //   try {
  //     var form = new FormData();
  //     form.append("ProductID", productID);
  //     form.append("StoreName", StoreName);
  //     form.append("OptionID", optionid);
  //     form.append("OptionValue", VariantValue);
  //     form.append("MainImage", "N");
  //     form.append("SKU", SKU);
  //     form.append("Price", Price);
  //     form.append("AvailableInventory", AvailableInventory);
  //     form.append("Inventory", Inventory);
  //     form.append("UnitPrice", UnitPrice);
  //     form.append("TotalPrice", TotalPrice);
  //     form.append("Status", "Y");

  //     form.append("Small", ModalSmall);
  //     form.append("Medium", ModalMedium);
  //     form.append("Large", ModalLarge);

  //     console.log("IMAGES DATA===============>", ModalSmall, ModalMedium, ModalLarge, TotalPrice);
  //     // return

  //     var response = await BanglaBazarApi.post(
  //       `http://localhost:3001/api/product/addProduct-Form2`,
  //       form
  //     );
  //     console.log(response);
  //     if (response.data.status) {
  //       firetoast(
  //         "Option Value Details Added Successfully",
  //         "success",
  //         3000,
  //         "top-right"
  //       );
  //       getAllVariantAndValues();
  //       setshowModal(false);
  //       clearDetails();
  //     } else {
  //       var { message, error } = response.data;
  //       return firetoast(message || error, "default-error");
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     return firetoast(
  //       "Something went wrong while adding option value deatils",
  //       "default-error"
  //     );
  //   }
  // };

  var CreateNewValue = async () => {
    let file = document.getElementById("variantImage").files[0];

    // console.log("------------------------------------->", file);

    // return

    // setDisableBtn(true)

    // let VariantValue = document.getElementById("valueName").value || ""

    let optionIds = [];
    const keys = Object.keys(selectedOptions);
    const values = Object.values(selectedOptions);

    for (let key of keys) {
      console.log(key + ": " + selectedOptions[key]["id"]);
      optionIds.push(selectedOptions[key]["id"]);
    }
    console.log(
      "selectedOption-------------------s",
      optionIds,
      selectedOptions
    );

    let SKU = document.getElementById("variantSKU").value;
    let Price =
      document.getElementById("variantPrice").value || selectedOptions["Price"];
    let Inventory = document.getElementById("variantInventory").value;
    let AvailableInventory = document.getElementById(
      "variantAvailableInventory"
    ).value;
    let TotalPrice = document.getElementById("variantTotalPrice").value;
    let UnitPrice = document.getElementById("variantUnitPrice").value;

    // console.log("=================>", VariantValue);

    if (keys.length !== optionWithValues.length) {
      setDisableBtn(false);
      return firetoast(
        "Please all select the required combinations",
        "default-error"
      );
    }

    // if (CheckEmpty(VariantValue)) {
    //   setDisableBtn(false)
    //   return firetoast("Option Value Name is required", "default-error");
    // }

    if (CheckEmpty(SKU)) {
      setDisableBtn(false);
      return firetoast("SKU is required", "default-error");
    }
    if (CheckEmpty(Price)) {
      setDisableBtn(false);
      return firetoast("Price is required", "default-error");
    }
    if (CheckEmpty(AvailableInventory)) {
      setDisableBtn(false);
      return firetoast("Available Inventory is required", "default-error");
    }

    if (CheckEmpty(Inventory)) {
      setDisableBtn(false);
      return firetoast("Inventory is required", "default-error");
    }
    if (parseInt(Inventory) < parseInt(AvailableInventory)) {
      setDisableBtn(false);
      return firetoast(
        "Available Inventory should be less than Inventory",
        "default-error"
      );
    }
    if (CheckEmpty(UnitPrice)) {
      setDisableBtn(false);
      return firetoast("Unit Price is required", "default-error");
    }
    if (CheckEmpty(TotalPrice)) {
      setDisableBtn(false);
      return firetoast("Total Price is required", "default-error");
    }
    if (!file) {
      setDisableBtn(false);
      return firetoast("Image is required", "defaulr-error");
    }

    if (file) {
      try {
        const resizePromises = [];

        resizePromises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              300,
              300,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                setModalSmall(uri);
                resolve(uri);
              },
              "file",
              1,
              1
            );
          })
        );

        resizePromises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              300,
              300,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                setModalMedium(uri);
                resolve(uri);
              },
              "file",
              10,
              10
            );
          })
        );

        resizePromises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              300,
              300,
              "JPEG",
              100,
              0,
              (uri) => {
                setModalLarge(uri);
                console.log(uri);
                resolve(uri);
              },
              "file",
              100,
              100
            );
          })
        );

        const [smallUri, mediumUri, largeUri] = await Promise.all(
          resizePromises
        );

        setSmallImage(smallUri);
        setMedium(mediumUri);
        setLargeImage(largeUri);

        // console.log("++++++++++++++++++++++++++++++---------------------------------->", VariantValue);

        // for (let i = 0; i < VariationValues.length; i++) {
        //   if (VariationValues[i].VariantValue === VariantValue) {
        //     setModalButton(true);
        //     setDisableBtn(false)
        //     return firetoast("Value already exists with this name", "default-error");
        //   }
        // }

        try {
          var form = new FormData();
          console.log(
            "selectedOptions['Price'];----------------------->",
            selectedOptions["Price"]
          );
          form.append("optionIds", optionIds);
          form.append("optionValues", JSON.stringify(selectedOptions));
          form.append("ProductID", productID);
          form.append("StoreName", StoreName);
          form.append("OptionID", optionid);
          form.append("OptionValue", "");
          form.append("MainImage", "N");
          form.append("SKU", SKU);
          form.append("Price", Price);
          form.append("AvailableInventory", AvailableInventory);
          form.append("Inventory", Inventory);
          form.append("UnitPrice", UnitPrice);
          form.append("TotalPrice", TotalPrice);
          form.append("Status", "Y");

          form.append("Small", smallUri);
          form.append("Medium", mediumUri);
          form.append("Large", largeUri);

          var response = await BanglaBazarApi.post(
            `${Endpoint}/api/product/addProduct-Form2`,
            form
          );
          console.log(response);
          if (response.data.status) {
            firetoast(
              "Option Value Details Added Successfully",
              "success",
              3000,
              "top-right"
            );
            getAllVariantAndValues();
            setDisableBtn(false);
            setshowModal(false);
            clearDetails();
          } else {
            var { message, error } = response.data;
            return firetoast(message || error, "default-error");
          }
        } catch (e) {
          setDisableBtn(false);
          console.log("----------------------", e.response.data.message);
          if (e.response?.data?.message) {
            return firetoast(e.response.data.message, "default-error");
          }
          return firetoast(
            "Something went wrong while adding option value details",
            "default-error"
          );
        }
      } catch (error) {
        setDisableBtn(false);
        console.log(error);
        return firetoast(
          "Something went wrong while resizing the image",
          "default-error"
        );
      }
    }
  };
  console.log("myyyyyyyy", selectedProductImageID);
  const handleCheckboxChange = (variantIndex, imageIndex) => {
    const updatedFaatState = [...vaiantValues];
    const id = updatedFaatState[variantIndex].Images[imageIndex].ProductImageID;
    // Set MainImage to 'N' for all images in the variant
    // Uncheck all images in the variant
    // updatedFaatState[variantIndex].Images.forEach((image) => {
    //   image.MainImage = 'N';
    // });

    // // Check the last selected image
    // updatedFaatState[variantIndex].Images[imageIndex].MainImage = 'Y';
    // Uncheck all images in the variant
    // Uncheck all images in all variants
    updatedFaatState.forEach((variant) => {
      variant.Images.forEach((image) => {
        image.MainImage = "N";
      });
    });

    // Check the selected image
    updatedFaatState[variantIndex].Images[imageIndex].MainImage = "Y";

    console.log(updatedFaatState, "updatedFaatState");
    setSelectedProductImageIDs({
      // ...selectedProductImageIDs,
      [variantIndex]: id,
    });
    setSelectedProductImageID(id);
    setVariantValues(updatedFaatState);
  };
  return (
    <>
      {/* <div>
        <b style={{ fontWeight: "600", fontSize: "15px" }}>
          Select the availabe variants from the listss{" "}
        </b>
      </div> */}
      <div className="row">
        {/* <div className="col-6">
          <select
            className="form-control"
            onChange={(e) => {
              console.log(e.target.value);
              getOptionValues(e.target.value);
              setOptionid(e.target.value);
              console.log(e.target.value == 'null');
              if (e.target.value == 'null') {
                getAllVariantAndValues()
              }
              // getOptionsValues(e.target.value);
            }}
          >
            <option value={"null"} >Select All</option>
            {Variations.map((item, index) => (
              <option
                value={item.value}
                key={index}
                selected={OptionId === item.value}
              >
                {item.label}
              </option>
            ))}
          </select>
        </div> */}

        <div className="col-6">
          <div style={{ float: "left" }}>
            <button
              // disabled={editeAble}

              className="btn btn-success"
              // onClick={() => AddNewOption()}
              onClick={() => {
                setshowModal(!showModal);
                setVariationList();
              }}
            >
              Add New Variant Values
            </button>
          </div>
        </div>
      </div>
      {vaiantValues?.map((item, index) => {
        return (
          <div
            className="row p-3 m-3"
            style={{ border: "1px solid rgba(128, 128, 128, 0.58) " }}
            key={index}
          >
            <div className="d-flex justify-content-between align-items-center">
              {item.variantionValues.map((variation, i) => {
                return (
                  <>
                    <div style={{ display: "inline" }}>
                      <h5 className="ftw-400 text-default">
                        {variation.VariantName} - {variation.VariantValue}
                      </h5>
                    </div>
                  </>
                );
              })}
              {!item?.isNew && VariationValues?.length > 1 && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => removeVariantValue(item)}
                >
                  <i className="fas fa-times text-danger"></i>
                </div>
              )}
            </div>

            {item.variantionValues.map((variation, i) => {
              return (
                <div className="col-6">
                  <label>
                    {variation.VariantName} <RequiredField />
                  </label>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    name={"VariantValue"}
                    onChange={(e) => {
                      handleVariationValueChange(e, index, i);
                      // console.log({ e, i });
                    }}
                    value={variation.VariantValue}
                  />
                  {/* <input
                disabled={editeAble}
                className="form-control"
                type="text"
                name="VariantValue"
                value={variation.VariantValue}
                onChange={(e) => {
                  console.log(e.target.value, "--------------");
                  // return
                  handleInputChange(e, variation.OptionValueID)
                }}
              /> */}
                </div>
              );
            })}

            <div className="col-6">
              <label>
                SKU <RequiredField />
              </label>
              <input
                disabled={editeAble}
                className="form-control"
                type="text"
                value={item.SKU}
                name="SKU"
                onChange={(e) =>
                  handleVariationValueChange(e, index, undefined)
                }
              />
            </div>
            <div className="col-6">
              <label>
                Price <RequiredField />
              </label>
              <input
                className="form-control"
                type="number"
                disabled={editeAble}
                // value={item.Price}
                value={item.VariantPrice}
                onChange={(e) =>
                  handleVariationValueChange(e, index, undefined)
                }
                name="VariantPrice"
              />

              <Alert color="success" className="p-1 m-1">
                Total Price ={" "}
                {`${Currency}  ${
                  parseFloat(Price ? Price : 0) +
                  parseFloat(item.VariantPrice ? item.VariantPrice : 0)
                } ,this amount is sum of base price of this product and current variation value price`}{" "}
              </Alert>
            </div>
            <div className="col-6">
              <label>
                Inventory <RequiredField />
              </label>
              <input
                className="form-control"
                type="number"
                disabled={editeAble}
                value={item.Inventory}
                onChange={(e) =>
                  handleVariationValueChange(e, index, undefined)
                }
                name="Inventory"
              />
            </div>
            <div className="col-6">
              <label>
                Available Inventory <RequiredField />
              </label>
              <input
                className="form-control"
                type="number"
                disabled={editeAble}
                onChange={(e) =>
                  handleVariationValueChange(e, index, undefined)
                }
                name="AvailableInventory"
                value={item.AvailableInventory}
              />
            </div>
            <div className="col-6">
              <label>
                Total Price <RequiredField />
              </label>
              <input
                className="form-control"
                type="number"
                disabled={editeAble}
                // value="0"
                onChange={(e) =>
                  handleVariationValueChange(e, index, undefined)
                }
                name="TotalPrice"
                value={item.TotalPrice}
              />
            </div>
            <div className="col-6">
              <label>
                Unit Price <RequiredField />
              </label>
              <input
                disabled={editeAble}
                className="form-control"
                type="number"
                onChange={(e) =>
                  handleVariationValueChange(e, index, undefined)
                }
                name="UnitPrice"
                value={item.UnitPrice}
              />
            </div>

            <div className="col-12">
              <div className="row ">
                <div className="col-6 ">
                  <label>
                    Upload Images <RequiredField />
                  </label>
                  <input
                    disabled={editeAble}
                    className="form-control"
                    type="file"
                    multiple
                    onChange={(e) =>
                      handleUpdateVarantValueImage(e.target.files, index)
                    }
                  />
                </div>

                <div className="col-6 ">
                  <label>Upload Video </label>

                  <input
                    disabled={editeAble}
                    className="form-control"
                    type="file"
                    onChange={(e) =>
                      handleVideoUpload(e.target.files[0], index)
                    }
                  />
                </div>

                {/* <div className="col-6">
                  {!item.isNew && (
                    <>
                      <div className="form-check form-check-inline">
                        <input
                          disabled={editeAble}
                          className="form-check-input default-check-color"
                          type="checkbox"
                          id="inlineCheckbox3"
                          defaultChecked={item.MainImage === "Y"}
                          onChange={() =>
                            handleVariationValueMainImageChange(
                              item.MainImage === "Y" ? "N" : "Y",
                              index
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          for="inlineCheckbox3"
                        >
                          Main Image
                        </label>
                      </div>
                    </>
                  )}
                </div> */}

                {/* <div className="col-6 mt-3 ">
                <img
                  disabled={editeAble}
                  src={`${Endpoint}/${item.Medium}`} className="img-fluid" />

                {item.Images?.map((image, i) => (
                  <img
                    disabled={editeAble}
                    src={`${Endpoint}/${image.Medium}`} className="img-fluid" />
                ))}

              </div> */}

                {/* {item.video && <video width="320" height="240" controls>
                  <source src={`${Endpoint}/${item.video}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>} */}

                {selectedVideoIndex === index && (
                  <div
                    style={{
                      position: "relative",
                      display:
                        "inline-block" /* or "block" depending on your layout needs */,
                      width: "320px",
                      height: "240px",
                    }}
                  >
                    <video width="320" height="240" controls>
                      <p>Replace Video</p>
                      <source src={selectedVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}

                {selectedVideoIndex !== index && item.video && (
                  <div
                    style={{
                      position: "relative",
                      display:
                        "inline-block" /* or "block" depending on your layout needs */,
                      width: "320px",
                      height: "240px",
                    }}
                  >
                    <video width="320" height="240" controls>
                      <source
                        src={`${Endpoint}/${item.video}`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>

                    <span
                      style={{
                        position: "absolute",
                        top: "10px",
                        fontSize: "15px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        handleVideoDelete(item);
                      }}
                    >
                      {deletingItemId === item.ProductVariantCombinationID
                        ? "Deleting...."
                        : "X"}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="row w-100">
              {/* <div className="col-3 mt-3 ">
                {item.Medium && (
                  <img
                    disabled={editeAble}
                    src={`${Endpoint}/${item.Medium}`}
                    className="img-fluid h-100 w-100"
                  />
                )}
              </div> */}

              {/* IMAGES SHOW MAP */}

              {item.Images?.map((image, imageIndex) => (
                <div className="col-3 mt-3">
                  <div
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {loadingStates[imageIndex] ? (
                      <div className="loader">
                        <Loading text="Deleting" />
                      </div> // Replace with your loader component or style
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          checked={image.MainImage === "Y"}
                          onChange={() =>
                            handleCheckboxChange(index, imageIndex)
                          }
                        />
                        <img
                          disabled={editeAble}
                          src={`${Endpoint}/${image.Medium}`}
                          style={{
                            display: "block",
                            maxWidth: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "3px",
                            right: "10px",
                            cursor: "pointer",
                            backgroundColor: "black",
                            color: "white",
                            padding: "5px",
                            borderRadius: "50%",
                          }}
                          onClick={() => {
                            handleImageDelete(image.ImageGalleryID, imageIndex);
                          }}
                        >
                          ✖
                        </span>
                      </>
                    )}
                  </div>
                </div>
              ))}

              {previewImagesIndex === index &&
                previewImages.map((image, index) => (
                  <div className="col-3 mt-3">
                    <div
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        key={index}
                        src={image.preview}
                        alt={`Preview-${index}`}
                        style={{
                          display: "block",
                          maxWidth: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>

            <div className="w-100">
              {item.isNew ? (
                <div style={{ float: "right" }}>
                  <button
                    disabled={editeAble}
                    className="btn btn-success"
                    onClick={() => AddNewOptionValue(item)}
                    // disabled={disableBtn}
                  >
                    Add Value{" "}
                  </button>
                </div>
              ) : (
                <div style={{ float: "right" }} className="mt-3">
                  {isLoading ? (
                    <Loading text="save" />
                  ) : (
                    <button
                      disabled={editeAble}
                      className="btn btn-success"
                      onClick={(e) =>
                        submitOptionValueDetail(item.OptionValueID, item, index)
                      }
                    >
                      Save
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
      {/* {VariationValues.map((item, index) => {
        return <div

          className="row p-3 m-3"
          style={{ border: "1px solid rgba(128, 128, 128, 0.58) " }}
          key={index}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="ftw-400 text-default">
              {item.VariantName} - {item.VariantValue}
            </h5>
            {!item.isNew && VariationValues.length > 1 && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => removeVariantValue(item.OptionValueID)}
              >
                <i className="fas fa-times text-danger"></i>
              </div>
            )}
          </div>

          <div className="col-6">
            <label>Value  <RequiredField /></label>
            <input
              disabled={editeAble}

              className="form-control"
              type="text"
              name="VariantValue"
              onChange={(e) => handleVariationValueChange(e, index)}
              value={item.VariantValue}
            />
          </div>
          <div className="col-6">
            <label>SKU <RequiredField /></label>
            <input
              disabled={editeAble}

              className="form-control"
              type="text"
              value={item.SKU}
              name="SKU"
              onChange={(e) => handleVariationValueChange(e, index)}
            />
          </div>
          <div className="col-6">
            <label>Price <RequiredField /></label>
            <input
              className="form-control"
              type="number"
              disabled={editeAble}

              // value={item.Price}
              value={item.VariantPrice}
              onChange={(e) =>
                handleVariationValueChange(e, index)
              }
              name="VariantPrice"
            />

            <Alert color="success" className="p-1 m-1">
              Total Price ={" "}

              {`${Currency}  ${parseFloat(Price ? Price : 0) +
                parseFloat(item.VariantPrice ? item.VariantPrice : 0)
                } ,this amount is sum of base price of this product and current variation value price`}{" "}
            </Alert>
          </div>
          <div className="col-6">
            <label>Inventory <RequiredField /></label>
            <input
              className="form-control"
              type="number"
              disabled={editeAble}

              value={item.Inventory}
              onChange={(e) => handleVariationValueChange(e, index)}
              name="Inventory"
            />
          </div>
          <div className="col-6">
            <label>Available Inventory <RequiredField /></label>
            <input
              className="form-control"
              type="number"
              disabled={editeAble}

              onChange={(e) => handleVariationValueChange(e, index)}
              name="AvailableInventory"
              value={item.AvailableInventory}
            />
          </div>
          <div className="col-6">
            <label>Total Price <RequiredField /></label>
            <input
              className="form-control"
              type="number"
              disabled={editeAble}

              // value="0"
              onChange={(e) => handleVariationValueChange(e, index)}
              name="TotalPrice"
              value={item.TotalPrice}
            />
          </div>
          <div className="col-6">
            <label>Unit Price <RequiredField /></label>
            <input
              disabled={editeAble}

              className="form-control"
              type="number"
              onChange={(e) => handleVariationValueChange(e, index)}
              name="UnitPrice"
              value={item.UnitPrice}
            />
          </div>

          <div className="col-6">
            <div className="row ">
              <div className="col-6">
                <label>Image <RequiredField /></label>
                <input
                  disabled={editeAble}

                  className="form-control"
                  type="file"
                  onChange={(e) =>
                    handleUpdateVarantValueImage(e.target.files[0], index)
                  }
                />

                <div className="col-6">
                  {!item.isNew && (
                    <>
                      <div className="form-check form-check-inline">
                        <input
                          disabled={editeAble}

                          className="form-check-input default-check-color"
                          type="checkbox"
                          id="inlineCheckbox3"
                          defaultChecked={item.MainImage === "Y"}
                          onChange={() =>
                            handleVariationValueMainImageChange(
                              item.MainImage === "Y" ? "N" : "Y",
                              index
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          for="inlineCheckbox3"
                        >
                          Main Image
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-6 mt-3">
                <img
                  disabled={editeAble}
                  src={`${Endpoint}/${item.Medium}`} className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="w-100">
            {item.isNew ? (
              <div style={{ float: "right" }}>
                <button
                  disabled={editeAble}

                  className="btn btn-success"
                  onClick={() => AddNewOptionValue(item)}
                // disabled={disableBtn}

                >
                  Add Value{" "}
                </button>
              </div>
            ) : (
              <div style={{ float: "right" }} className="mt-3">
                <button
                  disabled={editeAble}

                  className="btn btn-success"
                  onClick={(e) =>
                    submitOptionValueDetail(item.OptionValueID, item)
                  }
                >
                  Save{" "}
                </button>
              </div>
            )}
          </div>
        </div>
      })} */}
      <Modal
        toggle={() => setshowModal(!showModal)}
        isOpen={showModal}
        size="lg"
      >
        <ModalHeader toggle={() => setshowModal(!showModal)}>
          Custom Value
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {/* //!handling options */}

            {optionWithValues.map((option) => {
              return (
                <OptionValueField
                  option={option}
                  setModalButton={setModalButton}
                  handleDropdownChange={handleDropdownChange}
                  setSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedOptions}
                />
              );

              // return <div className="col-6">

              //   {/* //actual value is drop down */}
              //   <label>{option.OptionName}  <RequiredField /></label>
              //   {isAddCustom ? (
              //     <input
              //       disabled={editeAble}
              //       className="form-control"
              //       type="text"
              //       name="VariantValue"
              //       id="valueName"
              //       onChange={() => setModalButton(false)}
              //     />
              //   ) : (
              //     <select
              //       disabled={editeAble}

              //       className="form-control"
              //       type="text"
              //       name="VariantValue"
              //       // !value={option.OptionID}
              //       // value={736}
              //       id="valueName"
              //       onChange={(e) => {
              //         handleDropdownChange(option.OptionID, e);
              //         setModalButton(false)
              //       }}
              //     >
              //       <option value={""}>Select Available Option</option>
              //       {option.Values.map((item, index) => (
              //         <option value={item.OptionValueID}>
              //           {/* {item.OptionValueID} */}
              //           {item.OptionValue}
              //         </option>
              //       ))}
              //     </select>
              //   )}
              //   {isAddCustom ? (
              //     <Link
              //       to="#"
              //       className="td-none text-danger"
              //       onClick={(e) => {
              //         setIsAddCustom(false);
              //         document.getElementById("valueName").value = "";
              //         setSelectedOptions(prevState => ({
              //           ...prevState,
              //           [option.OptionID]: e.target.value
              //         }));
              //       }}
              //     >
              //       Cancel
              //     </Link>
              //   ) : (
              //     <Link
              //       to="#"
              //       className="td-none text-default"
              //       onClick={(e) => {
              //         setIsAddCustom(true);
              //         document.getElementById("valueName").value = "";
              //         setSelectedOptions(prevState => ({
              //           ...prevState,
              //           [option.OptionID]: e.target.value
              //         }));
              //         setModalButton(false);
              //       }}
              //     >
              //       {/* //!custom value */}
              //       Add Custom

              //     </Link>
              //   )}
              // </div>
            })}

            {/* <div className="col-6">

              <label>Value <RequiredField /></label>
              {isAddCustom ? (
                <input
                  disabled={editeAble}

                  className="form-control"
                  type="text"
                  name="VariantValue"
                  id="valueName"
                  onChange={() => setModalButton(false)}
                />
              ) : (
                <select
                  disabled={editeAble}

                  className="form-control"
                  type="text"
                  name="VariantValue"
                  id="valueName"
                  onChange={() => setModalButton(false)}
                >
                  <option value={""}>Select Available Option</option>
                  {AvailabaleValues.map((item, index) => (
                    <option value={item.VariantValue}>
                      {item.VariantValue}
                    </option>
                  ))}
                </select>
              )}
              {isAddCustom ? (
                <Link
                  to="#"
                  className="td-none text-danger"
                  onClick={() => {
                    setIsAddCustom(false);
                    document.getElementById("valueName").value = "";
                  }}
                >
                  Cancel
                </Link>
              ) : (
                <Link
                  to="#"
                  className="td-none text-default"
                  onClick={() => {
                    setIsAddCustom(true);
                    document.getElementById("valueName").value = "";
                  }}
                >
                </Link>
              )}
            </div> */}
            <div className="col-6">
              <label>
                SKU <RequiredField />
              </label>
              <input className="form-control" type="text" id="variantSKU" />
            </div>

            <div className="col-6">
              <label>
                Inventory <RequiredField />
              </label>
              <input
                disabled={editeAble}
                className="form-control"
                type="number"
                min={0}
                id="variantInventory"
              />
            </div>
            <div className="col-6">
              <label>
                Available Inventory <RequiredField />
              </label>
              <input
                disabled={editeAble}
                className="form-control"
                type="number"
                min={0}
                id="variantAvailableInventory"
              />
            </div>

            <div className="col-6">
              <label>
                Total Price <RequiredField />
              </label>
              <input
                disabled={editeAble}
                className="form-control"
                type="number"
                defaultValue={0}
                min={0}
                id="variantTotalPrice"
              />
            </div>
            <div className="col-6">
              <label>
                Unit Price <RequiredField />
              </label>
              <input
                disabled={editeAble}
                className="form-control"
                type="number"
                defaultValue={0}
                min={0}
                id="variantUnitPrice"
              />
            </div>
            <div className="col-6">
              <label>
                Price <RequiredField />
              </label>
              <input
                disabled={editeAble}
                className="form-control"
                type="number"
                defaultValue={0}
                id="variantPrice"
                onChange={(e) => setModalPrice(e.target.value)}
              />
              <Alert color="success" className="p-1 m-1">
                Total Price ={" "}
                {`${Currency}  ${
                  parseFloat(Price ? Price : 0) +
                  parseInt(ModalPrice ? ModalPrice : 0)
                } ,this amount is sum of base price of this product and current variation value pricess`}{" "}
              </Alert>
            </div>
            <div className="col-6">
              <label>
                Images <RequiredField />
              </label>
              <input
                disabled={editeAble}
                className="form-control"
                type="file"
                id="variantImage"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => CreateNewValue()}
            // disabled={ModalButton}
            disabled={disableBtn}
          >
            {disableBtn ? (
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <span>Add Value</span>
            )}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}
export default EditProductVariants;
