import { Container, ListInlineItem, List } from "reactstrap";
import BrowseCategories from "./BrowseCategories";
import { useHistory } from "react-router-dom";
import { useContext, useState } from "react";
import MODAL_CONTEXT from "../../../Contexts/ModalContext";
import { CurrentUser } from "../../../../Helpers/Auth";
import { Link, useLocation } from "react-router-dom";
import BrowseCategories2 from "./BrowseCategories2";
import firetoast from "../../../../Helpers/FireToast";
import BanglaBazarApi from "../../../Api/BanglaBazarApi";
import Endpoint from "../../../../Utils/Endpoint";
import { Button, Modal } from 'antd';
import { AiFillAndroid, AiFillApple } from 'react-icons/ai'

function NavMenu() {
  const [modal2Open, setModal2Open] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { pathname } = useLocation();

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((path) => path);

  var getVendorBusiness = async () => {
    try {
      var response = await BanglaBazarApi.get(
        Endpoint +
        `/api/store-management/buisness-details/${CurrentUser.UserID}`
      );
      console.log(response.data.business && CurrentUser.PhoneVerified === "Y");

      // return
      if (response.data.business && CurrentUser.PhoneVerified === "Y") {
        history.push(`/panel/viewBusiness/${CurrentUser.UserID}`);
        return
      }

      if (response.data.business && CurrentUser.PhoneVerified === "N") {
        firetoast(
          "You have to verify your phone number from your profile",
          "info",
          5000,
          "top-center"
        );
        setTimeout(() => {
          history.push("/sell");
        }, 3000);
        return
      }


      if (!response.data.business && CurrentUser.PhoneVerified === "Y") {
        history.push("/sell");
      }

      if (!response.data.business && CurrentUser.PhoneVerified === "N") {
        firetoast(
          "You have to verify your phone number from your profile",
          "info",
          5000,
          "top-center"
        );
        setTimeout(() => {
          history.push(`/user-profile/${CurrentUser.UserID}`);
        }, 3000);
        return
      }



    } catch (e) {
      console.log(e);
      // firetoast("Something went wrong!", "error", 4000, "top-right");
    }
  };
  const history = useHistory();
  const { signin, setSignin } = useContext(MODAL_CONTEXT);
  return (
    <Container>

      <Modal
        title="Download"
        centered
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}

      >


        <p>Download the app for:</p>
        <div style={{ display: 'flex', alignItems: "center" }}>
          <a
            href="https://play.google.com/store" // Replace this link with the actual Android Play Store link
            target="_blank"
            rel="noopener noreferrer"
            title="Download App for Andriod"
          >

            <h1 style={{ marginLeft: "5px" }}>
              <AiFillAndroid />
            </h1>
          </a>
          <a
            href="https://www.apple.com/app-store/" // Replace this link with the actual iOS App Store link
            target="_blank"
            rel="noopener noreferrer"
            title="Download App for App"

          >
            <h1 style={{ marginLeft: "5px" }}>
              <AiFillApple />
            </h1>
          </a>
        </div>
      </Modal>

      <div className="row align-items-baseline">
        <div className="col-xl-3 col-lg-6 col-md-12 mt-2">
          {/* <BrowseCategories /> */}
          <BrowseCategories2 />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <div
            className="d-flex justify-content-between"
            style={{ alignItems: "center" }}
          >
            <div
              className={
                pathname === "/" ? "nav-menu-item-active" : "nav-menu-item"
              }
            >
              <Link className="text-default td-none" to="/">
                Home
              </Link>
            </div>
            {/* <div className="nav-menu-item">Today's Best Deal</div> */}
            {CurrentUser ? (
              <div
                className={
                  pathname === "/order-details"
                    ? "nav-menu-item-active"
                    : "nav-menu-item"
                }
              >
                <Link className="text-default td-none" to="/order-details">
                  My Orders
                </Link>
              </div>
            ) : (
              <div
                onClick={() => {
                  firetoast(
                    "Login first to view your orders",
                    "info",
                    5000,
                    "top-center"
                  );
                }}
              >
                <span className="text-default td-none">My Orders</span>
              </div>
            )}

            <div
              className={
                pathname === "/sell"
                  ? "nav-menu-item-active"
                  : "nav-menu-item text-default"
              }
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (CurrentUser) {

                  // console.log("==============>", CurrentUser);
                  // // return
                  // if (CurrentUser.PhoneVerified === "N") {
                  //   firetoast(
                  //     "You have to verify your phone number from your profile",
                  //     "info",
                  //     5000,
                  //     "top-center"
                  //   );
                  //   console.log("============>", CurrentUser);
                  //   setTimeout(() => {
                  //     history.push("/sell");
                  //   }, 1500);
                  // }
                  // if (CurrentUser.PhoneVerified === "Y") {
                  //   history.push("/sell");
                  // }


                  getVendorBusiness()
                } else {
                  setSignin(!signin);
                }
              }}
            >
              Become a Vendor
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-12 col-md-6 mt-2">
          <List type="inline" className="lt-un mt-0 mr-auto pb-0 mb-0 pt-3">
            {/* <a href="https://play.google.com/store/apps" target="_blank">
              <ListInlineItem
                style={{ marginLeft: "5px", marginRight: "5px" }}
                className="nav-menu-item"
              >
                Get The App
              </ListInlineItem>
            </a> */}

            <div style={{ cursor: 'pointer' }} onClick={showModal}>
              <ListInlineItem
                style={{ marginLeft: "5px", marginRight: "5px" }}
                className="nav-menu-item"
              >
                Get The App
              </ListInlineItem>
            </div>

            {/* <span className="lt-divider"></span>
            <ListInlineItem
              style={{ marginLeft: "5px", marginRight: "5px" }}
              className="nav-menu-item"
            >
              Feedback
            </ListInlineItem>
            <span className="lt-divider"></span>
            <ListInlineItem
              style={{ marginLeft: "5px", marginRight: "5px" }}
              className="nav-menu-item"
            >
              Help Center
            </ListInlineItem> */}
          </List>
        </div>
      </div >
      {/* <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        {pathnames.map((path, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;

          return (
            <Breadcrumb.Item key={routeTo}>
              <Link to={routeTo}>{path}</Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb> */}

    </Container >
  );
}
export default NavMenu;
